import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import DurationFormInput from 'app/components/form/inputs/DurationFormInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { translate } from 'i18n/i18n';

@translate()
@inject('store')
@observer
class BookingDurationTab extends Component {
    render() {
        const { t, store: { booking } } = this.props;
        return (
            <Fragment>
                <div className="tab-container">
                    <DurationFormInput moduleStore={booking} onChange={() => booking.debounceAvailability()} />
                    {!booking.isAnyAvailableSpots && !booking.isLoading &&
                    <div className="no-spots-warning">
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                        <span>{t('COMMON.NO_SPOTS_WARNING')}</span>
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                    </div>
                    }
                </div>
            </Fragment>
        );
    }
}

export default BookingDurationTab;
