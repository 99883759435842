import { action, computed, observable } from 'mobx';
import CamperApi from 'api/CamperApi';

class ShopStore {
    @observable orderId = 'shop';
    @observable isDurationStepRequired = false;
    @observable peopleNumber = 0;
    @observable durationNumber = 0;
    @observable isvData = '';

    @observable servicesEnabled = {
        tent: false
    };

    @observable facilitiesAmount = {
        shower: 0,
        washer: 0,
        dryer: 0
    };

    @observable servicesArr = ['tent', 'shower', 'washer', 'dryer'];

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @action
    incrementFacilityAmount(facility) {
        this.facilitiesAmount[facility] =  this.facilitiesAmount[facility] + 1;
    }

    @action
    decrementFacilityAmount(facility) {
        if (this.facilitiesAmount[facility] > 0) {
            this.facilitiesAmount[facility] =  this.facilitiesAmount[facility] - 1;
        }
    }

    @action
    incrementDuration() {
        this.durationNumber = this.durationNumber + 1;

        if (this.durationNumber === 1) {
            this.servicesEnabled.tent = true;

            if (this.peopleNumber === 0) {
                this.peopleNumber = 1;
            }
        }
    }

    @action
    decrementDuration() {
        if (this.durationNumber >= 1) {
            this.durationNumber = this.durationNumber - 1;
        }

        if (this.durationNumber === 0) {
            this.servicesEnabled.tent = false;
            this.peopleNumber = 0;
        }
    }
    @action
    setISVData(value) {
        this.isvData = value;
    }

    @action
    incrementPeople() {
        this.peopleNumber = this.peopleNumber + 1;

        if (this.peopleNumber === 1) {
            this.servicesEnabled.tent = true;

            if (this.durationNumber === 0) {
                this.durationNumber = 1;
            }
        }
    }

    @action
    decrementPeople() {
        if (this.peopleNumber >= 1) {
            this.peopleNumber = this.peopleNumber - 1;
        }

        if (this.peopleNumber === 0) {
            this.servicesEnabled.tent = false;
            this.durationNumber = 0;
        }
    }

    @computed
    get pricePerNightPerPerson() {
        return 20 * this.peopleNumber * this.durationNumber;
    }

    @action
    priceForUtility(utility) {
        return 20 * this.facilitiesAmount[utility];
    }

    @computed get
    total() {
        let total = 0;

        this.servicesArr.forEach((utility, i) => {
            if (i < 1 && this.servicesEnabled[utility]) {
                total = total + (this.servicesEnabled[utility] ? (20 * this.peopleNumber * this.durationNumber) : 0);
            }
            if (i >= 1 && this.facilitiesAmount[utility] > 0) {
                total = total + this.facilitiesAmount[utility] * 20;
            }
        });

        return total;
    }

    @action
    createReservation() {
        if (!this.reservationLoading) {
            this.setReservationLoading(true);
            const body = this.getBodyForReservation();

            const q = CamperApi.postReservation(body, this.rootStore.harbor.id);

            return new Promise((resolve, reject) => {
                q
                    .then(response => {
                        this.setReservationLoading(false);
                        resolve(response);
                    })
                    .catch((e) => {
                        this.setReservationLoading(false);
                        reject(e);
                    })
            });
        } else {
            return new Promise((resolve) => {
                resolve(null);
            });
        }
    }
}

export default ShopStore;
