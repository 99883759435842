import { action, observable } from 'mobx';
import CamperApi from 'api/CamperApi';
import CamperPaymentViewModel from 'store/PaymentStore/modules/Camper/CamperPaymentViewModel';
import CamperReceiptModel from 'store/PaymentStore/PrintModels/CamperReceiptModel';
import CreditReceiptModel from 'store/PaymentStore/PrintModels/CreditReceiptModel';
import CamperStickerModel from 'store/PaymentStore/PrintModels/CamperStickerModel';
import PrinterApi from 'api/PrinterApi';
import AbstractModulePaymentStore from 'store/PaymentStore/modules/AbstractModulePaymentStore';
import HarborApi from '../../../../api/HarborApi';

class CamperPaymentStore extends AbstractModulePaymentStore {
    @observable camper: CamperPaymentViewModel;
    @observable hasAccessCode = false;

    constructor(paymentStore) {
        super(paymentStore);
        this.payment = paymentStore;
    }

    @action
    getCamperRoot() {
        return this.payment.rootStore.camper;
    }

    @action
    async finalizePayment(orderId) {
        const response = await getPaymentByOrderIdPromise(orderId)
        if (response) {
            let responseObject = response;
            const res = await HarborApi.getAccessCode(orderId);
            if (res) {
                responseObject.accessCode = res.data;
                if (res.data) {
                    this.setHasAccessCode();
                }
                this.setCamper(responseObject);
                this.markPaymentAsPaid(orderId);
            }
            this.generatePrint(responseObject);
            this.printSticker();
        }
    }

    @action
    extractTotalByOrderId(orderId) {
        return new Promise((resolve, reject) => {
            getPaymentByOrderIdPromise(orderId)
                .then((response) => {
                    this.setPaymentTotalIndoor(response.amount);
                    this.setPaymentTotalOutdoor(response.amount * 100);
                    resolve(response.amount);
                }).catch((error) => {
                    reject(error);
                });
        });
    }

    markPaymentAsPaid(orderId) {
        const q = CamperApi.markReservationAsPaid(orderId, this.getGateway(), this.payment.rootStore.camper.fee);
        q.then(() => {});
    }

    @action
    setHasAccessCode() {
        this.hasAccessCode = true;
    }

    @action
    setCamper(response) {
        this.accessCode = response.accessCode;
        this.camper = new CamperPaymentViewModel(response['camperReservation'], this.payment.rootStore.locale.locale);
        this.payment.rootStore.camper.setCamperFromApi(
            new CamperPaymentViewModel(
                response['camperReservation'],
                this.payment.rootStore.locale.locale
            )
        );
    }

    @action
    generatePrint(response) {
        if (this.camper.plateNumber === 'SLIPWAY') {
            this.receiptPrint = new CreditReceiptModel().build(response, this.payment.rootStore);
        } else {
            this.stickerPrint = new CamperStickerModel().build(response, this.payment.rootStore);
            this.receiptPrint = new CamperReceiptModel().build(response, this.payment.rootStore);
        }
    }

    printSticker() {
        if (this.payment.rootStore.terminal.hasPrinter && this.stickerPrint) {
            PrinterApi.printCamperSticker(this.stickerPrint).then();
        }
    }

    printReceipt() {
        if (this.payment.rootStore.terminal.hasPrinter) {
            PrinterApi.printCamperReceipt(this.receiptPrint)
                .then(() => this.setIsReceiptPrinted(true));
        }
    }
}

const getPaymentByOrderIdPromise = async (orderId) => {
    const res = await CamperApi.getCamperPaymentByOrderId(orderId);
    return res.data;
}

export default CamperPaymentStore;
