import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import PaymentFailedForm from 'app/modules/payment/components/PaymentFailedForm';
import Loader from 'app/components/Loader';

@withRouter
@inject('store')
@observer
class BookingPaymentFailed extends Component {
    constructor() {
        super();
        this.state = {
            total: null,
        };
    }

    async componentDidMount() {
        const { payment: { booking } } = this.props.store;
        const { params: { orderId } } = this.props.match;

        booking.extractTotalByOrderId(orderId)
            .then((res) => {
                this.setState({ total: res });
                booking.fetchPrinterStatus()
            })
    }

    render() {
        const { payment: { booking: { isTotalSet, paymentTotalIndoor } } } = this.props.store;

        return (
            <Fragment>
                {!isTotalSet && !this.state.total ?
                    <Loader fullScreen={true} /> : <PaymentFailedForm total={this.state.total || paymentTotalIndoor} />
                }
            </Fragment>
        )
    }
}

export default BookingPaymentFailed;
