import { action, computed, observable } from 'mobx';
import ModuleStore from 'store/modules/ModuleStore';
import CamperApi from 'api/CamperApi';
import moment from 'moment';

class CamperStore extends ModuleStore {
    @observable lots;
    @observable price;
    @observable camperFromApi;

    @observable hiddenFields = {
        isNationalityHidden: false,
        isFirstNameHidden: false,
        isLastNameHidden: false,
        isEmailHidden: false,
    };

    @observable form = {
        dateFrom: moment().startOf('day').format(),
        duration: 1,
        plateNumber: null,
        camperLot: null,
        country: null,
        firstName: null,
        lastName: null,
        phone: '+',
        email: null,
        price: null
    }

    constructor(rootStore) {
        super(rootStore);
        this.rootStore = rootStore;
    }

    @action
    setCamperFromApi(data) {
        this.camperFromApi = data;
    }

    @action
    getInitialCamperSettings() {
        this.setLoading(true);
        const harborId = this.rootStore.harbor.id;

        Promise.all([getCamperLotsPromise(harborId), getCamperSettingsPromise(harborId)])
            .then((response) => {
                this.setLots(response[0]);
                this.setCamperSettings(response[1])
                this.setLoading(false);
                this.checkDepartureTimePassed();
            })
            .catch(() => this.setLoading(false));
    }

    @action.bound
    setCamperSettings(settings) {
        this.price = settings.price;

        this.hiddenFields.isNationalityHidden = settings.isNationalityHidden;
        this.hiddenFields.isFirstNameHidden = settings.isFirstNameHidden;
        this.hiddenFields.isLastNameHidden = settings.isLastNameHidden;
        this.hiddenFields.isEmailHidden = settings.isEmailHidden;
    }

    @action.bound
    setLots(lots) {
        this.lots = lots.filter(lot => !lot['detected']);
        if (this.isOneLot) {
            this.setFormValue('camperLot', this.lots[0]);
        }
    }

    @computed
    get createCamperReservationPromise() {
        return () => {
            return new Promise((resolve, reject) => {
                CamperApi.postReservation(this.formRequestBody, this.rootStore.harbor.id)
                    .then(({ data }) => {
                        this.savePaymentRequest(data.orderId, this.total);
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    })
            })
        }
    }

    @computed
    get formRequestBody() {
        const { dateFrom, duration, camperLot, plateNumber, country, firstName, lastName, phone, email } = this.form;
        return {
            dateFrom: moment(dateFrom).format('YYYY-MM-DDTHH:mm:ss'),
            dateTo: moment(dateFrom).add(duration,'days').format('YYYY-MM-DDTHH:mm:ss'),
            camperLot: camperLot ? camperLot.id : null,
            plateNumber,
            country,
            firstName,
            lastName,
            phone: (phone !== '+' && !this.phonePreselected) ? phone : null,
            email,
            price: this.total,
            source: 'kiosk',
        }
    }

    @computed
    get isOneLot() {
        return this.lots.length === 1;
    }

    @computed
    get total() {
        return this.form.duration * this.price;
    }
}

const getCamperSettingsPromise = (harborId) => {
    return new Promise(resolve => {
        CamperApi.getSettings(harborId)
            .then((res) => resolve(res.data));
    })
}

const getCamperLotsPromise = (harborId) => {
    return new Promise(resolve => {
        CamperApi.getLots(harborId)
            .then((res) => resolve(res.data));
    })
}

export default CamperStore;
