import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import PaymentFailedForm from 'app/modules/payment/components/PaymentFailedForm';
import Loader from 'app/components/Loader';

@inject('store')
@observer
class ShopPaymentFailed extends Component {
    constructor() {
        super();
        this.state = {
            total: null,
        };
    }

    componentDidMount() {
        const { payment, shop } = this.props.store;

        payment.shop.setPaymentTotalIndoor(shop.total);

        this.setState({ total: shop.total })

        if (shop.fetchPrinterStatus instanceof Function) {
            shop.fetchPrinterStatus();
        }
    }

    render() {
        const { payment: { shop: { isTotalSet, total } } } = this.props.store;

        return (
            <Fragment>
                {(!isTotalSet && !total) && (!this.state.total) ?
                    <Loader fullScreen={true} /> : <PaymentFailedForm total={total || this.state.total} />
                }
            </Fragment>
        )
    }
}

export default ShopPaymentFailed;
