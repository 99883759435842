import { action, observable } from 'mobx';
import moment from 'moment';

class ModuleStore {
    @observable form;
    @observable loading = false;
    @observable phonePreselected = false;

    @observable orderId;
    @observable publicToken = null;

    @observable totalIndoor = 0;
    @observable totalOutdoor = null;
    @observable isvData = '';
    @observable fee = '';

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @action
    savePaymentRequest(orderId, total, publicToken) {
        this.orderId = orderId;
        this.publicToken = publicToken;
        this.totalIndoor = total;
        this.totalOutdoor = total * 100;
    }

    @action
    setLoading(value) {
        this.loading = value;
    }

    @action
    setPaymentLoading(value) {
        this.paymentLoading = value;
    }

    @action
    setFormValue(formField, value) {
        this.form[formField] = value;
    }

    @action
    resetDates() {
        if (this.form && this.form.dateFrom) {
            this.setFormValue('dateFrom', moment().startOf('day').format());
        }
    }
    @action
    updateDates() {
        today = moment().startOf('day').format();
        yesterday = moment().startOf('day').add(-1, 'days').format();
    }
    @action
    preselectPhone(country) {
        const selectedCountry = countries.filter((countryObj) => {
            return countryObj.value === country;
        });

        if (selectedCountry.length !== 0) {
            this.setFormValue('phone', selectedCountry[0].phone);
            this.setPhonePreselected(true);
        }
    }

    @action
    getCountryCodeByLocale(locale) {
        const selectedCountry = countries.filter((countryObj) => {
            return countryObj.value === locale;
        });

        if (selectedCountry.length !== 0) {
            return selectedCountry[0].phone;
        } else {
            return null;
        }
    }

    @action
    setPhonePreselected(value) {
        this.phonePreselected = value;
    }
    @action
    setISVData(value) {
        this.isvData = value;
    }
    @action
    setFee(value) {
        this.fee = value;
    }

    @action
    checkDepartureTimePassed() {
        const { departureTime } = this.rootStore.harbor;

        const minutesOfDay = (m) => m.minutes() + m.hours() * 60;

        const hasDepartureTimePassed = minutesOfDay(moment.utc(departureTime)) < minutesOfDay(moment());

        if (hasDepartureTimePassed) {
            this.setFormValue('dateFrom', today)
        } else {
            this.setFormValue('dateFrom', yesterday)
        }
    }

    @action
    onDebounce(func, delay) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            func();
        }, delay);
    }
}

let today = moment().startOf('day').format();
let yesterday = moment().startOf('day').add(-1, 'days').format();

const countries = [
    { value: 'DK', phone: '+45' },
    { value: 'DA', phone: '+45' },
    { value: 'DE', phone: '+49' },
    { value: 'SE', phone: '+46' },
    { value: 'NO', phone: '+47' },
    { value: 'GB', phone: '+44' },
    { value: 'US', phone: '+1' },
    { value: 'AU', phone: '+61' },
    { value: 'FI', phone: '+358' },
    { value: 'LT', phone: '+370' },
    { value: 'PL', phone: '+48' },
    { value: 'EE', phone: '+372' },
    { value: 'LV', phone: '+371' },
    { value: 'FR', phone: '+33' },
    { value: 'NL', phone: '+31' },
    { value: 'ES', phone: '+34' },
    { value: 'IT', phone: '+39' },
    { value: 'HR', phone: '+36' },
    { value: 'GR', phone: '+30' },
    { value: 'IE', phone: '+353' },
    { value: 'IS', phone: '+354' },
];

export default ModuleStore;
