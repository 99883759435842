import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { translate } from 'i18n/i18n';

@translate()
@inject('store')
@observer
class WelcomePage extends Component {

    render() {
        const harborName = this.props.store.harbor['name'];
        const { t, store: { harbor } } = this.props;

        return (
            <div className="selection-container">
                <div className="selection-title-container">
                    <span className="harbor-name">{t('COMMON.WELCOME_TO', { harborName })}</span>
                </div>
                <div className="vertical-centered">
                    <div className="welcome-text">
                        {harbor.welcomeTranslationBySelectedLocale}
                    </div>
                    <div style={{ 'marginBottom': '50px' }} className="selection-btn-container">
                        <button type="button"
                                className="selection-btn btn justify-content-center"
                                onClick={() => harbor.setIsWelcomeSkipped(true)}>
                            {t('COMMON.CONTINUE')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default WelcomePage;
