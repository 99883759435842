import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { translate } from 'i18n/i18n';
import FormNav from 'app/components/form/FormNav';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

@translate()
@withRouter
@inject('store')
@observer
class CraneBookingNav extends Component {
    isTabValid(tabNumber) {
        const activeTab = this.props.tabTitles[tabNumber - 1];
        const { form } = this.props.store.craneBooking;
        const { price } = form;

        switch (activeTab) {
            case 'COMMON.CRANE_BOOKING_TYPE':
                return !!(price);
            case 'COMMON.CRANE_BOOKING_OVERVIEW':
                return !!(price);
            default: return !!(price);
        }
    }

    render() {
        const { location: { pathname }, store: { craneBooking }, tabTitles } = this.props;
        const tabNumber = Number(pathname.charAt(pathname.length - 1));

        return (
            <FormNav tabTitles={tabTitles}
                     tabNumber={tabNumber}
                     isTabValid={this.isTabValid(tabNumber)}
                     prePaymentPromise={craneBooking.createCraneBookingPaymentPromise}
                     moduleStore={craneBooking}
            />
        )
    }
}

CraneBookingNav.propTypes = {
    tabTitles: PropTypes.array.isRequired
};

export default CraneBookingNav;
