import { computed, observable } from 'mobx';
import ModuleStore from 'store/modules/ModuleStore';

class ShopHackStore extends ModuleStore {
    @observable prices;

    @observable form = {
        order: [],
        totalPrice: null
    }

    constructor(rootStore) {
        super(rootStore);
        this.rootStore = rootStore;
    }

    @computed
    get createShopHackPaymentPromise() {
        return () => {
            return new Promise((resolve, reject) => {
                resolve();
            })
        }
    }

}

export default ShopHackStore;
