import React, { Component, Fragment } from 'react';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import VerifonePaymentModel from 'store/TerminalStore/models/VerifonePaymentModel';
import OrderApi from '../../../../api/OrderApi';

@translate()
@withRouter
@inject('store')
@observer
class PaymentPayAgainForm extends Component {
    async getIsvData(total) {
        const { match: { params: { harborId } } } = this.props;
        const response = await OrderApi.getISV(harborId, total);

        return response.data.isv;
    }

    async pay() {
        const { params: { orderId } } = this.props.match;
        const { total:totalFromProps, store: { terminal, payment, ...restStore } } = this.props;
        const currentModule = payment.paymentType;
        const { paymentTotalIndoor } = payment[currentModule];
        const moduleStore = restStore[currentModule];

        if (terminal.isIndoor) {
            const total = paymentTotalIndoor || totalFromProps;
            const isvData = moduleStore.isvData || await this.getIsvData(total);

            const verifonePayment = new VerifonePaymentModel(total, orderId, isvData, true);

            terminal.startPayment(verifonePayment);
        }

        if (terminal.isOutdoor) {
            setTimeout(() => {
                // Initiate outdoor payment
                document.getElementById('payAgain').submit();
            })
        }
    }

    render() {
        const { t, store: { terminal, payment } } = this.props

        const { params: { orderId } } = this.props.match;
        const { paymentTotalOutdoor } = payment[payment.paymentType];

        const isDisabled = !terminal.isReady;

        return (
            <Fragment>
                <button type="button"
                        disabled={isDisabled}
                        className="btn payment-btn"
                        onClick={() => this.pay()}>
                    {t('COMMON.TRY_AGAIN')}
                </button>
                <form action="http://localhost/payment/doPayment.aspx"
                      method="post"
                      target="_top"
                      id={'payAgain'}>
                    <input type="text" name="amount" style={{ display: 'none' }}
                           defaultValue={paymentTotalOutdoor} />
                    <input type="text" name="boorefno" defaultValue={orderId} style={{ display: 'none' }} />
                </form>
            </Fragment>
        );
    }
}

export default PaymentPayAgainForm;
