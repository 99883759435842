import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import touch from 'assets/img/touch.png'
import wind from 'assets/img/wind.png'
import Loader from 'app/components/Loader';
import Arrow from '@elsdoerfer/react-arrow';

const tenMinutes = 1000 * 60 * 10;

@inject('store')
@observer
class WeatherScreensaver extends Component {
    constructor() {
        super();
        this.state = {
            interval: null,
        }
    }

    componentDidMount() {
        this.doReset();
        const interval = setInterval(() => {
            this.doReset();
        }, tenMinutes);
        this.setState({ interval: interval });
    }

    doReset() {
        this.props.store.screensaver.getWeather();
        this.props.store.camper.resetDates();
        this.props.store.slipway.resetDates();
        this.props.store.booking.resetDates();
    }

    componentWillUnmount() {
        clearInterval(this.state.interval);
    }

    render() {
        const { harbor: { name }, screensaver } = this.props.store;
        const { loading, temperature, windSpeed, windDegree, icon } = screensaver;

        if (loading) {
            return (<Loader fullScreen />)
        }

        return (
            <div onClick={() => screensaver.disable()}>
                <div className="screensaver-title">
                    <span className="harbor-name-screensaver">{ name }</span>
                </div>
                <div className="screensaver-centered">
                    <img style={{ width: '170px', height: '170px' }}
                         alt={'touch'}
                         src={touch} />
                </div>
                {(temperature || temperature === 0) &&
                <Fragment>
                    <div className="screensaver-weather">
                        <div className="weather-tem">
                            {temperature}°C
                        </div>
                        <i className={`weather-icon ${icon}`}></i>
                    </div>
                    <div className="screensaver-weather-2">
                        <div className="weather-wind">
                            {windSpeed} m/s
                        </div>
                        <img className="wind-icon"
                             alt={'wind'}
                             src={wind} />
                    </div>
                    <div className="screensaver-weather-2">
                        <div className="wind-compass">
                            <span className="compass-letter">N</span>
                            <div className="mid-compass-row">
                                <span className="compass-letter">W</span>
                                <div className="wind-compass-arrow">
                                    <Arrow
                                        angle={windDegree + 180}
                                        length={35}
                                        lineWidth={1}
                                        color={'#234965'}
                                        className="wind-arrow"
                                    />
                                </div>
                                <span className="compass-letter">E</span>
                            </div>
                            <span className="compass-letter">S</span>
                        </div>
                    </div>
                </Fragment>
                }
            </div>
        )
    }
}

export default WeatherScreensaver;
