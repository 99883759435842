import i18n from 'i18next';
import en_US from './locales/en-us';
import da from './locales/da';
import de from './locales/de';
import { withTranslation } from 'react-i18next';

export const translate = withTranslation;

i18n.init({
    debug: process.env.REACT_APP_ENV === 'development',

    lng: 'en-US',
    resources: {
        'en-US': {
            'translation': en_US
        },
        'da': {
            'translation': da
        },
        'de': {
            'translation': de
        }
    },
    react: {
        wait: false,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default'
    }
});

export default i18n;
