import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faLongArrowAltLeft, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import OutdoorPaymentForm from 'app/components/form/OutdoorPaymentForm';
import { withRouter } from 'react-router-dom';
import VerifonePaymentModel from 'store/TerminalStore/models/VerifonePaymentModel';
import OrderApi from '../../../api/OrderApi';

@translate()
@withRouter
@inject('store')
@observer
class FormNav extends Component {
    constructor() {
        super();
        this.state = { paymentLoading: false, paymentError: false, navContainerWidth: 0 };
        this.containerRef = React.createRef();
    }

    componentDidMount() {
        this.setState({ navContainerWidth: this.containerRef.current.clientWidth / 2 });
    }

    reset() {
        this.props.store.refreshStore();
    }

    goBack() {
        const { tabNumber } = this.props;
        this.setState({ paymentError: false });

        if (tabNumber === 1) {
            this.reset();
        }

        if (tabNumber > 1) {
            let splitUrl = this.props.location.pathname.split('/');
            splitUrl[splitUrl.length - 1] = tabNumber - 1;
            const joinedUrl = splitUrl.join('/');
            this.props.history.replace(joinedUrl);
        }
    }

    goForward() {
        const { tabTitles, location: { pathname }, tabNumber } = this.props;
        const nextTabPath = pathname.substring(0, pathname.length - 1) + (tabNumber + 1);
        this.setState({ paymentError: false });

        if (tabNumber <= tabTitles.length) {
            this.props.history.push(nextTabPath);
        } else {
                this.startPayment();
        }
    }

    startPayment() {
        this.setState({ paymentLoading: true });
        const { store: { harbor: { id } }, moduleStore } = this.props;
        this.props.prePaymentPromise()
            .then(() => {
                if (moduleStore.totalIndoor > 0) {
                    OrderApi.getISV(id, moduleStore.totalIndoor)
                        .then(res => {
                            if (res && res.data && res.data.isv) {
                                moduleStore.setISVData(res.data.isv);
                                moduleStore.setFee(res.data.fee);
                            }
                            this.submitPayment();
                            // setTimeout(() => this.setState({ paymentLoading: false }), 1000);
                        })
                        .catch(err => {
                            this.submitPayment();
                        });
                } else {
                    this.completePayment();
                }

            })
            .catch(() => {
                this.setState({ paymentError: true });
                setTimeout(() => this.setState({ paymentLoading: false }), 1000);
            });
    }

    submitPayment() {
        const { store: { terminal: { isIndoor, isOutdoor } } } = this.props;
        const env = process.env.REACT_APP_ENV;

        if (isIndoor) {
            this.submitIndoorPayment()
        } else if (env === 'demo' && isOutdoor) {
            this.completePayment()
        } else if (isOutdoor) {
            this.submitOutdoorPayment()
        }
    }

    submitIndoorPayment() {
        const { store: { terminal }, moduleStore } = this.props;
        let payment = new VerifonePaymentModel(moduleStore.totalIndoor, moduleStore.orderId, moduleStore.isvData);
        terminal.startPayment(payment);
    }

    submitOutdoorPayment() {
        document.getElementById('outdoorLocale').submit();
        setTimeout(() => {
            // id=outdoorPaymentForm is located in OutdoorPaymentForm component
            document.getElementById('outdoorPaymentForm').submit();
        })
    }

    completePayment() {
        const { store: { locale: { locale }, harbor: { id } }, history, moduleStore } = this.props;
        history.push(`/${id}/payment/success/${moduleStore.orderId}/${locale}`);
    }

    render() {
        const { t, tabTitles, isTabValid, tabNumber, moduleStore } = this.props;

        return (
            <div className="form-nav">
                <div className="nav-block-l">
                    <button type="button" className="btn btn-light nav-back-btn" onClick={() => this.goBack()}>
                        <FontAwesomeIcon className="nav-back-icon" icon={faLongArrowAltLeft} />
                        {tabNumber === 1 ? t('COMMON.EXIT') : t('COMMON.BACK')}
                    </button>
                    <button type="button" className="btn btn-light reset-btn" onClick={() => this.reset()}>
                        {t('COMMON.RESET')}
                    </button>
                </div>

                <div className="nav-block-c" ref={this.containerRef}
                     style={{ left: `calc(50% - ${this.state.navContainerWidth}px)` }}>
                    <div className="progressbar-container">
                        {tabNumber <= tabTitles.length &&
                            <ul className={`progressbar p-bar-${tabTitles.length}`}>
                                {tabTitles.map((title, i) => {
                                    return (
                                        <li key={i} className={`${tabNumber >= (i + 1) ? 'active' : ''}`}>
                                            {t(title)}
                                        </li>
                                    )
                                })}
                            </ul>
                        }
                        {this.state.paymentError && tabNumber > tabTitles.length &&
                            <div className="server-error-alert">
                                <FontAwesomeIcon icon={faExclamationTriangle} />
                                <span>{t('COMMON.FORM_INVALID_TRY_AGAIN')}</span>
                                <FontAwesomeIcon icon={faExclamationTriangle} />
                            </div>
                        }
                    </div>
                </div>

                <div className="nav-block-r">
                    <button type="button" className="btn btn-light nav-next-btn"
                            disabled={!isTabValid || this.state.paymentLoading}
                            onClick={() => this.goForward()}
                    >
                        {tabNumber <= tabTitles.length ? t('COMMON.NEXT') : t('COMMON.PAY')}
                        <FontAwesomeIcon className="nav-next-icon" icon={faLongArrowAltRight} />
                    </button>
                </div>
                <OutdoorPaymentForm totalOutdoor={moduleStore.totalOutdoor} orderId={moduleStore.orderId} />
            </div>
        )
    }
}

// todo: Add hasNotReviewTab if needed (When there are no overview and it goes straight to pay)
export default FormNav;

FormNav.propTypes = {
    tabTitles: PropTypes.array.isRequired,
    isTabValid: PropTypes.bool.isRequired,
    prePaymentPromise: PropTypes.any.isRequired,
    tabNumber: PropTypes.number.isRequired,
    moduleStore: PropTypes.any.isRequired,
};
