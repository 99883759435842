import React, { Component } from 'react';
import FormOverview from 'app/components/form/overview/FormOverview';
import FormTextOverview from 'app/components/form/overview/FormTextOverview';
import FormLocationOverview from 'app/components/form/overview/FormLocationOverview';
import FormCountryOverview from 'app/components/form/overview/FormCountryOverview';
import { inject, observer } from 'mobx-react';
import { translate } from 'i18n/i18n';
import FormPriceOverview from 'app/components/form/overview/FormPriceOverview';
import FormDurationOverview from 'app/components/form/overview/FormDurationOverview';

@translate()
@inject('store')
@observer
class CamperOverviewTab extends Component {
    render() {
        const { t, store: { camper, harbor } } = this.props;
        const { form, hiddenFields } = camper;
        const { isNationalityHidden, isFirstNameHidden, isLastNameHidden, isEmailHidden } = hiddenFields;
        const { camperLot, country, firstName, lastName, email, phone, dateFrom, duration, plateNumber } = form;
        const { id } = this.props.store.harbor;

        return (
            <div className="tab-container">
                <FormOverview redBorder>
                    <FormDurationOverview dateFrom={dateFrom} duration={duration} />
                    <FormLocationOverview label={t('COMMON.STEPS_CAMPER')} value={camperLot && camperLot.name} />
                    {!isNationalityHidden && <FormCountryOverview label={t('COMMON.NATIONALITY')} value={country} /> }
                    <FormTextOverview label={t('COMMON.CAMPER_PLATE')} value={plateNumber} />
                    {!isFirstNameHidden && <FormTextOverview label={t('COMMON.FIRST_NAME')} value={firstName} />}
                    {!isLastNameHidden && <FormTextOverview label={t('COMMON.LAST_NAME')} value={lastName} />}
                    {!isEmailHidden && <FormTextOverview showWarnSign label={t('COMMON.EMAIL')} value={email} />}
                    <FormTextOverview showWarnSign label={t('COMMON.PHONE')} value={phone} />
                    {harbor.harbakioskSettings.isCreditEnabled && (
                        <>
                            <hr />
                            <p className='color-red'>{t('COMMON.ACCESS_CODE_PHONE_EMAIL_WARNING')}</p>
                        </>
                    )}
                </FormOverview>

                {id === '3da1df71-9a6b-11ea-95a3-000d3ab024ec' &&
                <div className="price-container-box ejerslev-reminder">
                    {t('COMMON.FACILITIES_INCLUDED_EJERSLEV')}
                </div>
                }

                <FormPriceOverview total={camper.total} noDivider />
            </div>
        );
    }
}

export default CamperOverviewTab;
