import { action, observable } from 'mobx';
import PrinterApi from 'api/PrinterApi';
import AbstractModulePaymentStore from 'store/PaymentStore/modules/AbstractModulePaymentStore';
import HarborApi from '../../../../api/HarborApi';
import SlipwayApi from '../../../../api/SlipwayApi';
import SlipwayPaymentViewModel from './SlipwayPaymentViewModel';
import CreditReceiptModel from '../../PrintModels/CreditReceiptModel';

class SlipwayPaymentStore extends AbstractModulePaymentStore {
    @observable slipway: SlipwayPaymentViewModel;
    @observable hasAccessCode = false;

    constructor(paymentStore) {
        super(paymentStore);
        this.payment = paymentStore;
    }

    @action
    getSlipwayRoot() {
        return this.payment.rootStore.slipway;
    }

    @action
    async finalizePayment(orderId) {
        const response = await getPaymentByOrderIdPromise(orderId)
        if (response) {
            let responseObject = response;
            const res = await HarborApi.getAccessCode(orderId);

            if (res) {
                responseObject.accessCode = res.data;
                if (res.data) {
                    this.setHasAccessCode();
                }
                this.setSlipway(responseObject);
                this.markPaymentAsPaid(orderId);
            }

            this.printReceipt(responseObject);
        }
    }

    @action
    extractTotalByOrderId(orderId) {
        return new Promise((resolve, reject) => {
            getPaymentByOrderIdPromise(orderId)
                .then((response) => {
                    this.setPaymentTotalIndoor(response.amount);
                    this.setPaymentTotalOutdoor(response.amount * 100);
                    resolve(response.amount);
                }).catch((error) => {
                    reject(error);
                });
            });
    }

    markPaymentAsPaid(orderId) {
        const q = SlipwayApi.markReservationAsPaid(orderId, this.getGateway(), this.payment.rootStore.slipway.fee);
        q.then(() => {});
    }

    @action
    setHasAccessCode() {
        this.hasAccessCode = true;
    }

    @action
    setSlipway(response) {
        this.accessCode = response.accessCode;
        this.slipway = new SlipwayPaymentViewModel(response['slipwayReservation']);
        this.payment.rootStore.slipway.setSlipwayFromApi(new SlipwayPaymentViewModel(response['slipwayReservation']));
    }

    printReceipt(response) {
        const receipt = new CreditReceiptModel().build(response, this.payment.rootStore);
        if (this.payment.rootStore.terminal.hasPrinter) {
            PrinterApi.printCreditReceipt(receipt)
                .then(() => {
                    this.setIsReceiptPrinted(true)
                });
        }
    }
}

const getPaymentByOrderIdPromise = async (orderId) => {
    const res = await SlipwayApi.getSlipwayPaymentByOrderId(orderId);
    return res.data;
}

export default SlipwayPaymentStore;
