import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import moment from 'moment';

@translate()
@inject('store')
@observer
class DurationFormInput extends Component {
    increment() {
        const { moduleStore, onChange } = this.props;
        const { duration } = moduleStore.form;

        if (onChange) {
            onChange();
        }

        moduleStore.setFormValue('duration', duration + 1);
        this.refreshStore();
    }

    decrement() {
        const { moduleStore, onChange } = this.props;
        const { duration } = moduleStore.form;

        if (onChange) {
            onChange();
        }

        if (duration > 1) {
            moduleStore.setFormValue('duration', duration - 1);
        }
        this.refreshStore();
    }

    refreshStore() {
        const { moduleStore } = this.props;
        moduleStore.setFormValue('berth', null);
        moduleStore.setFormValue('dock', null);
    }

    changeDateFrom(day) {
        const { moduleStore, onChange } = this.props;

        if (onChange) {
            onChange();
        }

        if (day === 'today') {
            moduleStore.setFormValue('dateFrom', today);
        }

        if (day === 'yesterday') {
            moduleStore.setFormValue('dateFrom', yesterday);
        }
    }

    formatDate(date) {
        let newDate = moment(date);

        switch (this.props.store.locale.locale) {
            case 'da':
                return newDate.format('DD.MM.YYYY');
            default:
                return newDate.format('YYYY-MM-DD');
        }
    }

    render() {
        const { t, moduleStore, store: { harbor: { bookOneDayOnly } } } = this.props;
        const { dateFrom, duration } = moduleStore.form;

        const isToday = today.isSame(dateFrom);
        const isYesterday = yesterday.isSame(dateFrom);
        const dayTranslation = duration > 1 ? t('COMMON.DAYS') : t('COMMON.DAY');
        const dateTo = moment(dateFrom).add(duration,'days');

        return (
            <Fragment>
                <div className="duration-arrival">
                    <span className="duration-arrival-text">
                        {t('COMMON.CHOOSE_ARRIVAL')}:
                    </span>
                    <div className="duration-btn-container">
                        <button type="button"
                                className={`duration-btn btn btn-light ${isYesterday && 'duration-active-btn'}
                                click-effect`}
                                onClick={() => this.changeDateFrom('yesterday')}>
                            {t('COMMON.YESTERDAY')}
                        </button>
                        <button type="button"
                                className={`duration-btn btn btn-light ${isToday && 'duration-active-btn'}
                                click-effect`}
                                onClick={() => this.changeDateFrom('today')}>
                            {t('COMMON.TODAY')}
                        </button>
                    </div>
                </div>

                <div className="line-break"></div>

                {bookOneDayOnly ?
                    <div className="book-one-day">
                        {t('COMMON.BOOKINGS_ONLY_ONE_DAY')}
                    </div>
                    :
                    <Fragment>
                        <div className="departure-container">
                                    <span className="duration-arrival-text">
                                        {t('COMMON.CHOOSE_DURATION_OF_STAY')}:
                                    </span>
                            <div className="duration-container">
                                <button className="duration-count-btn duration-btn-minus btn btn-light"
                                        type="button" onClick={() => this.decrement()}>
                                    <FontAwesomeIcon icon={faMinus} />
                                </button>
                                <div className="duration-count-container">
                                    <div className="duration-count">
                                        {duration}
                                    </div>
                                    <div className="duration-days">
                                        {dayTranslation}
                                    </div>
                                </div>
                                <button className="duration-count-btn duration-btn-plus btn btn-light"
                                        type="button" onClick={() => this.increment()}>
                                    <FontAwesomeIcon icon={faPlus} />
                                </button>
                            </div>
                        </div>
                    </Fragment>
                }

                <div className="line-break"></div>

                <div id={'summary'} className="duration-summary-container">
                    <div className="duration-dates-title-cont">
                        <span className="duration-dates-title">{t('COMMON.ARRIVAL')}</span>
                        <span className="duration-dates-title">{t('COMMON.DEPARTURE')}</span>
                    </div>
                    <div className="duration-dates-container">
                        <span className="duration-date">
                            {this.formatDate(dateFrom)}
                        </span>
                    <span>
                        <FontAwesomeIcon className="duration-arrow-right" icon={faLongArrowAltRight} />
                    </span>
                    <span className="duration-date">
                        {this.formatDate(dateTo)}
                    </span>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const today = moment().startOf('day');
const yesterday = moment().startOf('day').add(-1, 'days');

DurationFormInput.propTypes = {
    moduleStore: PropTypes.any.isRequired,
    onChange: PropTypes.any,
};

export default DurationFormInput;
