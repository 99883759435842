import { action, computed, observable } from 'mobx';
import PaymentFailedModel from 'store/PaymentStore/PrintModels/PaymentFailedModel';
import PrinterApi from 'api/PrinterApi';

class AbstractModulePaymentStore {
    @observable receiptPrint;
    @observable stickerPrint;
    @observable isReceiptPrinted: boolean = false;

    @observable paymentTotalIndoor = null;
    @observable paymentTotalOutdoor = null;
    @observable accessCode = null;

    constructor(paymentStore) {
        this.payment = paymentStore;
    }

    @action
    getGateway() {
        const type = this.payment.rootStore.terminal.type;

        if (type === 'indoor') {
            return 'verifone';
        }

        if (type === 'outdoor') {
            return 'nets';
        }
    }

    @action
    setIsReceiptPrinted(value) {
        this.isReceiptPrinted = value;
    }

    @action
    setPaymentTotalIndoor(value) {
        this.paymentTotalIndoor = value;
    }

    @action
    setPaymentTotalOutdoor(value) {
        this.paymentTotalOutdoor = value;
    }

    @action
    fetchPrinterStatus() {
        const isDemo = process.env.REACT_APP_ENV === 'demo';

        return new Promise((resolve, reject) => {
            if (!isDemo) {
                PrinterApi.getPrinterStatus()
                    .then((value) => {
                        this.payment.rootStore.terminal.setHasPrinter(value.data);
                        resolve();
                    })
            } else {
                resolve();
            }
        });
    }

    @computed
    get isTotalSet() {
        return (this.paymentTotalOutdoor !== null || this.paymentTotalIndoor !== null);
    }

    @action
    printPaymentFailed() {
        const paymentFailed = new PaymentFailedModel().build(this.payment.rootStore);
        PrinterApi.printPaymentFailed(paymentFailed).then();
    }
}

export default AbstractModulePaymentStore;
