import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import BookingPaymentFailed from 'app/modules/payment/modules/Booking/BookingPaymentFailed';
import CreditPaymentFailed from 'app/modules/payment/modules/Credit/CreditPaymentFailed';
import CamperPaymentFailed from 'app/modules/payment/modules/Camper/CamperPaymentFailed';
import ShopPaymentFailed from 'app/modules/payment/modules/Shop/ShopPaymentFailed';
import CraneBookingPaymentFailed from './modules/CraneBooking/CraneBookingPaymentFailed';
import ShopHackPaymentFailed from './modules/ShopHack/ShopHackPaymentFailed';
import SlipwayPaymentFailed from './modules/Slipway/SlipwayPaymentFailed';
import Loader from 'app/components/Loader';

@withRouter
@inject('store')
@observer
class PaymentFailed extends Component {
    componentDidMount() {
        const { match: { params: { orderId, locale } }, store: { payment } } = this.props;

        this.props.store.locale.setInitialLocale(locale);

        payment.fetchPaymentType(orderId);
    }


    render() {
        const { store: { payment: { paymentType, loading } } } = this.props;

        return (
            <Fragment>
                {loading && <Loader fullScreen={true} />}
                {!loading &&
                    <Fragment>
                        {paymentType === 'booking' && <BookingPaymentFailed />}
                        {paymentType === 'credit' && <CreditPaymentFailed />}
                        {paymentType === 'camper' && <CamperPaymentFailed />}
                        {paymentType === 'slipway' && <SlipwayPaymentFailed />}
                        {paymentType === 'shop' && <ShopPaymentFailed />}
                        {paymentType === 'craneBooking' && <CraneBookingPaymentFailed />}
                        {paymentType === 'shopHack' && <ShopHackPaymentFailed />}
                    </Fragment>
                }
            </Fragment>
        )
    }
}

export default PaymentFailed;
