import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LocationModal from 'app/components/form/modal/LocationModal';
import FormValueDisplay from 'app/components/form/FormValueDisplay';
import { inject, observer } from 'mobx-react';

@inject('store')
@observer
class LocationFormInput extends Component {
    constructor(props) {
        super(props);
        this.state = { open: false };
    }

    triggerOpen() {
        this.setState({ open: true }, () => this.setState({ open: false }));
    }

    render() {
        const { moduleStore, formFieldName, setTitle, changeTitle, options, isRequired } = this.props;

        return (
            <div>
                <div className="form-input" onClick={() => this.triggerOpen()}>
                    <FormValueDisplay value={moduleStore.form[formFieldName]} {...this.props} />

                    {options && options.length > 1 &&
                        <button type="button" className={`modal-form-btn ${isRequired && !moduleStore.form[formFieldName] && 'red-border'}`}>
                            {!moduleStore.form[formFieldName] ? setTitle : changeTitle}
                        </button>
                    }
                </div>

                <LocationModal {...this.props} triggerOpen={this.state.open} />
            </div>
        )
    }
}

LocationFormInput.propTypes = {
    isRequired: PropTypes.bool.isRequired,
    isValid: PropTypes.any,
    moduleStore: PropTypes.any.isRequired,
    formFieldName: PropTypes.string.isRequired,
    options: PropTypes.any.isRequired,
    value: PropTypes.any,
    setTitle: PropTypes.any.isRequired,
    changeTitle: PropTypes.any.isRequired,
    title: PropTypes.any.isRequired,
    hideOptionality: PropTypes.bool,
    onChange: PropTypes.any,
};

export default LocationFormInput;
