import React, { Component } from 'react';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import LocationFormInput from 'app/components/form/inputs/LocationFormInput';
import InputContainer from 'app/components/form/inputs/utils/InputContainer';

@translate()
@inject('store')
@observer
class CamperLocationTab extends Component {
    render() {
        const { t, store: { camper } } = this.props;
        const { form, lots } = camper;
        const { camperLot } = form;

        return (
            <div className="tab-container">
                <InputContainer>
                    <LocationFormInput
                        isRequired isValid={camperLot}
                        moduleStore={camper} formFieldName={'camperLot'} options={lots}
                        value={camperLot ? camperLot.name : null}
                        setTitle={t('COMMON.CHOOSE_PARKING_LOT')}
                        changeTitle={t('COMMON.CHANGE_LOT')}
                        title={t('COMMON.CHOSEN_LOT')}
                    />
                </InputContainer>
            </div>
        );
    }
}


export default CamperLocationTab;
