import React, { Component } from 'react';
import FormTabRouter from '../../components/form/FormTabRouter';
import { inject, observer } from 'mobx-react';
import ShopHackProductsTab from './tabs/ShopHackProductsTab';
import ShopHackOverviewTab from './tabs/ShopHackOverviewTab';
import ShopHackNav from './ShopHackNav';

@inject('store')
@observer
class ShopHack extends Component {

    buildFormTabs() {
        let tabs = [];
        let tabNames = [];

        tabs.push(<ShopHackProductsTab />);
        tabNames.push('COMMON.SHOP_PRODUCTS');

        tabs.push(<ShopHackOverviewTab />);

        return { tabs, tabNames };
    }

    render() {
        const { tabs, tabNames } = this.buildFormTabs();

        return (
            <div className="module-container">
                <FormTabRouter tabs={tabs} module={'shopHack'} />
                <ShopHackNav tabTitles={tabNames} />
            </div>
        )
    }
}

export default ShopHack;
