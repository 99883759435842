import React, { Component } from 'react';
import { translate } from 'i18n/i18n';

@translate()
class FormOverview extends Component {
    render() {
        const { t, children, redBorder } = this.props;
        return (
            <div className='form-review-container'>
                <span className="form-review-title">{t('COMMON.PREPAYMENT_CHECK')}</span>
                <div className="form-review-box">
                    <div className={ `form-review ${redBorder ? 'red-border' : ''}` }>
                        {React.Children.map(children, (child) => child)}
                    </div>
                </div>
            </div>
        );
    }
}

export default FormOverview;
