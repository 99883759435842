import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import card from 'assets/img/contactless.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltDown } from '@fortawesome/free-solid-svg-icons'
import { translate } from 'i18n/i18n';

@translate()
@withRouter
@inject('store')
@observer
class PaymentPay extends Component {
    resetTerminal() {
        const { store: { terminal } } = this.props;
        terminal.abortPayment();
        this.props.store.refreshStore();
    }

    render() {
        const { match: { params: { amount } }, store: { harbor: { currency } }, t } = this.props;
        return (
            <div className="pay-block">
                <div className="message-container">
                    <div className="pay-text">
                        {t('COMMON.PAY_WITH_CARD')}
                    </div>
                </div>
                <div>
                    <img className="contactless" alt="card" src={card} />
                </div>
                <div className="pay-amount">
                    { Number(amount).toFixed(2) } { currency }
                </div>
                <div>
                    *{t('COMMON.SIGNATURE_NOT_ACCEPTED')}
                </div>

                <FontAwesomeIcon className="pay-arrow" icon={faLongArrowAltDown} />
                <input type="button" value={t('COMMON.EXIT')} onClick={() => this.resetTerminal()}
                       className="form-btn payment-exit-btn payment-abort" />
            </div>
        )
    }
}

export default PaymentPay;
