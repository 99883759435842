class ShopReceiptModel {
    build(rootStore, i18n) {
        const locale = rootStore.locale.locale;
        const currency = rootStore.harbor.currency;
        const harborName = rootStore.harbor.name;
        const amount = rootStore.shop.total;
        const productText = this.generateProductText(rootStore, i18n);
        const productPriceText = this.generateProductPriceText(rootStore, currency);
        const productCount = this.getProductCount(rootStore);
        const vat = this.getVat(rootStore);

        return {
            locale,
            alertOnLowPaperEnabled: false,
            total: amount.toFixed(2),
            currency,
            harborName,
            productText,
            productPriceText,
            productCount,
            vat
        }
    }

    buildTest() {
        const locale = 'da';

        return {
            locale,
            alertOnLowPaperEnabled: false,
            total: (120).toFixed(2),
            currency: 'DKK',
            harborName: 'PORT',
            productText: 'TENT \n\n SHOWER x 2',
            productPriceText: '80 DKK \n\n 40 DKK',
            productCount: 2,
            vat: '24.00'
        }
    }

    generateProductText(rootStore, i18n) {
        const { servicesEnabled, facilitiesAmount, servicesArr, peopleNumber, durationNumber } = rootStore.shop;
        let text = '';

        servicesArr.forEach((service, i) => {
            if (i < 1 && servicesEnabled[service]) {
                text += (i18n.t('COMMON.' + service.toUpperCase())).toUpperCase() + '\n';

                if (peopleNumber === 1) {
                    text += (i18n.t('COMMON.PERSON')).toUpperCase() + ' x ' + peopleNumber + '\n';
                } else {
                    text += (i18n.t('COMMON.PEOPLE')).toUpperCase() + ' x ' + peopleNumber + '\n';
                }

                if (durationNumber === 1) {
                    text += (i18n.t('COMMON.NIGHT')).toUpperCase() + ' x ' + durationNumber + '\n';
                } else {
                    text += (i18n.t('COMMON.NIGHTS')).toUpperCase() + ' x ' + durationNumber + '\n';
                }
            }

            if (i >= 1 && (facilitiesAmount[service] !== 0)) {
                text += (i18n.t('COMMON.' + service.toUpperCase())).toUpperCase()
                    +' x ' + facilitiesAmount[service] + '\n';
            }
        });

        return text;
    }

    generateProductPriceText(rootStore, currency) {
        const { servicesEnabled, facilitiesAmount, servicesArr, peopleNumber, durationNumber } = rootStore.shop;
        let text = '';

        servicesArr.forEach((service, i) => {
            if (i < 1 && servicesEnabled[service]) {
                text += ' \n' + (20 * peopleNumber * durationNumber).toFixed(2) + ' ' + currency + '\n \n';
            }

            if (i >= 1 && (facilitiesAmount[service] !== 0)) {
                text += (20 * facilitiesAmount[service]).toFixed(2)  + currency + '\n';
            }
        });

        return text;
    }

    getProductCount(rootStore) {
        const { servicesEnabled, facilitiesAmount, servicesArr } = rootStore.shop;
        let count = 0;

        servicesArr.forEach((service, i) => {
            if (i < 1 && servicesEnabled[service]) {
                count = count + 3;
            }

            if (i >= 1 && (facilitiesAmount[service] !== 0)) {
                count++;
            }
        });

        return count;
    }

    getVat(rootStore) {
        return (rootStore.shop.total - (rootStore.shop.total / 1.25)).toFixed(2);
    }
}

export default ShopReceiptModel;
