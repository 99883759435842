import React, { Component } from 'react';
import { translate } from 'i18n/i18n';

@translate()
class PaymentSuccessTitle extends Component {

    render() {
        const { t } = this.props;
        return (
            <div className="message-container">
                <div className="message-text">
                    {t('COMMON.PAYMENT_SUCCESSFUL')}
                </div>
            </div>
        );
    }
}

export default PaymentSuccessTitle;
