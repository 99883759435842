import React, { Component } from 'react';
import { translate } from '../../../../i18n/i18n';
import { inject, observer } from 'mobx-react';

@translate()
@inject('store')
@observer
class CraneBookingTypeTab extends Component {
    constructor() {
        super();
        this.state= {
            type: null,
            price: null
        }
    }

    componentDidMount() {
        this.setState({
            type: this.props.store.craneBooking.form.type,
            price: this.props.store.craneBooking.form.price,
        })
    }

    setBookingType(type, price) {
        this.props.store.craneBooking.setFormValue('type', type);
        this.props.store.craneBooking.setFormValue('price', price);
        this.setState({
            type,
            price
        });
        this.props.store.craneBooking.savePaymentRequest('craneBooking', price);
    }
    render() {
        return (
            <div className='vertical-centered'>
                <div className="selection-btn-container">
                    <button
                        onClick={()=>this.setBookingType(0, 940)}
                        className={`${this.state.type === 0 ? 'modal-form-btn-active' : 'modal-form-btn'}`}
                    >
                        Op til 5 tons uden mast (940 kr)
                    </button>
                </div>
                <div className="selection-btn-container">
                    <button
                        onClick={()=>this.setBookingType(1, 1230)}
                        className={`${this.state.type === 1 ? 'modal-form-btn-active' : 'modal-form-btn'}`}
                    >
                        Op til 5 tons med mast (1230 kr)
                    </button>
                </div>
                <div className="selection-btn-container">
                    <button
                        onClick={()=>this.setBookingType(2, 1490)}
                        className={`${this.state.type === 2 ? 'modal-form-btn-active' : 'modal-form-btn'}`}
                    >
                        5-10 tons uden mast (1490 kr)
                    </button>
                </div>
                <div className="selection-btn-container">
                    <button
                        onClick={()=>this.setBookingType(3, 1780)}
                        className={`${this.state.type === 3 ? 'modal-form-btn-active' : 'modal-form-btn'}`}
                    >
                        5-10 tons med mast (1780 kr)
                    </button>
                </div>
            </div>
        )
    }
}

export default CraneBookingTypeTab;
