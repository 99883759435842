import { observable } from 'mobx';

class SlipwayPaymentViewModel {
    @observable phone;
    @observable plateNumber;

    constructor(slipway) {
        this.phone = slipway.phone ? slipway.phone : '+';
        this.plateNumber = slipway.plateNumber;
    }
}

export default SlipwayPaymentViewModel;
