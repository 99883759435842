import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const FormLocationOverview = ({ label, value, secondaryValue }) => {
    if (!value) {return null}
    return (
        <div className="overview-block">
            <div className="overview-label">{label}:</div>
            <div className="w-100">
                <div className="overview-text-round">{value}</div>
                {secondaryValue &&
                <Fragment>
                    <span className="overview-score">–</span>
                    <div className="overview-text-round">{secondaryValue}</div>
                </Fragment>
                }
            </div>
        </div>
    );
};

FormLocationOverview.propTypes = {
    label: PropTypes.any.isRequired,
    value: PropTypes.any,
    secondaryValue: PropTypes.any,
};

export default FormLocationOverview;
