import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import PaymentFailedForm from 'app/modules/payment/components/PaymentFailedForm';

@withRouter
@inject('store')
@observer
class SlipwayPaymentFailed extends Component {
    constructor() {
        super();
        this.state = {
            total: null,
        };
    }

    componentDidMount() {
        const { payment: { slipway } } = this.props.store;
        const { params: { orderId } } = this.props.match;

        slipway.extractTotalByOrderId(orderId)
            .then((res) => {
                this.setState({ total: res });
                slipway.fetchPrinterStatus();
            })
    }

    render() {
        return (
            <Fragment>
                <PaymentFailedForm total={this.state.total} />
            </Fragment>
        )
    }
}

export default SlipwayPaymentFailed;
