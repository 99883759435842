import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { translate } from 'i18n/i18n';
import VerifonePaymentModel from '../../../../store/TerminalStore/models/VerifonePaymentModel';

@translate()
@withRouter
@inject('store')
@observer
class PaymentPrinterOffline extends Component {
    componentDidMount() {
        const { match: { params: { orderId, locale } }, store: { payment } } = this.props;

        this.props.store.locale.setInitialLocale(locale ? locale : window.harbaLocale);

        payment.fetchPaymentType(orderId);
    }

    getModuleFromOrderID(orderId) {
        switch (orderId) {
            case 'shop':
                return 'shop';
            case 'craneBooking':
                return 'craneBooking';
            case 'shopHack':
                return 'shopHack';
            default:
                return 'shop';
        }
    }

    pay() {
        const { store: { terminal, payment, ...restStore }  } = this.props;
        const { params: { orderId, amount } } = this.props.match;
        const currentModule = payment.paymentType || this.getModuleFromOrderID(orderId);

        if (currentModule) {
            const moduleStore = restStore[currentModule];
            if (terminal.isIndoor) {
                let payment = new VerifonePaymentModel(amount, orderId, moduleStore.isvData, true);
                terminal.startPayment(payment);
            }
        }

        if (terminal.isOutdoor) {
            setTimeout(() => {
                // Initiate payment
                document.getElementById('payWithoutReceipt').submit();
            })
        }
    }

    exit() {
        this.props.store.refreshStore();
    }

    render() {
        const { match: { params: { amount, orderId } }, t, store: { terminal } } = this.props;
        const isDisabled = !terminal.isReady;

        const paymentTotalOutdoor = amount * 100;

        return (
            <div className="printer-offline-block">
                <div className="message-container">
                    <div className="printer-offline">
                        {t('COMMON.PRINTER_OFFLINE_NOTICE')}
                    </div>
                    <div className="printer-offline">
                        {t('COMMON.PRINTER_OFFLINE_INFO')}
                    </div>
                    <div className="printer-offline-continue">
                        {t('COMMON.PRINTER_OFFLINE_CONTINUE')}
                    </div>

                    <div className="printer-continue-box">
                        <button type="button"
                                className="btn payment-btn payment-exit-btn"
                                onClick={() => this.exit()}>
                            {t('COMMON.EXIT')}
                        </button>
                        <button type="button"
                                disabled={isDisabled}
                                className="btn payment-btn"
                                onClick={() => this.pay()}>
                            {t('COMMON.CONTINUE')}
                        </button>
                    </div>

                    <form action="http://localhost/payment/doPayment.aspx"
                          method="post"
                          target="_top"
                          id={'payWithoutReceipt'}>
                        <input type="text" name="amount" style={{ display: 'none' }}
                               defaultValue={paymentTotalOutdoor} />
                        <input type="text" name="boorefno" defaultValue={orderId} style={{ display: 'none' }} />
                    </form>
                </div>
            </div>
        )
    }
}

export default PaymentPrinterOffline;
