import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import enterPin from 'assets/img/enter_pin.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltDown } from '@fortawesome/free-solid-svg-icons'
import { translate } from 'i18n/i18n';

@translate()
@inject('store')
@observer
class PaymentEnterPin extends Component {
    resetTerminal() {
        const { store: { terminal } } = this.props;
        terminal.abortPayment();
        this.props.store.refreshStore();
    }

    render() {
        const { t } = this.props;
        return (
            <div className="pay-block">
                <div className="message-container">
                    <div className="pay-text">
                        {t('COMMON.ENTER_PIN')}
                    </div>
                </div>
                <div>
                    <img className="enter-pin" alt="card" src={enterPin} />
                </div>

                <FontAwesomeIcon className="pay-arrow" icon={faLongArrowAltDown} />
                <input type="button" value={t('COMMON.EXIT')} onClick={() => this.resetTerminal()}
                       className="form-btn payment-exit-btn payment-abort" />
            </div>
        )
    }
}

export default PaymentEnterPin;
