import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { translate } from 'i18n/i18n';
import PropTypes from 'prop-types';
import moment from 'moment';
import { inject, observer } from 'mobx-react';

@translate()
@inject('store')
@observer
class FormDurationOverview extends Component {
    formatDate(date) {
        let newDate = moment(date);

        switch (this.props.store.locale.locale) {
            case 'da':
                return newDate.format('DD.MM.YYYY');
            default:
                return newDate.format('YYYY-MM-DD');
        }
    }

    render() {
        const { t, dateFrom, duration } = this.props;
        if (!dateFrom) {return null}
        const dateTo = moment(dateFrom).add(duration,'days').format();
        return (
            <div className="overview-date-container">
                <div className="overview-date-title-box">
                    <span className="overview-date-title">{t('COMMON.ARRIVAL')}</span>
                    <span className="overview-date-title">{t('COMMON.DEPARTURE')}</span>
                </div>
                <div className="overview-date-box">
                    <span className="overview-date">{this.formatDate(dateFrom)}</span>
                    <span>
                        <FontAwesomeIcon className="overview-date-arrow" icon={faLongArrowAltRight} />
                    </span>
                    <span className="overview-date">{this.formatDate(dateTo)}</span>
                </div>
            </div>
        );
    }
}

FormDurationOverview.propTypes = {
    dateFrom: PropTypes.any.isRequired,
    duration: PropTypes.any.isRequired,
};

export default FormDurationOverview;
