import React, { Component } from 'react';
import { translate } from 'i18n/i18n';
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react';

@translate()
@inject('store')
@observer
class PaymentReceiptCodeReminder extends Component {

    render() {
        const { t, model } = this.props;
        const { email, phone } = model;

        return (
            <div className="payment-receipt-message">
                {t('COMMON.RECEIPT_SENT_MESSAGE')}:
                {email &&
                    <div className="payment-receipt-text">{email}</div>
                }
                {phone !== '+' &&
                    <div className="payment-receipt-text">{phone}</div>
                }
            </div>
        );
    }
}

PaymentReceiptCodeReminder.propTypes = {
    model: PropTypes.any.isRequired
};

export default PaymentReceiptCodeReminder;
