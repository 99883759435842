import React, { Fragment } from 'react';
import 'scss/modules/information.scss';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom'

const Information = ({ store: { harbor }, store }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { search } = useLocation();

    const goBack = () => {
        const fromUrl = new URLSearchParams(search).get('from');

        if (fromUrl.indexOf('/payment/') !== -1) {
            store.refreshStore()
        } else {
            history.push(fromUrl);
        }
    }

    return (
        <Fragment>
            <div className="information-container">
                <div className="information-message-text">
                    {t('COMMON.INFO_MESSAGE')}
                </div>
                {harbor.phone &&
                    <div className="contact-text-phone">
                        {t('COMMON.PHONE')}: {harbor.phone}
                    </div>
                }
                {harbor.email &&
                    <div className="contact-text">
                        {t('COMMON.EMAIL')}: {harbor.email}
                    </div>
                }
                <input type="button" value={t('COMMON.EXIT')} onClick={goBack}
                       className="information-module-btn btn btn-light" />
            </div>
        </Fragment>
    )
}
export default inject('store')(observer(Information));
