import React, { Component } from 'react';
import FormOverview from '../../../components/form/overview/FormOverview';
import FormTextOverview from '../../../components/form/overview/FormTextOverview';
import FormPriceOverview from '../../../components/form/overview/FormPriceOverview';
import { translate } from '../../../../i18n/i18n';
import { inject, observer } from 'mobx-react';

@translate()
@inject('store')
@observer
class ShopHackOverviewTab extends Component {
    render() {
        const { t, store: { shopHack } } = this.props;
        const { form } = shopHack;
        const { order, totalPrice } = form;

        return (
            <div className="tab-container">
                <FormOverview>
                    <FormTextOverview
                        label={t('COMMON.SHOP_PRODUCTS')}
                        value={order.map((item, index)=>
                            <div key={index}>{item.quantity > 0 ? `${item.name} | ${t('COMMON.SHOP_QTY')}: ${item.quantity}` : null}</div>)} />
                </FormOverview>

                <FormPriceOverview total={totalPrice} noDivider />
            </div>
        )
    }
}

export default ShopHackOverviewTab;
