import moment from 'moment';

class CamperReceiptModel {
    build(response, rootStore) {
        const { amount, vatAmount, accessCode } = response;

        const locale = rootStore.locale.locale;
        const currency = rootStore.harbor.currency;
        const harborName = rootStore.harbor.name;

        let dateFrom;
        let dateTo;

        switch (locale) {
            case 'da':
                dateFrom = moment(response['camperReservation'].dateFrom).format('DD.MM.YYYY');
                dateTo =  moment(response['camperReservation'].dateTo).format('DD.MM.YYYY');
                break;
            default:
                dateFrom = moment(response['camperReservation'].dateFrom).format('YYYY-MM-DD');
                dateTo =  moment(response['camperReservation'].dateTo).format('YYYY-MM-DD');
                break;
        }

        return {
            locale,
            alertOnLowPaperEnabled: false,
            dateFrom,
            dateTo,
            total: amount.toFixed(2),
            vat: vatAmount ? vatAmount.toFixed(2) : (amount - (amount / 1.25)).toFixed(2),
            currency,
            harborName,
            accessCode
        }
    }

    buildTest() {
        const locale = 'da';
        let dateFrom = moment().format('DD.MM.YYYY');
        let dateTo = moment().add(1,'days').format('DD.MM.YYYY');

        return {
            locale,
            alertOnLowPaperEnabled: false,
            accessCode: '5555',
            dateFrom,
            dateTo,
            total: '27.36',
            vat: '3.00',
            currency: 'DKK',
            harborName: 'PORT',
        }
    }
}

export default CamperReceiptModel;
