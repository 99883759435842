import React, { Component, Fragment } from 'react';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import shelter from 'assets/img/shelter.svg'
import shelterWhite from 'assets/img/shelterWhite.svg'
import tent from 'assets/img/tent.svg'
import tentWhite from 'assets/img/tentWhite.svg'
import power from 'assets/img/power.png'
import toilet from 'assets/img/toilet.png'
import shower from 'assets/img/shower.png'
import laundry from 'assets/img/laundry.png'
import wifiWhite from 'assets/img/wifi-white.png'
import powerWhite from 'assets/img/power-white.png'
import toiletWhite from 'assets/img/toilet-white.png'
import showerWhite from 'assets/img/shower-white.png'
import laundryWhite from 'assets/img/laundry-white.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

@translate()
@inject('store')
@observer
class ShopServiceStep extends Component {
    render() {
        const { currency } = this.props.store.harbor;
        const { t, store: { shop } } = this.props;

        const { servicesArr, servicesEnabled, facilitiesAmount, peopleNumber, durationNumber } = shop;
        return (
            <Fragment>
                <div className="utility-container">

                    <div className="serviceContainer">
                        <div id={'notFree'} className="utility-service">
                            <span className="title">
                                    {t('COMMON.SERVICE_TITLE')}
                            </span>
                        </div>
                        <div className="utilities-container">
                            <div className="centered-block">
                                <div className="amount-title">{t('COMMON.NUMBER_PEOPLE')}:</div>
                                <div className="duration-container-auto">
                                    <button className="duration-count-btn duration-btn-minus btn btn-light decrement"
                                            type="button" onClick={() => shop.decrementPeople()}>
                                        <FontAwesomeIcon icon={faMinus} />
                                    </button>
                                    <div className="duration-count-container">
                                        <div className="duration-count">
                                            {peopleNumber}
                                        </div>
                                        <div className="duration-days">
                                            {peopleNumber === 1 ? t('COMMON.PERSON') : t('COMMON.PEOPLE')}
                                        </div>
                                    </div>
                                    <button className="duration-count-btn duration-btn-plus btn btn-light increment"
                                            type="button" onClick={() => shop.incrementPeople()}>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </button>
                                </div>
                            </div>
                            {servicesArr.map((service, i) => {
                                if (i === 0) {
                                    return (
                                        <button key={i}
                                                className={`service utility btn btn-light
                                                    ${servicesEnabled[service] ? 'utility-selected' : ''}
                                                `}
                                                onClick={() => {}}>

                                            <img className="image"
                                                 alt={'test'}
                                                 src={servicesEnabled[service] ?
                                                     images[service + 'White'] :
                                                     images[service]
                                                 } />
                                            <div className="utility-title">
                                                {t('COMMON.' + service.toUpperCase())}
                                            </div>
                                            <span className="utility-price">
                                                {20 + ' ' + currency + t('COMMON.PER_DAY')
                                                + '\n ' + t('COMMON.FOR_ONE_PERSON')}
                                            </span>
                                        </button>
                                    )
                                }
                                return null;
                            })}
                            <div className="centered-block">
                                <div className="amount-title">{t('COMMON.STAY_DURATION')}:</div>
                                <div className="duration-container-auto">
                                    <button className="duration-count-btn duration-btn-minus btn btn-light decrement"
                                            type="button" onClick={() => shop.decrementDuration()}>
                                        <FontAwesomeIcon icon={faMinus} />
                                    </button>
                                    <div className="duration-count-container">
                                        <div className="duration-count">
                                            {durationNumber}
                                        </div>
                                        <div className="duration-days">
                                            {durationNumber === 1 ? t('COMMON.NIGHT') : t('COMMON.NIGHTS')}
                                        </div>
                                    </div>
                                    <button className="duration-count-btn duration-btn-plus btn btn-light increment"
                                            type="button" onClick={() => shop.incrementDuration()}>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="serviceContainer">
                        <div id={'notFree'} className="utility-service">
                            <span className="title">
                                    {t('COMMON.SHOP_FACILITIES')}
                            </span>
                        </div>
                        <div id={'utilities'} className="utilities-container">
                            {servicesArr.map((service, i) => {
                                if (i > 0) {
                                    return (
                                        <div key={i}
                                                className="btn btn-light shop-utility"
                                                onClick={() => {}}>
                                            <div className="utility-block">
                                                <div>
                                                    <button className="duration-count-borderless duration-btn-minus
                                                    btn btn-light no-margin"
                                                            type="button"
                                                            onClick={() => {shop.decrementFacilityAmount(service)}}>
                                                        <FontAwesomeIcon icon={faMinus} />
                                                    </button>
                                                </div>
                                                <div>
                                                    <img className="utility-image"
                                                         alt={'test'}
                                                         src={images[service]} />
                                                    <div className="utility-title">
                                                        {t('COMMON.' + service.toUpperCase())}
                                                    </div>
                                                    <div className="utility-price">
                                                        {20 + ' ' + currency}
                                                    </div>
                                                </div>
                                                <div>
                                                    <button className="duration-count-borderless duration-btn-plus
                                                    btn btn-light no-margin"
                                                            type="button"
                                                            onClick={() => {shop.incrementFacilityAmount(service)}}>
                                                        <FontAwesomeIcon icon={faPlus} />
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="amount-block">
                                                {t('COMMON.AMOUNT')}: {facilitiesAmount[service]}
                                            </div>
                                        </div>
                                    )
                                }
                                return null;
                            })}
                        </div>
                    </div>

                </div>
            </Fragment>
        );
    }
}

const images = {
    shelter: shelter,
    shelterWhite: shelterWhite,
    tent: tent,
    tentWhite: tentWhite,
    power: power,
    toilet: toilet,
    shower: shower,
    dryer: laundry,
    washer: laundry,
    wifiWhite: wifiWhite,
    powerWhite: powerWhite,
    toiletWhite: toiletWhite,
    showerWhite: showerWhite,
    laundryWhite: laundryWhite,
};

export default ShopServiceStep;
