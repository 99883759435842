import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { translate } from 'i18n/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import VerifonePaymentModel from 'store/TerminalStore/models/VerifonePaymentModel';
import OrderApi from '../../../../api/OrderApi';

@translate()
@withRouter
@inject('store')
@observer
class ShopNav extends Component {
    isStepActive(step) {
        const { location: { pathname } } = this.props;
        const currentStep = Number(pathname.charAt(pathname.length - 1));

        return currentStep >= step;
    }

    getRightButtonTitle(step) {
        const { t } = this.props;

        if (step === 1) {
            return t('COMMON.REVIEW');
        }

        if (step > 1) {
            return t('COMMON.PAY');
        }
    }

    pay() {
        const { store: { shop, harbor: { id } } } = this.props;
        OrderApi.getISV(id, shop.total)
            .then(res => {
                if (res && res.data && res.data.isv) {
                    shop.setISVData(res.data.isv);
                }
                this.submitPayment();
            })
            .catch(err => {
                this.submitPayment();
            });

    }

    submitPayment(){
        const { store: { shop, terminal } } = this.props;
        const env = process.env.REACT_APP_ENV;
        const kioskType = process.env.REACT_APP_KIOSK_TYPE;
        if (env === 'demo' && kioskType === 'outdoor') {
            this.completeDemoPayment('shop');
        } else {
            if (terminal.isIndoor) {
                let payment = new VerifonePaymentModel(shop.total, 'shop', shop.isvData);
                terminal.startPayment(payment);
            }
        }
    }

    completeDemoPayment(orderId) {
        const { store: { locale : { locale }, harbor: { id } }, history } = this.props;
        history.push(`/${id}/payment/success/${orderId}/${locale}`);
    }

    render() {
        const { t,
            location: { pathname },
            store: { shop, terminal, locale: { locale } }
        } = this.props;

        const step = Number(pathname.charAt(pathname.length - 1));

        const { paymentTotal, orderId, total } = shop;

        const goBack = () => () => {
            if (step === 1) {
                this.props.store.refreshStore();
            } else {
                this.props.history.goBack();
            }

        };

        const goForward = () => () => {
            if (step === 2) {
                this.pay();
            } else {
                this.props.history.push(pathname.substring(0, pathname.length - 1) + (step + 1));
            }
        };


        const isDisabled = () => {
            // If no internet, block access to next page
            if (!terminal.isReady) {
                return true;
            }

            if (step === 1) {
                if (total === 0) {
                    return true;
                }
            }

            if (step === 2) {

            }

        };

        const resetForm = () => () => {
            this.props.store.refreshStore();
        };


        return (
            <div className="shop-nav">
                <Route exact path="/:harborId/shop/:step([0-3])?">
                    <div className="nav-container">
                        <div className="nav-element-left">
                            <button type="button" className="btn btn-light nav-btn nav-back-btn" onClick={goBack()}>
                                <FontAwesomeIcon className="nav-back-icon" icon={faLongArrowAltLeft} />
                                {step === 1 ? t('COMMON.EXIT') : t('COMMON.BACK')}
                            </button>
                            <button type="button" className="btn btn-light nav-btn reset-btn" onClick={resetForm()}>
                                {t('COMMON.RESET')}
                            </button>
                        </div>
                        <div className="nav-element-center">
                        </div>
                        <div className="nav-element-right">
                            <form action="http://localhost/payment/doPayment.aspx"
                                  method="post"
                                  id={'camperForm'}
                                  style={{ display: 'none' }}
                                  target="_top">
                                <input type="text" name="amount" defaultValue={paymentTotal} />
                                <input type="text" name="boorefno" defaultValue={orderId} />
                                <input type="submit" value={''} />
                            </form>

                            <form id={'locale'} action={`saveLocale://${locale}`} style={{ display: 'none' }}>
                                <input type="submit" value={''} />
                            </form>

                            <button disabled={isDisabled()}
                                    type="button"
                                    className="btn btn-light nav-btn nav-next-btn"
                                    onClick={goForward()}>
                                {this.getRightButtonTitle(step)}
                                <FontAwesomeIcon className="nav-next-icon" icon={faLongArrowAltRight} />
                            </button>
                        </div>
                    </div>
                </Route>
            </div>
        )
    }
}

export default ShopNav;
