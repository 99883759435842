import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { translate } from 'i18n/i18n';
import FormNav from 'app/components/form/FormNav';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import FormValidator from 'store/validators/FormValidator';

@translate()
@withRouter
@inject('store')
@observer
class CamperNav extends Component {
    isTabValid(tabNumber) {
        const activeTab = this.props.tabTitles[tabNumber - 1];
        const { form, hiddenFields, phonePreselected } = this.props.store.camper;
        const { isNationalityHidden, isFirstNameHidden, isLastNameHidden, isEmailHidden  } = hiddenFields;
        const { camperLot, country, firstName, lastName, phone, email, plateNumber } = form;

        const { empty, isEmailValidForDisplay, isPhoneValidForDisplay, phoneEmpty } = new FormValidator();

        switch (activeTab) {
            case 'COMMON.STEPS_DURATION':
                return true;
            case 'COMMON.STEPS_LOCATION':
                return !empty(camperLot);
            case 'COMMON.INFO_TITLE':
                return ((isNationalityHidden || !empty(country)) && !empty(plateNumber));
            case 'COMMON.STEPS_CONTACT_INFORMATION':
            {
                let isValid = true;
                if (!isFirstNameHidden && empty(firstName)) {isValid = false}
                if (!isLastNameHidden && empty(lastName)) {isValid = false}

                if (!isEmailHidden) {
                    if (!isEmailValidForDisplay(email) && !empty(email)) {
                        isValid = false
                    }

                    if (!isEmailValidForDisplay(email) && empty(email) && !isPhoneValidForDisplay(phone)) {
                        isValid = false;
                    }
                }

                if (!phone) {
                    isValid = false;
                }

                if (!isPhoneValidForDisplay(phone) && !phoneEmpty(phone) && !phonePreselected && phone.length < 5) {
                    isValid = false
                }

                if (!isPhoneValidForDisplay(phone) && !phoneEmpty(phone) && !isEmailValidForDisplay(email) && phone.length < 5) {
                    isValid = false;
                }

                return isValid;
            }
            default: return true;
        }
    }

    render() {
        const { location: { pathname }, store: { camper } } = this.props;
        const tabNumber = Number(pathname.charAt(pathname.length - 1));

        return (
            <FormNav tabTitles={this.props.tabTitles}
                     tabNumber={tabNumber}
                     isTabValid={this.isTabValid(tabNumber)}
                     prePaymentPromise={camper.createCamperReservationPromise}
                     moduleStore={camper}
            />
        )
    }
}

CamperNav.propTypes = {
    tabTitles: PropTypes.array.isRequired
};

export default CamperNav;
