import * as Sentry from '@sentry/react';

class ConnectionObserver {
    isConnected = true;
    reportOfflineAfterSeconds = 10 * 60 * 1000;
    offlineReportCount = 0;
    offlineReportCountStorage = '';
    maxOfflineReportCount = 2;
    disconnectionTime = null;
    disconnectionTimeStorage = '';
    harborName = '';
    timeout = null;

    constructor(connectionType: string) {
        this.connectionType = connectionType;
        this.disconnectionTimeStorage = connectionType + 'DisconnectedAt';
        this.offlineReportCountStorage = connectionType + 'offlineReportCount';
        if (typeof localStorage !== 'undefined') {
            this.getDisconnectTimeFromStorage();
            this.getOfflineReportCountFromStorage();
        }
    }


    setHarborName(harborName) {
        this.harborName = harborName;
    }

    setIsConnected(value) {
        this.isConnected = value;
        if (typeof localStorage !== 'undefined') {
            if (!this.isConnected) {
                if (!this.timeout) {
                    this.createTimeout();
                }
            } else {
                this.offlineReportCount = 0;
                this.disconnectionTime = null;
                this.setDisconnectTimeToStorage();
                this.setOfflineReportCountToStorage();
                clearTimeout(this.timeout);
            }
        }
    }

    createTimeout() {

        this.timeout = setTimeout(() => {
            this.createTimeout();
            this.createDisconnectedTerminalError();
        }, this.reportOfflineAfterSeconds / 2);
    }

    createDisconnectedTerminalError() {
        if (!this.disconnectionTime) {
            this.disconnectionTime = Date.now();
            this.setDisconnectTimeToStorage();
            this.offlineReportCount = 0;
            this.setOfflineReportCountToStorage();
        }
        if (this.isTimePastThreshold() && !this.isConnected) {
            this.offlineReportCount++;
            this.setOfflineReportCountToStorage();
            if (this.offlineReportCount >= this.maxOfflineReportCount) {
                clearTimeout(this.timeout);
            }
            if (process.env.REACT_APP_ENV !== 'production') {
                console.log(this.getErrorMessage());
            } else {
                Sentry.captureMessage(this.getErrorMessage());
            }
        }
    }

    getErrorMessage() {
        const timeSinceFirstOffline =
            this.reportOfflineAfterSeconds * this.offlineReportCount / 60000;
        return this.connectionType + ' is disconnected in ' +
            this.harborName + '. ~' + timeSinceFirstOffline +
            'minutes';
    }

    isTimePastThreshold() {
        const timeSinceDisconnect = Date.now() - this.disconnectionTime;
        const maxReportTimeDiff = this.reportOfflineAfterSeconds * (this.maxOfflineReportCount + 1);
        const currentReportTimeDiff = this.reportOfflineAfterSeconds * (this.offlineReportCount + 1);
        return timeSinceDisconnect < maxReportTimeDiff && timeSinceDisconnect > currentReportTimeDiff;
    }


    getDisconnectTimeFromStorage() {
        this.disconnectionTime = Number(localStorage.getItem(this.disconnectionTimeStorage));
    }

    setDisconnectTimeToStorage() {
        localStorage.setItem(this.disconnectionTimeStorage, this.disconnectionTime);
    }

    removeDisconnectTimeToStorage() {
        localStorage.removeItem(this.disconnectionTimeStorage);
    }


    getOfflineReportCountFromStorage() {
        this.offlineReportCount = Number(localStorage.getItem(this.offlineReportCountStorage));
    }

    setOfflineReportCountToStorage() {
        localStorage.setItem(this.offlineReportCountStorage, this.offlineReportCount);
    }

    removeOfflineReportCountToStorage() {
        localStorage.removeItem(this.offlineReportCountStorage);
    }

}

export default ConnectionObserver;
