import Api from 'api/api';

class CreditApi {
    getDoorPrices(harborId) {
        return Api.get(`/public/access-control/prices/${harborId}`);
    }

    postTopUp(body: any, harborId: string) {
        return Api.post(`/public/credits/top_up/${harborId}`, body);
    }

    markCreditAsPaid(orderId: string, gateway, fee = 0) {
        return Api.patch(`/public/credits/verify_nets/${orderId}?gateway=${gateway}&fee=${fee}`);
    }

    getCreditPaymentByOrderId(orderId: string) {
        return Api.get(`/public/credits/credit/${orderId}`);
    }

    getActSettings(harborId) {
        return Api.get(`/public/access-control/settings/${harborId}`);
    }
}

export default new CreditApi();
