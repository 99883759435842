import i18n from 'i18n/i18n';
import { observable, action } from 'mobx';
import ukFlag from 'assets/img/gb.svg';
import daFlag from 'assets/img/dk.svg';
import deFlag from 'assets/img/de.svg';
import LocaleApi from 'api/LocaleApi';

class LocaleStoreModel {
    locales = [
        { locale: 'en-US', flag: ukFlag },
        { locale: 'da', flag: daFlag },
        { locale: 'de', flag: deFlag }
    ];

    @observable locale = 'en-US';
    // This locale is set at the first screen and after payment redirect
    @observable initialLocaleSelection = process.env.REACT_APP_ENV === 'development';

    constructor(rootStore) {
        this.rootStore = rootStore;
        i18n.changeLanguage(this.locale).then();
    }

    @action
    setLocale(locale) {
        this.locale = locale;
        i18n.changeLanguage(locale).then();

        if (!this.rootStore.harbor.isTranslationAvailableForLocale(locale)) {
            this.rootStore.harbor.setIsWelcomeSkipped(true);
        }

        LocaleApi.saveLocale(locale)
            .then();
    }

    @action
    setInitialLocale(locale) {
        window.harbaLocale = locale;
        this.setLocale(locale);
        this.initialLocaleSelection = true;
    }
}

export default LocaleStoreModel;
