import { action, computed, observable } from 'mobx';

class HarborStoreModel {
    @observable id;
    @observable name;
    @observable utilities;
    @observable bookOneDayOnly;
    @observable hasFreeharbor;
    @observable email;
    @observable phone;
    @observable currency;
    @observable translations;
    @observable isWelcomeSkipped = process.env.REACT_APP_ENV === 'development';
    @observable docks = [];
    @observable loading = true;
    @observable docksLoading = true;
    @observable requiresCrewMembersEnabled = false;
    @observable isCrewMemberCorrectType = false;
    @observable crewMembersLimit = 0;
    @observable departureTime;
    @observable lon;
    @observable lat;
    @observable bookingPricingType = 'by_boat_length';

    @observable harbakioskSettings = {
        isEnabled: false,
        paperCount: 0,
        isCreditEnabled: false,
        isCamperEnabled: false,
        isSlipwayEnabled: false,
        shopEnabled: null,
        stickerInitials: null,
        craneBookingEnabled: false,
        shopHackEnabled: false,
        pinShownOnScreen: false,
        actDoorOnMenuEnabled: false,
        actDoor: null
    };

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @action.bound
    setHarbakioskSettings(data) {
        this.harbakioskSettings.paperCount = data.paperCount;
        this.harbakioskSettings.isCreditEnabled = data.isCreditEnabled;
        this.harbakioskSettings.isCamperEnabled = data.isCamperEnabled;
        this.harbakioskSettings.isSlipwayEnabled = data.isSlipwayEnabled;
        this.harbakioskSettings.shopEnabled = data.shopEnabled;
        this.harbakioskSettings.stickerInitials = data.stickerInitials;
        this.harbakioskSettings.isEnabled = data.isEnabled;
        this.harbakioskSettings.craneBookingEnabled = data.craneBookingEnabled;
        this.harbakioskSettings.shopHackEnabled = data.shopHackEnabled;
        this.harbakioskSettings.guestEmailHidden = data.guestEmailHidden;
        this.harbakioskSettings.pinShownOnScreen = data.pinShownOnScreen;
        this.harbakioskSettings.actDoorOnMenuEnabled = data.actDoorOnMenuEnabled;
        this.harbakioskSettings.actDoor = data.actDoor;
    }

    @action.bound
    setHarbor(harbor) {
        this.id = harbor.id;
        this.name = harbor.name;
        this.utilities = harbor.utilities;
        this.email = harbor.email;
        this.phone = harbor.phone;
        this.bookOneDayOnly = harbor.bookOneDayOnly;
        this.hasFreeharbor = harbor.hasFreeharbor;
        this.currency = harbor.currency;
        this.requiresCrewMembersEnabled = harbor.requiresCrewMembers;
        this.translations = harbor.translations;
        this.departureTime = harbor.departureTime;
        this.lon = harbor.lon;
        this.lat = harbor.lat;
    }

    @action.bound
    setId(id) {
        this.id = id;
    }

    @action.bound
    setLoading(value) {
        this.loading = value;
    }

    @action.bound
    setCrewMembersLimit(value) {
        this.crewMembersLimit = value;
    }

    @action.bound
    setIsCrewMemberCorrectType(value) {
        this.isCrewMemberCorrectType = value;
    }

    @action
    setIsWelcomeSkipped(value) {
        this.isWelcomeSkipped = value;
    }

    @computed
    get isWelcomePageNeedsToBeShown() {
        const { locale } = this.rootStore.locale;

        if (this.isWelcomeSkipped) {
            return false;
        }

        return this.isTranslationAvailableForLocale(locale);
    }

    @action.bound
    setBookingPricingType(value) {
        this.bookingPricingType = value['pricingType'];
    }

    isTranslationAvailableForLocale(locale) {
        return this.translations.some((translation) => {
            let isRightTranslation = translation.name === 'notice';
            let isCorrectLocale = translation.locale.substr(0,2) === locale.substr(0, 2);
            return isRightTranslation && isCorrectLocale;
        });
    }

    @computed
    get welcomeTranslationBySelectedLocale() {
        const { locale } = this.rootStore.locale;

        return this.translations.filter((translation) => {
            let isRightTranslation = translation.name === 'notice';
            let isCorrectLocale = translation.locale.substr(0,2) === locale.substr(0, 2);
            return isRightTranslation && isCorrectLocale;
        })[0]['content'];
    }

    @computed
    get successBookingTranslationBySelectedLocale() {
        const { locale } = this.rootStore.locale;

        const translation = this.translations.filter((translation) => {
            let isRightTranslation = translation.name === 'general_info';
            let isCorrectLocale = translation.locale.substr(0,2) === locale.substr(0, 2);
            return isRightTranslation && isCorrectLocale;
        })[0];
        if (translation) {
            return translation['content'];
        } else {
            return null;
        }
    }
}

export default HarborStoreModel;
