import React, { Component } from 'react';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';

@translate()
@inject('store')
@observer
class AccessCodeReminder extends Component {

    render() {
        const { t, accessCode, store: { harbor, terminal } } = this.props;

        return (
            <div>
                {terminal.hasPrinter && (
                    <div className="payment-receipt-message red-text">
                        {t('COMMON.ACCESS_CODE_ON_RECEIPT')}
                    </div>
                )}

                {harbor.harbakioskSettings.pinShownOnScreen && (
                    <div className="payment-receipt-message">
                        <span><span className="red-text">{t('COMMON.YOUR_ACCESS_CODE')}: </span>{accessCode}</span>
                    </div>
                )}
            </div>
        );
    }
}


export default AccessCodeReminder;
