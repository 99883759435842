import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';

@inject('store')
@observer
class OutdoorPaymentForm extends Component {
    render() {
        const { locale } = this.props.store.locale;
        const { orderId, totalOutdoor } = this.props;

        return (
            <Fragment>
                <form className="hidden" target="_top" method="post" id="outdoorPaymentForm"
                      action="http://localhost/payment/doPayment.aspx"
                >
                    <input type="text" name="amount" defaultValue={totalOutdoor} />
                    <input type="text" name="boorefno" defaultValue={orderId} />
                    <input type="submit" value={''} />
                </form>

                <form id={'outdoorLocale'} action={`saveLocale://${locale}`} className="hidden">
                    <input type="submit" value={''} />
                </form>
            </Fragment>
        )
    }
}

OutdoorPaymentForm.propTypes = {
    totalOutdoor: PropTypes.number,
    orderId: PropTypes.string
};

export default OutdoorPaymentForm;
