class CraneBookingReceiptModel {
    build(rootStore, i18n) {
        const locale = rootStore.locale.locale;
        const currency = rootStore.harbor.currency;
        const harborName = rootStore.harbor.name;
        const amount = rootStore.craneBooking.form.price;
        const productText = this.getCorrectType(rootStore.craneBooking.form.type);
        const productPriceText = rootStore.craneBooking.form.price.toFixed(2) + ' ' + currency;
        const productCount = 1;
        const vat = this.getVat(rootStore);

        return {
            locale,
            alertOnLowPaperEnabled: false,
            total: amount.toFixed(2),
            currency,
            harborName,
            productText,
            productPriceText,
            productCount,
            vat
        }
    }

    getCorrectType(type) {
        switch (type) {
            case 0:
                return 'Op til 5 tons uden mast'
            case 1:
                return 'Op til 5 tons med mast'
            case 2:
                return '5-10 tons uden mast'
            case 3:
                return '5-10 tons med mast'
            default:
                return 'Error'
        }
    }

    buildTest() {
        const locale = 'da';

        return {
            locale,
            alertOnLowPaperEnabled: false,
            total: (120).toFixed(2),
            currency: 'DKK',
            harborName: 'PORT',
            productText: 'CRANE',
            productPriceText: '80 DKK',
            productCount: 1,
            vat: '24.00'
        }
    }

    getVat(rootStore) {
        return (rootStore.craneBooking.form.price - (rootStore.craneBooking.form.price / 1.25)).toFixed(2);
    }
}

export default CraneBookingReceiptModel;
