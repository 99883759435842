import moment from 'moment';

class CamperStickerModel {
    build(response, rootStore) {

        const { amount } = response;
        const locale = rootStore.locale.locale;
        const currency = rootStore.harbor.currency;
        const harborName = rootStore.harbor.name;
        const arrivalDay = moment(response['camperReservation'].dateFrom).format('YYYY-MM-DD');
        const departureDay = moment(response['camperReservation'].dateTo).format('YYYY-MM-DD');
        const stickerInitials = rootStore.harbor.harbakioskSettings.stickerInitials;

        return {
            locale,
            alertOnLowPaperEnabled: false,
            arrivalDay,
            departureDay,
            harborName,
            stickerInitials,
            total: amount.toFixed(2),
            currency
        }
    }
}

export default CamperStickerModel;
