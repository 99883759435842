import React, { Component } from 'react';
import FormValueDisplay from 'app/components/form/FormValueDisplay';
import PropTypes from 'prop-types';
import TextInputModal from 'app/components/form/modal/TextInputModal';
import { inject, observer } from 'mobx-react';
import FormValidator from 'store/validators/FormValidator';

@inject('store')
@observer
class TextFormInput extends Component {
    constructor() {
        super();
        this.state = { flash: false, open: false };
    }

    triggerOpen() {
        this.setState({ open: true }, () => this.setState({ open: false }));
    }

    displayFlash() {
        if (!this.props.flashDisabled) {
            this.setState({ flash: true }, () => this.setState({ flash: false }));
        }
    }

    isValueValid(value) {
        const { isEmail, isPhone, isNumber, isInvalid } = this.props;
        const validator = new FormValidator();

        if (isInvalid) {return false}
        if (isEmail) {return validator.isEmailValidForDisplay(value)}
        if (isPhone) {return validator.isPhoneValidForDisplay(value)}
        if (isNumber) {return validator.isNumberValidForDisplay(value)}

        return true
    }

    isEmpty() {
        const { isPhone, moduleStore, formFieldName } = this.props;
        const { phoneEmpty, empty } = new FormValidator();
        const value = moduleStore.form[formFieldName];

        return isPhone ? phoneEmpty(value) : empty(value);
    }

    render() {
        const { setTitle, changeTitle, isChangeButtonDisabled, moduleStore, formFieldName, isRequired } = this.props;
        const value = this.props.value ? this.props.value : moduleStore.form[formFieldName];
        const isValid = this.isValueValid(value);

        return (
            <div>
                <div className="form-input"  onClick={() => this.triggerOpen()}>
                    <FormValueDisplay {...this.props} flash={this.state.flash} />

                    <button type="button"
                            className={`modal-form-btn ${isRequired && (!value || !isValid) ? 'red-border' : ''}`}
                            disabled={isChangeButtonDisabled}>
                        { this.isEmpty() ? setTitle : changeTitle}
                    </button>
                </div>

                <TextInputModal {...this.props} triggerOpen={this.state.open} onClose={() => this.displayFlash()} />
            </div>
        )
    }
}

TextFormInput.propTypes = {
    isRequired: PropTypes.bool.isRequired,
    moduleStore: PropTypes.any.isRequired,
    formFieldName: PropTypes.string.isRequired,
    value: PropTypes.any,
    setTitle: PropTypes.any.isRequired,
    changeTitle: PropTypes.any.isRequired,
    title: PropTypes.any.isRequired,
    hideOptionality: PropTypes.bool,
    flashDisabled: PropTypes.any,
    isPhone: PropTypes.any,
    isEmail: PropTypes.any,
    isNumber: PropTypes.any,
    isAlwaysUppercase: PropTypes.any,
    valueAddon: PropTypes.any,
    onSave: PropTypes.any,
    isChangeButtonDisabled: PropTypes.bool,
    isInvalid: PropTypes.bool,
    warningIcon: PropTypes.bool,
    redInputBorder: PropTypes.bool,
    actCodeWarning: PropTypes.bool,
};

export default TextFormInput;
