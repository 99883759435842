import React, { Component } from 'react';
import FormOverview from '../../../components/form/overview/FormOverview';
import FormTextOverview from '../../../components/form/overview/FormTextOverview';
import FormPriceOverview from '../../../components/form/overview/FormPriceOverview';
import { translate } from '../../../../i18n/i18n';
import { inject, observer } from 'mobx-react';

@translate()
@inject('store')
@observer
class CraneBookingOverviewTab extends Component {
    getCorrectBookingTypeTrans (type) {
        switch (type) {
            case 0:
                return 'Op til 5 tons uden mast'
            case 1:
                return 'Op til 5 tons med mast'
            case 2:
                return '5-10 tons uden mast'
            case 3:
                return '5-10 tons med mast'
            default:
                return 'Error'
        }
    }
    render() {
        const { t, store: { craneBooking } } = this.props;
        const { form } = craneBooking;
        const { type, price } = form;

        return (
            <div className="tab-container">
                <FormOverview>
                    <FormTextOverview label={t('COMMON.TYPE')} value={this.getCorrectBookingTypeTrans(type)} />
                </FormOverview>

                <FormPriceOverview total={Number(price)} noDivider />
            </div>
        )
    }
}

export default CraneBookingOverviewTab;
