import { observable } from 'mobx';
import moment from 'moment';

class BookingPaymentViewModel {
    @observable id;
    @observable dateFrom;
    @observable dateTo;
    @observable dock;
    @observable berth;
    @observable email;
    @observable phone;

    constructor(booking, locale) {
        this.id = booking.id;
        this.dateFrom = this.formatDate(moment(booking.dateFrom), locale);
        this.dateTo = this.formatDate(moment(booking.dateTo), locale);
        this.dock = booking.bridge;
        this.berth = booking.position;
        this.email = booking.email;
        this.phone = booking.phone ? booking.phone : '+';
    }

    formatDate(date, locale) {
        switch (locale) {
            case 'da':
                return date.format('DD.MM.YYYY');
            default:
                return date.format('YYYY-MM-DD');
        }
    }
}

export default BookingPaymentViewModel;
