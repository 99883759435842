import React, { Component, Fragment } from 'react';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import InputContainer from 'app/components/form/inputs/utils/InputContainer';
import TextFormInput from 'app/components/form/inputs/TextFormInput';
import CountryFormInput from 'app/components/form/inputs/CountryFormInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

@translate()
@inject('store')
@observer
class BookingBoatInfoTab extends Component {
    componentDidMount() {
        this.getBookingPrice();
    }

    getBookingPrice() {
        const { booking } = this.props.store;
        const { boatLength, dock, priceLoading } = this.props.store.booking.form;

        if (boatLength && dock && !priceLoading) {
            booking.getBookingPrice();
        }
    }

    render() {
        const { t, store: { booking, harbor: { bookingPricingType } } } = this.props;

        return (
            <Fragment>
                <div className="tab-container">
                    <InputContainer>
                        <TextFormInput
                            isRequired isNumber onSave={() => this.getBookingPrice()}
                            isChangeButtonDisabled={booking.form.priceLoading}
                            isInvalid={booking.form.boatTooBig}
                            valueAddon="m."
                            moduleStore={booking}
                            formFieldName={'boatLength'}
                            setTitle={t(bookingPricingType === 'by_boat_width' ?
                                'COMMON.BOAT_WIDTH_MESSAGE' : 'COMMON.BOAT_LENGTH_MESSAGE')}
                            changeTitle={t(bookingPricingType === 'by_boat_width' ?
                                'COMMON.CHANGE_BOAT_WIDTH' : 'COMMON.CHANGE_BOAT_LENGTH')}
                            title={t(bookingPricingType === 'by_boat_width' ?
                                'COMMON.BOAT_WIDTH_MESSAGE' : 'COMMON.BOAT_LENGTH_MESSAGE') + ':'} />

                        <CountryFormInput
                            isRequired isValid preselectPhone
                            moduleStore={booking} formFieldName={'boatCountry'}
                            setTitle={t('COMMON.CHOOSE_BOAT_COUNTRY')}
                            changeTitle={t('COMMON.CHANGE_BOAT_COUNTRY')}
                            title={t('COMMON.BOAT_COUNTRY_LABEL') + ':'}
                        />

                        <TextFormInput
                            isRequired
                            moduleStore={booking}
                            formFieldName={'boatName'}
                            setTitle={t('COMMON.ENTER_BOAT_NAME')}
                            changeTitle={t('COMMON.CHANGE_BOAT_NAME')}
                            title={t('COMMON.BOAT_NAME_LABEL') + ':'} />
                    </InputContainer>
                </div>
                {booking.form.boatTooBig &&
                <div className="boat-too-big">
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    <span>{t('COMMON.BOAT_TOO_BIG')}</span>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                </div>
                }
            </Fragment>
        )
    }
}

export default BookingBoatInfoTab;
