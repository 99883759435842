import Api from 'api/api';

class TerminalApi {
    startPayment(verifonePayment) {
        return Api.postLocal('/verifone/start_payment', verifonePayment);
    }

    getTerminalStatus() {
        return Api.getLocal('/verifone/terminal_status');
    }

    getExitCode() {
        return Api.getLocal('/verifone/exit_code');
    }

    abortPayment() {
        return Api.getLocal('/verifone/abort_payment');
    }

    startReconciliation() {
        return Api.getLocal('/verifone/start_reconciliation');
    }

    getLocalAddress() {
        return Api.getLocal('/network/address');
    }

    shutdown() {
        return Api.postLocal('/application/shutdown');
    }
}

export default new TerminalApi();
