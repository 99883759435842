import React, { Component, Fragment } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import PageNotFound from 'app/layout/components/PageNotFound';
import Dashboard from 'app/dashboard/Dashboard';
import LayoutHarborAuth from 'app/layout/components/LayoutHarborAuth';

@withRouter
class Layout extends Component {
    render() {
        const { location: { pathname } } = this.props;
        const { REACT_APP_DEFAULT_HARBOR, REACT_APP_ENV } = process.env;
        const env = REACT_APP_ENV;

        // Inserts default harbor into params if it is not provided in DEV environment
        const redirectToDefault = (env === 'development' || env === 'staging' || env === 'demo')
            && pathname === '/' && REACT_APP_DEFAULT_HARBOR;

        return (
            <Fragment>
                { redirectToDefault ?  <Redirect to={'/' + REACT_APP_DEFAULT_HARBOR} /> : null }
                <Switch>
                    <Route path="/:harborId"
                        render={(props) =>
                            <LayoutHarborAuth {...props} >
                                {/* If harbor is found render application's Dashboard */}
                                <Dashboard />
                            </LayoutHarborAuth>
                        } />
                    <Route exact path="/" component={PageNotFound} />
                </Switch>
            </Fragment>
        );
    }
}

export default Layout;
