import React, { Component } from 'react';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import CountryFormInput from 'app/components/form/inputs/CountryFormInput';
import InputContainer from 'app/components/form/inputs/utils/InputContainer';
import TextFormInput from 'app/components/form/inputs/TextFormInput';

@translate()
@inject('store')
@observer
class CamperInfoTab extends Component {
    render() {
        const { t, store: { camper } } = this.props;
        const { isNationalityHidden } = camper.hiddenFields;

        return (
            <div className="tab-container">
                <InputContainer>
                    <CountryFormInput
                        isRequired isValid preselectPhone
                        hidden={isNationalityHidden}
                        moduleStore={camper} formFieldName={'country'}
                        setTitle={t('COMMON.CHOOSE_NATIONALITY')}
                        changeTitle={t('COMMON.CHANGE_NATIONALITY')}
                        title={t('COMMON.NATIONALITY') + ':'}
                    />

                    <TextFormInput
                        isRequired isAlwaysUppercase
                        moduleStore={camper}
                        formFieldName={'plateNumber'}
                        setTitle={t('COMMON.ENTER_PLATE')}
                        changeTitle={t('COMMON.CHANGE_PLATE')}
                        title={t('COMMON.CAMPER_PLATE') + ':'} />
                </InputContainer>
            </div>
        )
    }
}

export default CamperInfoTab;
