import Api from 'api/api';

class SlipwayApi {
    getSettings(id) {
        return Api.get(`/public/slipway/settings/${id}`);
    }

    postReservation(body, id) {
        return Api.post(`/public/slipway/reservation/${id}`, body);
    }

    getSlipwayPaymentByOrderId(orderId: string) {
        return Api.get(`/public/slipway/reservation_payment/${orderId}`);
    }

    markReservationAsPaid(orderId: string, gateway, fee = 0) {
        return Api.patch(`/public/slipway/verify_order/${orderId}?gateway=${gateway}&fee=${fee}`);
    }
}

export default new SlipwayApi();
