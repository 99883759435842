import React from 'react';
import 'flag-icon-css/css/flag-icon.min.css';
import Layout from 'app/layout/Layout';
import 'weather-icons/css/weather-icons.min.css'
import 'scss/global.scss';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import * as Sentry from '@sentry/react';
// Font Awesome icon register:
library.add(faExclamationTriangle);

if (typeof localStorage !== 'undefined') {
    Sentry.init({
        dsn: 'https://d0bf379d37254575968814f8bc874f91@o64878.ingest.us.sentry.io/137722',
        defaultIntegrations: false
    });
}

class App extends React.Component {
    render() {
        return (
            <Layout/>
        );
    }
}

export default App;
