import { action } from 'mobx';
import LocaleStore from 'store/LocaleStore';
import HarborStore from 'store/HarborStore';
import PaymentStore from 'store/PaymentStore/PaymentStore';
import TerminalStore from 'store/TerminalStore/TerminalStore';
import ExitStore from 'store/ExitStore/ExitStore';
import TestAreaStore from 'store/TestAreaStore/TestAreaStore';
import ShopStore from 'store/ShopStore/ShopStore';
import SlipwayStore from 'store/modules/SlipwayStore/SlipwayStore';
import CamperStore from 'store/modules/CamperStore/CamperStore';
import CreditStore from 'store/modules/CreditStore/CreditStore';
import BookingStore from 'store/modules/BookingStore/BookingStore';
import ScreenSaverStore from 'store/ScreenSaverStore/ScreenSaverStore';
import CraneBookingStore from './modules/CraneBookingStore/CraneBookingStore';
import ShopHackStore from './modules/ShopHackStore/ShopHackStore';

class RootStore {
    constructor() {
        // Utility stores
        this.harbor = new HarborStore(this);
        this.terminal = new TerminalStore(this);
        this.locale = new LocaleStore(this);
        this.exit = new ExitStore(this);
        this.testArea = new TestAreaStore(this);
        this.screensaver = new ScreenSaverStore(this);

        // Module stores
        this.booking = new BookingStore(this);
        this.credit = new CreditStore(this);
        this.camper = new CamperStore(this);
        this.slipway = new SlipwayStore(this);
        this.shop = new ShopStore(this);
        this.payment = new PaymentStore(this);
        this.craneBooking = new CraneBookingStore(this);
        this.shopHack = new ShopHackStore(this);
    }

    @action
    refreshStore() {
        window.location.href = `/${this.harbor.id}`;
    }
}

export default new RootStore();
