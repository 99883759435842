import axios from 'axios';

class WeatherApi {
    getWeather(lon, lat) {
        const q = axios.get(`https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=c3d0a9e52efdb424487d939d3d45ffce&units=metric`);
        q.catch(err => this.handle401(err));
        return q;
    }
}

export default new WeatherApi();
