import React, { Component } from 'react';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Loader from 'app/components/Loader';
import PaymentSuccessTitle from 'app/modules/payment/components/PaymentSuccessTitle';
import PaymentSuccessNavBar from 'app/modules/payment/components/PaymentSuccessNavBar';
import PaymentReceiptCodeReminder from 'app/modules/payment/components/PaymentReceiptCodeReminder';
import AccessCodeReminder from '../../components/AccessCodeReminder';

@translate()
@withRouter
@inject('store')
@observer
class CreditPaymentSuccess extends Component {
    async componentDidMount() {
        const { payment: { credit } } = this.props.store;
        const { params: { orderId } } = this.props.match;

        await credit.fetchPrinterStatus();
        credit.finalizePayment(orderId);
    }

    render() {
        const { t, store: { payment: { credit }, harbor: { currency } } } = this.props;

        const creditRoot = credit.getCreditRoot();
        const creditToUse = creditRoot.rootStore.credit.creditFromApi;

        if (!creditToUse) {
            return <Loader fullScreen={true} />;
        }

        return (
            <div className="payment-block">
                <PaymentSuccessTitle />

                <div className="credit-review-box">
                    <div className="payment-amount-text">
                        {`${creditToUse.amount} ${currency} `}
                        <span className="payment-amount-subtext">{t('COMMON.CREDIT_ADDED')}</span>
                    </div>
                </div>

                <PaymentReceiptCodeReminder model={creditToUse} />
                {credit.hasAccessCode && (
                    <AccessCodeReminder accessCode={credit.accessCode} />
                )}
                <PaymentSuccessNavBar />

            </div>
        )
    }
}

export default CreditPaymentSuccess;
