import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import 'scss/modules/shop.scss'
import ShopNav from 'app/modules/shop/components/ShopNav';
import ShopServiceStep from 'app/modules/shop/steps/ShopServiceStep';
import ShopOverviewStep from 'app/modules/shop/steps/ShopOverviewStep';

@withRouter
@inject('store')
@observer
class Shop extends Component {
    componentDidMount() {
    }

    render() {
        const { location: { pathname } } = this.props;

        return (
            <Fragment>
                <div className="shop-container">
                    <Switch>
                        <Route exact path="/:harborId/shop" render={() => (<Redirect to={pathname + '/1'} />)} />
                        <Route path="/:harborId/shop/1" render={() => <ShopServiceStep />} />
                        <Route path="/:harborId/shop/2" render={() => <ShopOverviewStep />} />
                        <Route render={() => (<Redirect to="/" />)} />
                    </Switch>
                    <ShopNav />
                </div>
            </Fragment>
        );
    }
}

export default Shop;
