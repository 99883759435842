import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'i18n/i18n';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormValidator from 'store/validators/FormValidator';
import { inject, observer } from 'mobx-react';

@translate()
@inject('store')
@observer
class FormValueDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = { isValueNew: false };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.flashDisabled) {
            if (prevProps.value !== this.props.value) {
                this.flash();
            }

            if (prevProps.flash !== this.props.flash) {
                this.flash();
            }
        }
    }

    flash() {
        this.setState({ isValueNew: true }, () => {
            setTimeout(() => {
                this.setState({ isValueNew: false });
            })
        });
    }

    isValueValid(value) {
        const { isEmail, isPhone, isNumber, isInvalid } = this.props;
        const validator = new FormValidator();

        if (isInvalid) {return false}
        if (isEmail) {return validator.isEmailValidForDisplay(value)}
        if (isPhone) {return validator.isPhoneValidForDisplay(value)}
        if (isNumber) {return validator.isNumberValidForDisplay(value)}

        return true
    }

    isDisplayVisible() {
        const { isPhone, moduleStore, formFieldName } = this.props;
        const value = moduleStore.form[formFieldName];

        if (isPhone) {
            if ((value && value === '+') || moduleStore.phonePreselected) {
                return false;
            }
        }

        return !!value;
    }

    stringValueDisplay() {
        const { isNumber, moduleStore, formFieldName, valueAddon } = this.props;
        let value = this.props.value ? this.props.value : moduleStore.form[formFieldName];

        if (isNumber) {
            value = Number(value).toFixed(2);
        }

        return (
            <div className="choice-info-name">{ value } { valueAddon ? valueAddon : null}</div>
        )
    }

    render() {
        const { t, isRequired, title, hideOptionality, isCountry, moduleStore, formFieldName, setTitle, warningIcon } = this.props;
        const { isValueNew } = this.state;

        const value = this.props.value ? this.props.value : moduleStore.form[formFieldName];

        const isValid = this.isValueValid(value);

        return (
            <Fragment>
                {this.isDisplayVisible() ?
                    <div className="choice-info">
                        <div className="choice-info-value">
                            <div>
                                {title}
                            </div>
                            <div className={`choice-value ${isValueNew && 'flash'}`}>
                                {!isValid ?
                                    <span className="form-value-invalid">
                                                {this.stringValueDisplay()}
                                        <FontAwesomeIcon icon={faTimes} className="form-value-times" />
                                    </span>
                                    :
                                    <Fragment>
                                        {isCountry ?
                                            countryFlagValueDisplay(value)
                                            :
                                            this.stringValueDisplay()
                                        }
                                        <FontAwesomeIcon icon={faCheck} className="form-value-check" />
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </div>
                    :
                    <Fragment>
                        <span className="form-label-text">
                            {warningIcon && isRequired && (
                                <span className='fa-warning mr-1'>
                                    <FontAwesomeIcon icon='exclamation-triangle' />
                                </span>
                            )}
                            {setTitle}:
                        </span>
                        {!hideOptionality &&
                            (isRequired ?
                                <span className="form-value-required">
                                    ({t('COMMON.REQUIRED').toUpperCase()})
                                </span>
                                    :
                                <span className="form-value-optional">
                                    ({t('COMMON.OPTIONAL').toUpperCase()})
                                </span>
                            )
                        }
                    </Fragment>
                }
            </Fragment>
        )
    }
}

const countryFlagValueDisplay = (country) => {
    return (
        <div className="form-flag">
            <span className={`form-value-flag flag-icon flag-icon-${country.toLowerCase()}`}> </span>
        </div>
    )
}

FormValueDisplay.propTypes = {
    isRequired: PropTypes.bool.isRequired,
    value: PropTypes.any,
    title: PropTypes.string.isRequired,
    hideOptionality: PropTypes.bool,
    isCountry: PropTypes.bool,
    isPhone: PropTypes.any,
    isEmail: PropTypes.any,
    isNumber: PropTypes.any,
    flashDisabled: PropTypes.bool,
    flash: PropTypes.any,
    valueAddon: PropTypes.any,
    isInvalid: PropTypes.bool,
};

export default FormValueDisplay;
