import React, { Component } from 'react';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import LocationFormInput from 'app/components/form/inputs/LocationFormInput';
import InputContainer from 'app/components/form/inputs/utils/InputContainer';
import Loader from 'app/components/Loader';

@translate()
@inject('store')
@observer
class BookingLocationTab extends Component {
    render() {
        const { t, store: { booking } } = this.props;
        const { form, docks, berths } = booking;
        const { dock, berth, berthsLoading } = form;

        const loadBerths = () => {
            setTimeout(() => {
                booking.requestAvailableBerths((value) => booking.setFormValue('berthsLoading', value));
            })
        }

        return (
            <div className="tab-container">
                <InputContainer>
                    {docks &&
                        <LocationFormInput
                            isRequired isValid={dock} onChange={loadBerths}
                            moduleStore={booking} formFieldName={'dock'} options={docks}
                            value={dock ? dock.title : null}
                            setTitle={t('COMMON.FIND_DOCK')}
                            changeTitle={t('COMMON.CHANGE_DOCK')}
                            title={t('COMMON.CHOSEN_DOCK')}
                        />
                    }
                    {berths ?
                        <LocationFormInput
                            isRequired isValid={berth}
                            moduleStore={booking} formFieldName={'berth'} options={berths}
                            value={berth ? berth.title : null}
                            setTitle={t('COMMON.FIND_BERTH')}
                            changeTitle={t('COMMON.CHANGE_BERTH')}
                            title={t('COMMON.CHOSEN_BERTH')}
                        />
                        :
                        <div className="text-center">
                            {berthsLoading && <Loader /> }
                        </div>
                    }
                </InputContainer>
            </div>
        );
    }
}


export default BookingLocationTab;
