import React, { Component, Fragment } from 'react';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';

@translate()
@inject('store')
@observer
class ShopOverviewStep extends Component {
    render() {
        const { currency } = this.props.store.harbor;
        const { t, store: { shop } } = this.props;

        const { servicesArr, servicesEnabled, facilitiesAmount, peopleNumber, durationNumber, total } = shop;

        return (
            <Fragment>
                <div className="services-enabled-box">
                    {servicesEnabled.tent &&
                        <Fragment>
                            <div className="services-enabled">
                                {peopleNumber === 1 ? t('COMMON.PERSON'): t('COMMON.PEOPLE')}: {peopleNumber}
                            </div>
                            <div className="services-enabled">
                                {durationNumber === 1 ? t('COMMON.NIGHT'): t('COMMON.NIGHTS')}: {durationNumber}
                            </div>
                        </Fragment>
                    }
                </div>
                <div className="price-container">
                    <div className="price-review-title" style={{ marginTop: '20px' }}>
                        {t('COMMON.PRICE')}
                    </div>

                    <div id={'price'} className="price-container-box">
                        <Fragment>
                            {servicesArr.map((service, i) => {
                                if (i < 1 && servicesEnabled[service]) {
                                    return (
                                        <PriceDisplay title={t('COMMON.' + service.toUpperCase())}
                                                      key={i}
                                                      amount={shop.pricePerNightPerPerson}
                                                      currency={currency} />
                                    )
                                }
                                return null;
                            })}

                            {servicesArr.map((service, i) => {
                                if (i >= 1 && (facilitiesAmount[service] > 0)) {
                                    return (
                                        <PriceDisplay title={t('COMMON.' + service.toUpperCase())
                                        + ' x ' + facilitiesAmount[service]}
                                                      key={i}
                                                      amount={shop.priceForUtility(service)}
                                                      currency={currency} />
                                    )
                                }
                                return null;
                            })}

                            <TotalDisplay title={t('COMMON.TOTAL')} amount={total} currency={currency} />
                        </Fragment>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const PriceDisplay = ({ title, amount, currency, isNegative }) => {
    return ((amount && amount !== 0) ?
            <div className="overview-price-box">
                <div className="overview-subprice-title">{title}</div>
                <div className="overview-subprice">
                    {isNegative && <span style={{ marginRight: '3px' }}>–</span>}
                    {amount.toFixed(2)} {currency}
                </div>
            </div>
            :
            null
    )
};

const TotalDisplay = ({ title, amount, currency }) => {
    return ((amount && amount !== 0) ?
            <div className="overview-total-box">
                <div className="overview-price-title">{title}:</div>
                <div className="overview-total-price">{amount.toFixed(2)} {currency}</div>
            </div>
            :
            null
    )
};

export default ShopOverviewStep;
