import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Loader from 'app/components/Loader';
import FormTabRouter from 'app/components/form/FormTabRouter';
import CamperDurationTab from 'app/modules/camper/tabs/CamperDurationTab';
import CamperLocationTab from 'app/modules/camper/tabs/CamperLocationTab';
import CamperInfoTab from 'app/modules/camper/tabs/CamperInfoTab';
import CamperNav from 'app/modules/camper/CamperNav';
import CamperContactTab from 'app/modules/camper/tabs/CamperContactTab';
import CamperOverviewTab from 'app/modules/camper/tabs/CamperOverviewTab';

@inject('store')
@observer
class Camper extends Component {
    componentDidMount() {
        this.props.store.camper.getInitialCamperSettings();
    }

    buildFormTabs() {
        const { camper } = this.props.store;

        let tabs = [];
        let tabNames = [];

        tabs.push(<CamperDurationTab />);
        tabNames.push('COMMON.STEPS_DURATION');

        if (!camper.isOneLot) {
            tabs.push(<CamperLocationTab />);
            tabNames.push('COMMON.STEPS_LOCATION');
        }

        tabs.push(<CamperInfoTab />);
        tabNames.push('COMMON.INFO_TITLE');

        tabs.push(<CamperContactTab />);
        tabNames.push('COMMON.STEPS_CONTACT_INFORMATION');

        tabs.push(<CamperOverviewTab />);
        return { tabs, tabNames };
    }

    render() {
        const { store: { camper: { loading, price } } } = this.props;

        if (loading || !price) {
            return <Loader fullScreen={true} />
        }

        const { tabs, tabNames } = this.buildFormTabs();

        return (
            <div className="module-container">
                <FormTabRouter tabs={tabs} module={'camper'} />
                <CamperNav tabTitles={tabNames} />
            </div>
        )
    }
}

export default Camper;
