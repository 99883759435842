import React, { Component } from 'react';
import FormOptionList from 'app/components/form/FormOptionList';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import TextFormInput from '../../../components/form/inputs/TextFormInput';

@translate()
@inject('store')
@observer
class CreditPriceTab extends Component {
    render() {
        const { t, store: { credit, harbor: { currency } } } = this.props;

        // const { prices, settings } = this.props.store.credit;
        const { prices } = this.props.store.credit;
        return (
            <div className="tab-container">
                <div className="credit-title">{t('COMMON.PRICE_LIST')}</div>
                {/* {settings && settings.defaultPayType && settings.defaultPayType === 'prepaid' && (*/}
                {/*    <div>*/}
                {/*        <span className="red-text">*</span> {t('COMMON.ACT_DEFAULT_PAY_TYPE_WARNING')}*/}
                {/*    </div>*/}
                {/* )}*/}
                <div className='credit-info-text'>
                    <span className='red-text'>*</span> {t('COMMON.INFO_TEXT_CREDIT')}
                </div>
                <FormOptionList options={prices} currency={currency} />
                <TextFormInput
                    isRequired isNumber
                    valueAddon={currency}
                    moduleStore={credit}
                    formFieldName={'price'}
                    setTitle={t('COMMON.ENTER_TOPUP_LABEL')}
                    changeTitle={t('COMMON.CHANGE_AMOUNT')}
                    title={t('COMMON.TOP_UP_AMOUNT') + ':'} />
            </div>
        );
    }
}

export default CreditPriceTab;
