import React, { Component } from 'react';
import PropTypes from 'prop-types'
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { inject, observer } from 'mobx-react';

Modal.setAppElement('#root');

@inject('store')
@observer
class LocationModal extends Component {
    constructor() {
        super();
        this.state = { open: false, page: 1 }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.triggerOpen && (prevProps.value !== this.props.triggerOpen)) {
            this.setState({ open: true });
        }
    }

    setModalPage(newPage) {
        this.setState({ page: newPage });
    }

    closeModal() {
        this.setState({ open: false });
    }

    paginator() {
        const items = this.props.options;
        const { page } = this.state;

        let perPage = 24,
            offset = (page - 1) * perPage,

            paginatedItems = items.slice(offset).slice(0, perPage),
            total_pages = Math.ceil(items.length / perPage);

        return {
            page: page,
            perPage: perPage,
            prePage: page - 1 ? page - 1 : null,
            nextPage: (total_pages > page) ? page + 1 : null,
            total: items.length,
            total_pages: total_pages,
            data: paginatedItems
        };
    }

    render() {
        const { moduleStore, formFieldName, closeOnOutClick, onChange } = this.props;

        const { open, page } = this.state

        const value = moduleStore.form[formFieldName];

        const pageDown = () => () => this.setModalPage(page - 1);

        const pageUp = () => () => this.setModalPage(page + 1);

        const select = (value) => () => {
            if (onChange) {
                onChange();
            }

            moduleStore.setFormValue(formFieldName, value);
            this.closeModal();
        };

        const close = () => () => this.closeModal();

        const paginator = this.paginator();

        return (
            <Modal isOpen={open} onRequestClose={close()} shouldCloseOnOverlayClick={closeOnOutClick}
                   className="form-modal">
                <div className="modal-container">
                    <div className="modal-arrow-container">
                        {paginator.page !== 1 &&
                            <FontAwesomeIcon icon={faChevronLeft} className="modal-arrow" onClick={pageDown()} />
                        }
                    </div>
                    {paginator.data ?
                        <div className="modal-option-container">
                            {paginator.data.map((option, i) => {
                                return (
                                    <button onClick={select(option)}
                                            className={`modal-option 
                                            ${value && value.id === option.id && 'active-option'}`}
                                            key={i}>
                                        {option.title ? option.title : option.name}
                                    </button>
                                )
                            })}
                        </div>
                        :
                        null
                    }
                    <div className="modal-arrow-container">
                        {paginator.nextPage &&
                            <FontAwesomeIcon icon={faChevronRight} className="modal-arrow" onClick={pageUp()} />
                        }
                    </div>
                </div>
            </Modal>
        )
    }
}

export default LocationModal;

LocationModal.propTypes = {
    moduleStore: PropTypes.any.isRequired,
    formFieldName: PropTypes.string.isRequired,
    options: PropTypes.any.isRequired,
    closeOnOutClick: PropTypes.any,
    onChange: PropTypes.any
};
