import moment from 'moment';

class BookingReceiptModel {
    build(response, rootStore, i18n) {
        const prices = JSON.parse(response['legacyPrices']);
        const { total, facilityPrice, environmentalFee, freeharborDiscount, stayPrice, localTax } = prices;
        // HACK: Fix me, kill me
        const totalVatAmount = response.harborVatAmount;
        const lengthFrom = Number(prices['priceTier']['lengthFrom']);
        const lengthTo = Number(prices['priceTier']['lengthTo']);
        const locale = rootStore.locale.locale;
        const currency = rootStore.harbor.currency;
        const harborName = rootStore.harbor.name;
        const code = this.generateCodeJsonString(response, i18n);
        const { accessCode } = response;

        let dateFrom;
        let dateTo;

        switch (locale) {
            case 'da':
                dateFrom = moment(response.dateFrom).format('DD.MM.YYYY');
                dateTo =  moment(response.dateTo).format('DD.MM.YYYY');
                break;
            default:
                dateFrom = moment(response.dateFrom).format('YYYY-MM-DD');
                dateTo =  moment(response.dateTo).format('YYYY-MM-DD');
                break;
        }

        return {
            locale,
            alertOnLowPaperEnabled: false,
            boatLengthFrom: lengthFrom,
            boatLengthTo: lengthTo,
            dateFrom,
            dateTo,
            stayPrice: stayPrice.toFixed(2),
            total: total.toFixed(2),
            vat: totalVatAmount.toFixed(2),
            currency,
            harborName,
            servicePrice: facilityPrice.toFixed(2),
            environmentalFee: environmentalFee.toFixed(2),
            freeharborDiscount: freeharborDiscount.toFixed(2),
            localTax: localTax.toFixed(2),
            code: code !== '' ? code : null,
            accessCode
        }
    }

    buildTest() {
        const locale = 'da';
        const boatLengthFrom = 10;
        const boatLengthTo = 15;
        let dateFrom = moment().format('DD.MM.YYYY');
        let dateTo = moment().add(1,'days').format('DD.MM.YYYY');

        return {
            locale,
            alertOnLowPaperEnabled: false,
            boatLengthFrom,
            boatLengthTo,
            dateFrom,
            dateTo,
            accessCode: '5555',
            stayPrice: '15.99',
            total: '15.99',
            vat: '2.00',
            currency: 'DKK',
            harborName: 'PORT',
            servicePrice: '0.00',
            environmentalFee: '0.00',
            freeharborDiscount: '0.00',
            localTax: '0.00',
            code: 'Wifi: 12345678'
        }
    }

    generateCodeJsonString(response, i18n) {
        let code = '';

        response.utilities.forEach((utility) => {
            if (utility.utility.code) {
                code +=
                    i18n.t('COMMON.' + utility.utility.utility.name.toUpperCase()) + ': ' + utility.utility.code + '\n';
            }
        });

        return code;
    }
}

export default BookingReceiptModel;
