import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react';
import { translate } from 'i18n/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { inject, observer } from 'mobx-react';

@translate()
@inject('store')
@observer
class FormOptionList extends Component {
    addCredits(price) {
        const creditAmount = this.props.store.credit.form.price;
        this.props.store.credit.setFormValue('price', (Number(creditAmount) + Number(price)).toString());
    }

    deductCredits(price) {
        const creditAmount = this.props.store.credit.form.price;
        if (creditAmount - price <= 0) {
            this.props.store.credit.setFormValue('price',null);
        } else {
            this.props.store.credit.setFormValue('price', (Number(creditAmount) - Number(price)).toString());
        }
    }

    render() {
        const { options, currency } = this.props;
        return (
            <Fragment>
                <div className="container" style={{ minHeight: '35vh' }}>
                    {options ?
                        <div className="row">
                            {options.map((option, i)=>(
                                <div key={i} className='col-lg-4' >
                                <div className="btn btn-light shop-utility service-price">
                                    <div className="utility-block" >
                                        <div>
                                            <button
                                                className="duration-count-borderless
                                                    duration-btn-minus btn btn-light no-margin"
                                                type="button"
                                                disabled={
                                                        this.props.store.credit.form.price < 0 ||
                                                        !this.props.store.credit.form.price
                                                    }
                                                onClick={() => this.deductCredits(option.price)}>
                                                <FontAwesomeIcon icon={faMinus} />
                                            </button>
                                        </div>
                                        <div>
                                             <div className="utility-title">
                                                 <b>{option.name}</b>
                                             </div>
                                             <div className="utility-price">
                                                {option.price + ' ' + currency}
                                             </div>
                                        </div>
                                        <div>
                                            <button className="duration-count-borderless
                                                        duration-btn-plus btn btn-light no-margin"
                                                    type="button"
                                                    onClick={() => this.addCredits(option.price)}>
                                                <FontAwesomeIcon icon={faPlus} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            ))}
                        </div>
                        :
                        null
                    }
                </div>
            </Fragment>
        );
    }
}

FormOptionList.propTypes = {
    options: PropTypes.any,
    currency: PropTypes.any,
};

export default FormOptionList;
