import React, { Component } from 'react';
import FormOverview from 'app/components/form/overview/FormOverview';
import FormTextOverview from 'app/components/form/overview/FormTextOverview';
import FormLocationOverview from 'app/components/form/overview/FormLocationOverview';
import FormCountryOverview from 'app/components/form/overview/FormCountryOverview';
import { inject, observer } from 'mobx-react';
import { translate } from 'i18n/i18n';
import FormPriceOverview from 'app/components/form/overview/FormPriceOverview';

@translate()
@inject('store')
@observer
class SlipwayOverviewTab extends Component {
    render() {
        const { t, store: { slipway } } = this.props;
        const { form, hiddenFields } = slipway;
        const { isNationalityHidden, isFirstNameHidden, isLastNameHidden, isEmailHidden } = hiddenFields;
        const { camperLot, country, firstName, lastName, email, phone } = form;

        return (
            <div className="tab-container">
                <FormOverview>
                    <FormLocationOverview label={t('COMMON.SLIPWAY')} value={camperLot && camperLot.name} />
                    {!isNationalityHidden && <FormCountryOverview label={t('COMMON.NATIONALITY')} value={country} /> }
                    {!isFirstNameHidden && <FormTextOverview label={t('COMMON.FIRST_NAME')} value={firstName} />}
                    {!isLastNameHidden && <FormTextOverview label={t('COMMON.LAST_NAME')} value={lastName} />}
                    {!isEmailHidden && <FormTextOverview label={t('COMMON.EMAIL')} value={email} />}
                    <FormTextOverview label={t('COMMON.PHONE')} value={phone} />
                </FormOverview>

                <FormPriceOverview total={slipway.total} noDivider />
            </div>
        );
    }
}

export default SlipwayOverviewTab;
