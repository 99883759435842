import React, { Component } from 'react';
import 'scss/modules/slipway.scss';
import Loader from 'app/components/Loader';
import { inject, observer } from 'mobx-react';
import SlipwayNav from 'app/modules/slipway/SlipwayNav';
import FormTabRouter from 'app/components/form/FormTabRouter';
import SlipwayInfoTab from 'app/modules/slipway/tabs/SlipwayInfoTab';
import SlipwayContactTab from 'app/modules/slipway/tabs/SlipwayContactTab';
import SlipwayOverviewTab from 'app/modules/slipway/tabs/SlipwayOverviewTab';

@inject('store')
@observer
class Slipway extends Component {
    componentDidMount() {
        this.props.store.slipway.getInitialCamperSettings();
    }

    buildFormTabs() {
        const { slipway } = this.props.store;

        let tabs = [];
        let tabNames = [];

        if (!slipway.hiddenFields.isNationalityHidden || !slipway.hiddenFields.isPlateNumberHidden) {
            tabs.push(<SlipwayInfoTab />);
            tabNames.push('COMMON.INFO_TITLE');
        }

        tabs.push(<SlipwayContactTab />);
        tabNames.push('COMMON.STEPS_CONTACT_INFORMATION');

        tabs.push(<SlipwayOverviewTab />);

        return { tabs, tabNames };
    }

    render() {
        const { store: { slipway: { loading, price } } } = this.props;

        if (loading || !price) {
            return <Loader fullScreen={true} />
        }

        const { tabs, tabNames } = this.buildFormTabs();

        return (
            <div className="module-container">
                <FormTabRouter tabs={tabs} module={'slipway'} />
                <SlipwayNav tabTitles={tabNames} />
            </div>
        )
    }
}

export default Slipway;
