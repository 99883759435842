import React, { Component } from 'react';
import { translate } from '../../../../i18n/i18n';
import { inject, observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import HarborApi from '../../../../api/HarborApi';
import Loader from '../../../components/Loader';

@translate()
@inject('store')
@observer
class ShopHackProductsTab extends Component {
    constructor() {
        super();
        this.state = {
            products: [],
            loading: false,
        }
    }

    componentDidMount() {
        this.setState({ loading: true })
        const response = HarborApi.getShopHackProducts(this.props.store.harbor.id);
        Promise.all([response])
            .then((res) => {
                const products = JSON.parse(res[0].data[0].settingValue);
                this.setState({ products, loading: false });
            })
            .catch(() => {
                this.setState({ products: null });
            });
    }

    addProductToOrder(product, idx) {
        let products = [...this.state.products];
        if (products[idx].quantity) {
            products[idx].quantity ++;
        } else {
            products[idx].quantity = 1;
        }

        this.setState({ products });
        this.props.store.shopHack.setFormValue('order', products);
        this.props.store.shopHack.setFormValue('totalPrice', this.countTotalPrice(products));
        this.props.store.shopHack.savePaymentRequest('shopHack', this.countTotalPrice(products));
    }

    removeProductFromOrder(product, idx) {
        let products = [...this.state.products];
        if (products[idx].quantity && products[idx].quantity < 0) {
            return;
        } else {
            products[idx].quantity --;
        }

        this.setState({ products });
        this.props.store.shopHack.setFormValue('order', products);
        this.props.store.shopHack.setFormValue('totalPrice', this.countTotalPrice(products));
        this.props.store.shopHack.savePaymentRequest('shopHack', this.countTotalPrice(products));
    }

    countTotalPrice(products) {
        let total = 0;
        products.map((product) => {
            if (product.quantity) {
                total += Number(product.price) * Number(product.quantity);
            }
            return true;
        })
        return total;
    }

    render() {
        const { currency } = this.props.store.harbor;
        const { t } = this.props;

        if (this.state.loading) {
            return (
                <div className='text-center'>
                    <Loader fullScreen={true} />
                </div>
            )
        }

        return (
            <div className='container' style={{ height: '100%' }}>
                <div className="utilities-container" style={{ width: '100%' }}>
                    {this.state.products.map((product, index)=>(
                        <div key={index}
                            className="btn btn-light shop-utility col-sm-3">
                            <div className="utility-block">
                                <div>
                                    <button className="duration-count-borderless duration-btn-minus
                                                    btn btn-light no-margin"
                                            type="button"
                                            disabled={!product.quantity || product.quantity <= 0}
                                            onClick={() => this.removeProductFromOrder(product, index)}>
                                        <FontAwesomeIcon icon={faMinus} />
                                    </button>
                                </div>
                                <div>
                                    <div className="utility-title">
                                        <b>{product.name}</b>
                                    </div>
                                    <div className="utility-price">
                                        {product.price + ' ' + currency}
                                    </div>
                                </div>
                                <div>
                                    <button className="duration-count-borderless duration-btn-plus
                                                    btn btn-light no-margin"
                                            type="button"
                                            disabled={product.quantity && product.limit > 0 && product.quantity >= product.limit}
                                            onClick={() => this.addProductToOrder(product, index)}>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </button>
                                </div>
                            </div>
                            <div className="amount-block" style={{ visibility: product.quantity ? 'visible' : 'hidden' }}>
                                {t('COMMON.SHOP_QTY')}: {product.quantity ? product.quantity : null}
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        )
    }
}

export default ShopHackProductsTab;
