import { observable } from 'mobx';
import moment from 'moment';

class CamperPaymentViewModel {
    @observable phone;
    @observable dateFrom;
    @observable dateTo;
    @observable lot;
    @observable plateNumber;

    constructor(camper, amount, locale) {
        this.phone = camper.phone ? camper.phone : '+';
        this.plateNumber = camper.plateNumber;
        this.dateFrom = this.formatDate(moment(camper.dateFrom), locale);
        this.dateTo = this.formatDate(moment(camper.dateTo), locale);
        this.lot = camper.camperLot;
    }

    formatDate(date, locale) {
        switch (locale) {
            case 'da':
                return date.format('DD.MM.YYYY');
            default:
                return date.format('YYYY-MM-DD');
        }
    }
}

export default CamperPaymentViewModel;
