import React, { Component, Fragment } from 'react';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Loader from 'app/components/Loader';
import PaymentSuccessTitle from 'app/modules/payment/components/PaymentSuccessTitle';
import PaymentSuccessNavBar from 'app/modules/payment/components/PaymentSuccessNavBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import AccessCodeReminder from '../../components/AccessCodeReminder';

@translate()
@withRouter
@inject('store')
@observer
class CamperPaymentSuccess extends Component {
    async componentDidMount() {
        const { payment: { camper } } = this.props.store;
        const { params: { orderId } } = this.props.match;

        await camper.fetchPrinterStatus()
        camper.finalizePayment(orderId);
    }

    render() {
        const { t, store: { payment: { camper } } } = this.props;

        const camperRoot = camper.getCamperRoot();
        const camperToUse = camperRoot.rootStore.camper.camperFromApi;

        if (!camperToUse) {
            return <Loader fullScreen={true} />;
        }

        return (
            <div className="payment-block">
                <PaymentSuccessTitle />

                <div className="review-box">
                    <Fragment>
                        {camperToUse.plateNumber !== 'SLIPWAY' &&
                            <div className="payment-overview">
                                <div className="payment-dates-title-container">
                                    <span className="payment-dates-titles">{t('COMMON.ARRIVAL')}</span>
                                    <span className="payment-dates-titles">{t('COMMON.DEPARTURE')}</span>
                                </div>
                                <div className="payment-date-container">
                                    <span className="payment-date">
                                        {camperToUse.dateFrom}
                                    </span>
                                    <span>
                                        <FontAwesomeIcon className="payment-arrow-right"
                                                         icon={faLongArrowAltRight} />
                                    </span>
                                    <span className="payment-date">
                                    {camperToUse.dateTo}
                                </span>
                                </div>
                            </div>
                        }

                        <div>
                            {camperToUse.lot &&
                            <div id={'location'} className="payment-data-container">
                                <div className="payment-review-label">{t('COMMON.LOCATION')}:</div>
                                <div className="payment-review-text-round">
                                    {camperToUse.lot.name}
                                </div>
                            </div>
                            }
                        </div>

                        <div>
                            {(camperToUse.plateNumber && camperToUse.plateNumber !== 'SLIPWAY') &&
                            <div id={'location'} className="payment-data-container">
                                <div className="payment-review-label">{t('COMMON.CAMPER_PLATE')}:</div>
                                <div className="payment-review-text-round">
                                    {camperToUse.plateNumber}
                                </div>
                            </div>
                            }
                        </div>
                    </Fragment>
                </div>

                {camper.hasAccessCode && (
                    <AccessCodeReminder accessCode={camper.accessCode} />
                )}

                <PaymentSuccessNavBar />
            </div>
        )
    }
}

export default CamperPaymentSuccess;
