import React, { Component } from 'react';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import Loader from 'app/components/Loader';
import PaymentSuccessTitle from 'app/modules/payment/components/PaymentSuccessTitle';
import PaymentSuccessNavBar from 'app/modules/payment/components/PaymentSuccessNavBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltDown } from '@fortawesome/free-solid-svg-icons';

@translate()
@inject('store')
@observer
class ShopPaymentSuccess extends Component {
    constructor() {
        super();
        this.state = {
            isPrinted: false,
        };
    }

    componentDidMount() {
        const { payment: { shop } } = this.props.store;

        shop.fetchPrinterStatus().then(() => {
            try {
                shop.finalizePayment();
                this.setState({ isPrinted: true });
            } catch (err) {
                this.setState({ isPrinted: false });
            }
        });
    }

    render() {
        const { t, store: { terminal, payment } } = this.props;

        if ((!payment.shop.isReceiptPrinted && !this.state.isPrinted) && terminal.hasPrinter) {
            return <Loader fullScreen={true} />;
        }

        return (
            <div className="payment-block">
                <PaymentSuccessTitle />

                {terminal.hasPrinter &&
                <div style={ { marginTop: '150px' } } className="payment-sticker-message">
                    <FontAwesomeIcon className="payment-arrow-down" icon={faLongArrowAltDown} />
                    <div>{t('COMMON.RECEIPT_COLLECT_REMINDER')}</div>
                    <FontAwesomeIcon className="payment-arrow-down" icon={faLongArrowAltDown} />
                </div>
                }

                <PaymentSuccessNavBar />
            </div>
        )
    }
}

export default ShopPaymentSuccess;
