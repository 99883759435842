import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import BookingPaymentSuccess from 'app/modules/payment/modules/Booking/BookingPaymentSuccess';
import CreditPaymentSuccess from 'app/modules/payment/modules/Credit/CreditPaymentSuccess';
import CamperPaymentSuccess from 'app/modules/payment/modules/Camper/CamperPaymentSuccess';
import ShopPaymentSuccess from 'app/modules/payment/modules/Shop/ShopPaymentSuccess';
import CraneBookingPaymentSuccess from './modules/CraneBooking/CraneBookingPaymentSuccess';
import ShopHackPaymentSuccess from './modules/ShopHack/ShopHackPaymentSuccess';
import SlipwayPaymentSuccess from './modules/Slipway/SlipwayPaymentSuccess';
import Loader from 'app/components/Loader';

@withRouter
@inject('store')
@observer
class PaymentSuccess extends Component {
    componentDidMount() {
        const { match: { params: { orderId, locale } }, store: { payment } } = this.props;

        this.props.store.locale.setInitialLocale(locale);

        payment.fetchPaymentType(orderId);
    }

    render() {
        const { store: { payment: { paymentType, loading } } } = this.props;

        return (
            <Fragment>
                { loading && <Loader fullScreen={true} /> }
                { !loading &&
                    <Fragment>
                        {paymentType === 'booking' && <BookingPaymentSuccess />}
                        {paymentType === 'credit' && <CreditPaymentSuccess />}
                        {paymentType === 'camper' && <CamperPaymentSuccess />}
                        {paymentType === 'slipway' && <SlipwayPaymentSuccess />}

                        {paymentType === 'shop' && <ShopPaymentSuccess />}
                        {paymentType === 'craneBooking' && <CraneBookingPaymentSuccess />}
                        {paymentType === 'shopHack' && <ShopHackPaymentSuccess />}
                    </Fragment>
                }
            </Fragment>
        )
    }
}

export default PaymentSuccess;
