import { action, computed, observable } from 'mobx';
import ModuleStore from 'store/modules/ModuleStore';
import BookingApi from 'api/BookingApi';
import moment from 'moment';

class BookingStore extends ModuleStore {
    @observable docks;
    @observable berths;
    @observable bookingFromApi;

    @observable form = {
        dateFrom: moment().startOf('day').format(),
        duration: 1,
        phone: '+',
        email: null,
        price: null,
        dock: null,
        berth: null,
        boatLength: null,
        boatCountry: null,
        boatName: null,
        hasFreeharborSelected: false,
        utilities: [],
        freeCrewLimitReached: false,
        boatTooBig: false,
        debounceLoading: false,
        locationLoading: false,
        berthsLoading: false,
        priceLoading: false,
    }

    constructor(rootStore) {
        super(rootStore);
        this.rootStore = rootStore;
    }

    @action
    getInitialDateRange() {
        this.checkDepartureTimePassed();
        this.getDateRange((value) => this.setLoading(value));
    }

    @action
    debounceAvailability() {
        this.setFormValue('debounceLoading', true);
        this.onDebounce(() => {
            this.getDateRange((value) => this.setFormValue('locationLoading', value));
            this.setFormValue('debounceLoading', false);
        }, 1000);
    }

    @action
    getDateRange(loadingFunction) {
        const harborId = this.rootStore.harbor.id;

        loadingFunction(true);

        BookingApi.listAvailableDocks(harborId, this.dateRangeForBooking)
            .then(({ data }) => {
                this.setDocks(null);
                this.setDocks(data);

                if (data.length === 1) {
                    this.requestAvailableBerths(loadingFunction);
                } else {
                    loadingFunction(false);
                }
            })
    }

    @action
    requestAvailableBerths(loadingFunction) {
        const harborId = this.rootStore.harbor.id;
        this.setBerths(null);
        this.setFormValue('berth', null);

        loadingFunction(true);

        BookingApi.listAvailableDockBerths(harborId, this.dateRangeForBooking, this.form.dock['id'])
            .then(({ data }) => {
                this.setBerths(null);
                this.setBerths(data);
                loadingFunction(false);
            })
    }

    @action
    getBookingPrice() {
        this.setFormValue('boatTooBig', false);
        this.setFormValue('price', null);

        const { boatLength, dock, priceLoading } = this.form;

        if (boatLength && dock && !priceLoading) {
            this.setFormValue('priceLoading', true);
            const body = this.bodyForPriceCheck;

            BookingApi.checkPrice(body)
                .then(({ data }) => {
                    this.setFormValue('price', data);
                    this.setFormValue('priceLoading', false);
                })
                .catch((e) => {
                    if (e.response && e.response.status === 500)  {
                        this.setFormValue('priceLoading', false);
                        this.setFormValue('boatTooBig', true);
                    }
                });
        }
    }

    @computed
    get createBookingPromise() {
        return () => {
            return new Promise((resolve, reject) => {
                BookingApi.createBooking(this.formRequestBody)
                    .then((response) => {
                        const { data } = response;
                        if (response.status === 201 && data['created']) {
                            this.savePaymentRequest(
                                data['order']['orderNumber'],
                                this.form.price.total,
                                data['order']['id']
                            );
                            setTimeout(() => {
                                resolve();
                            })
                        } else {
                            reject()
                        }
                    })
                    .catch(() => {
                        reject();
                    })
            })
        }
    }

    @action
    setBookingFromApi(data) {
        this.bookingFromApi = data;
    }

    @action
    setDocks(data) {
        this.docks = data;
        if (data && data.length === 1) {this.setFormValue('dock', data[0])}
    }

    @action
    setBerths(data) {
        this.berths = data;
        if (data && data.length === 1) {this.setFormValue('berth', data[0])}
    }

    @computed
    get dateRangeForBooking() {
        const dateFrom = moment(this.form.dateFrom);
        const dateTo = moment(this.form.dateFrom).add(this.form.duration,'days');

        return {
            fromDate: dateFrom.format('YYYY-MM-DD'),
            toDate: dateTo.format('YYYY-MM-DD'),
        };
    }

    @computed
    get isAnyAvailableSpots() {
        return this.docks && this.docks.length > 0;
    }

    @computed
    get isLoading() {
        return this.form.debounceLoading || this.form.locationLoading;
    }

    @computed
    get isNoBerths() {
        return !this.berths;
    }

    @computed
    get isLocationStepNeedsSkip() {
        return !!(this.docks && this.docks.length === 1 && (!this.berths || this.berths.length <= 1));
    }

    @computed
    get isUtilityStepNeedsSkip() {
        return this.utilities.length === 0;
    }


    @computed
    get bodyForPriceCheck() {
        return {
            dateFrom: moment(this.form.dateFrom).format('YYYY-MM-DD'),
            dateTo: moment(this.form.dateFrom).add(this.form.duration,'days').format('YYYY-MM-DD'),
            boatLength: this.form.boatLength,
            boatWidth: this.form.boatLength,
            bridge: this.form.dock['id'],
            harbor: this.rootStore.harbor.id,
            hasFreeharborSelected: this.form.hasFreeharborSelected,
            utilities: this.form.utilities.map((utility) => {return { utility }}),
            freeCrewLimitReached: this.form.freeCrewLimitReached,
            source: 'kiosk',
            email: this.form.email,
            phone: this.form.phone
        };
    }

    @computed
    get formRequestBody() {
        const {
            phone, email, dateFrom, duration, boatLength, dock, berth, boatName, boatCountry, hasFreeharborSelected,
            freeCrewLimitReached
        } = this.form;

        const { bookingPricingType } = this.rootStore.harbor;

        return {
            dateFrom: moment(dateFrom).format('YYYY-MM-DD'),
            dateTo: moment(dateFrom).add(duration,'days').format('YYYY-MM-DD'),
            boatLength,
            boatWidth: bookingPricingType === 'by_boat_length' ? null : boatLength,
            utilities: this.form.utilities.map((utility) => {return { utility }}),
            harbor: this.rootStore.harbor.id,
            hasFreeharborSelected,
            phone: (!this.phonePreselected && phone !== '+') ? phone : null,
            email,
            bridge: dock['id'],
            position: berth ? berth['id'] : null,
            boatName,
            boatCountry,
            freeCrewLimitReached,
            source: 'kiosk',
        };
    }

    @computed
    get paidUtilities() {
        return this.utilities.filter((utility) =>  utility.price > 0);
    }

    @computed
    get freeUtilities() {
        return this.utilities.filter((utility) =>  utility.price === 0);
    }

    @computed
    get utilities() {
        if (!this.rootStore.harbor.utilities) {
            return [];
        }

        return this.rootStore.harbor.utilities.filter(utility => {
            if (utility.utility.name !== 'diesel' && utility.utility.name !== 'gas') {
                return utility;
            }
            return null;
        });
    }
}

export default BookingStore;
