import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import LanguageSelector from 'app/dashboard/components/LanguageSelector';
import TopBar from 'app/dashboard/components/TopBar';
import SelectionPage from 'app/dashboard/components/SelectionPage';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import Payment from 'app/modules/payment/Payment';
import Information from 'app/modules/information/Information';
import Camper from 'app/modules/camper/Camper';
import WebsocketClient from 'app/dashboard/websocket/WebsocketClient';
import IdleTimer from 'react-idle-timer';
import TestArea from 'app/modules/testarea/TestArea';
import Shop from 'app/modules/shop/Shop';
import WelcomePage from './components/WelcomePage';
import WeatherScreensaver from 'app/modules/screensaver/WeatherScreensaver';
import Slipway from 'app/modules/slipway/Slipway';
import Credit from 'app/modules/credit/Credit';
import Booking from 'app/modules/booking/Booking';
import CraneBooking from '../modules/craneBooking/CraneBooking';
import ShopHack from '../modules/shopHack/ShopHack';

@withRouter
@inject('store')
@observer
class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.onIdle = this._onIdle.bind(this);
    }

    render() {
        const { harbor: { isWelcomePageNeedsToBeShown }, locale: { initialLocaleSelection } } = this.props.store;
        const { screensaver } = this.props.store;

        return (
            <Fragment>
                <Fragment>
                    {process.env.REACT_APP_KIOSK_TYPE === 'indoor' &&
                        <Fragment>
                            <IdleTimer
                                element={document}
                                onIdle={this.onIdle}
                                debounce={250}
                                startOnMount={false}
                                timeout={1000 * (process.env.REACT_APP_ENV === 'development' ? 600 : 60)} />
                        </Fragment>
                    }
                </Fragment>
                <TopBar />
                <div className="dashboard">
                    <WebsocketClient />
                    <Switch>
                        {/* Routes where initial locale is set automatically goes under here*/}
                        <Route path="/:harborId/payment" render={() => <Payment />} />
                        <Route path="/:harborId/screensaver" render={() => <WeatherScreensaver />} />

                        {/* Routes that require initial locale select goes under here*/}
                        {screensaver.enabled && <Route path="/" render={() => <WeatherScreensaver />} />}
                        {!initialLocaleSelection && <Route path="/" render={() => <LanguageSelector />} />}
                        {isWelcomePageNeedsToBeShown && <Route path="/" render={() => <WelcomePage />} />}

                        <Route exact path="/:harborId" render={() => <SelectionPage />} />
                        <Route path="/:harborId/booking" render={() => <Booking />} />
                        <Route path="/:harborId/credit" render={() => <Credit />} />
                        <Route path="/:harborId/credit-slipway" render={() => <Credit />} />
                        <Route path="/:harborId/camper" render={() => <Camper />} />
                        <Route path="/:harborId/slipway" render={() => <Slipway />} />
                        <Route path="/:harborId/shop" render={() => <Shop />} />
                        <Route path="/:harborId/test" render={() => <TestArea />} />
                        <Route path="/:harborId/information" render={() => <Information />} />
                        <Route path="/:harborId/crane" render={() => <CraneBooking />} />
                        <Route path="/:harborId/shopHack" render={() => <ShopHack />} />
                        <Route render={() => (<Redirect to="/" />)} />
                    </Switch>
                </div>
            </Fragment>
        );
    }

    _onIdle() {
        const payRegex = /^\/.*\/payment\/pay\/[+-]?([0-9]*[.])?[0-9]+$/;
        const { store: { terminal } } = this.props;

        if (payRegex.test(this.props.location.pathname)) {
            terminal.abortPayment();
        }

        this.props.store.refreshStore();
    }
}

export default Dashboard;
