import { action, observable } from 'mobx';
import CreditApi from 'api/CreditApi';
import CreditPaymentViewModel from 'store/PaymentStore/modules/Credit/CreditPaymentViewModel';
import CreditReceiptModel from 'store/PaymentStore/PrintModels/CreditReceiptModel';
import AbstractModulePaymentStore from 'store/PaymentStore/modules/AbstractModulePaymentStore';
import PrinterApi from '../../../../api/PrinterApi';
import HarborApi from '../../../../api/HarborApi';

class CreditPaymentStore extends AbstractModulePaymentStore {
    @observable credit: CreditPaymentViewModel;
    @observable hasAccessCode = false;

    constructor(paymentStore) {
        super(paymentStore);
        this.payment = paymentStore;
    }

    @action
    getCreditRoot() {
        return this.payment.rootStore.credit;
    }

    @action
    finalizePayment(orderId) {
        getPaymentByOrderIdPromise(orderId)
            .then((response) => {
                if (response) {
                    let responseObject = response;
                    HarborApi.getAccessCode(orderId)
                        .then((res)=>{
                            responseObject.accessCode = res.data;
                            if (res.data) {
                                this.setHasAccessCode();
                            }

                            this.setCredit(responseObject);
                            this.markPaymentAsPaid(orderId)

                            this.generatePrint(responseObject);
                            this.printReceipt();
                        })
                }
            })
    }

    @action
    extractTotalByOrderId(orderId) {
        return new Promise((resolve, reject) => {
            getPaymentByOrderIdPromise(orderId)
                .then((response) => {
                    this.setPaymentTotalIndoor(response.amount);
                    this.setPaymentTotalOutdoor(response.amount * 100);
                    resolve(response.amount);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    markPaymentAsPaid(orderId) {
        const q = CreditApi.markCreditAsPaid(orderId, this.getGateway(), this.payment.rootStore.credit.fee);
        q.then(() => {});
    }

    @action
    setCredit(response) {
        this.accessCode = response.accessCode;
        this.credit = new CreditPaymentViewModel(response.credit, response.amount);
        this.payment.rootStore.credit.setCreditFromApi(new CreditPaymentViewModel(response.credit, response.amount));
    }

    @action
    setHasAccessCode() {
        this.hasAccessCode = true;
    }

    @action
    generatePrint(response) {
        this.receiptPrint = new CreditReceiptModel().build(response, this.payment.rootStore);
    }

    printReceipt() {
        if (this.payment.rootStore.terminal.hasPrinter && this.receiptPrint) {
            PrinterApi.printCreditReceipt(this.receiptPrint).then(() => {
                this.setIsReceiptPrinted(true)
            });
        }
    }
}

const getPaymentByOrderIdPromise = (orderId) => {
    return new Promise(resolve => {
        CreditApi.getCreditPaymentByOrderId(orderId)
            .then((res) => resolve(res.data));
    })
}

export default CreditPaymentStore;
