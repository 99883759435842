import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import logo from 'assets/img/kioskLogo.png'
import { translate } from 'i18n/i18n';
import InputModal from 'app/components/InputModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';

@translate()
@withRouter
@inject('store')
@observer
class TopBar extends Component {

    incrementExitModalCount() {
        const { store: { exit } } = this.props;

        if (exit.count < 4) {
            exit.incrementCount();
        } else {
            exit.setIsExitModalOpen(true)
        }
    }

    isExitCodeEntered() {
        const { store: { terminal, exit } } = this.props;

        if (terminal.exitCode.toString() === exit.code) {
            exit.closeClient();
            setTimeout(() => window.close(), 1000);
        }
    }

    isTestAreaCodeEntered() {
        const { store: { terminal, exit, harbor } } = this.props;

        if (terminal.testAreaCode.toString() === exit.code) {
            this.props.history.push(`/${harbor.id}/test`);
        }
    }

    render() {
        const locales = this.props.store.locale['locales'];
        const isLocaleSelected = this.props.store.locale['initialLocaleSelection'];
        const { store: { harbor, terminal, exit },
            t, history,
            location: { pathname } } = this.props;

        const isAtReceiptPage = (pathname.indexOf('payment/success') > 0) || (pathname.indexOf('payment/failed') > 0);

        const changeLocale = (locale) => () => {
            this.props.store.locale.setLocale(locale);
        };

        const closeExitModal = () => () => exit.closeExitModal();
        const changeExitCode = () => (value) => exit.setCode(value);
        const exitApp = () => () => {
            this.isExitCodeEntered();
            this.isTestAreaCodeEntered();
            exit.closeExitModal();
        };

        const generateInfoPath = () => {
            const routerItems = pathname.split('/');
            return `/${routerItems[1]}/information?from=${pathname}`;
        }

        return (
            <div className="topbar-container">
                <img className="topbar-logo" alt={'logo'} src={logo} onClick={() => this.incrementExitModalCount()} />

                <InputModal closeOnOutClick={false} isOpen={exit.isExitModalOpen} onClose={closeExitModal}
                            onSave={exitApp()} onChange={changeExitCode()}
                            value={exit.code} isLengthInput={true}
                            message={t('COMMON.EXIT_TEXT')} inputWidth={'350px'} />

                {isLocaleSelected && harbor.harbakioskSettings.paperCount < 50 && !isAtReceiptPage &&
                    <div className="topbar-error">{t('COMMON.NOT_ENOUGH_PAPER')}</div>
                }

                {isLocaleSelected && !terminal.isReady &&
                    <div className="topbar-error">{t('COMMON.TERMINAL_OFFLINE')}</div>
                }

                {isLocaleSelected ?
                    <div className="topbar-btn-container">
                        {locales.map((locale, index) => {
                            return (
                                <button onClick={changeLocale(locale['locale'])}
                                        key={index + 'small'}
                                        type="button"
                                        className="language-btn-small btn btn-light">
                                    <img className="language-flag-small" alt={'test'} src={locale.flag} />
                                </button>
                            )
                        })}
                        <button type="button"
                                className="information-btn"
                                onClick={() => history.replace(generateInfoPath())}>
                            <FontAwesomeIcon icon={faQuestion} />
                        </button>
                    </div>
                :
                    null
                }
            </div>
        );
    }
}

export default TopBar;
