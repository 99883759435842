class PaymentFailedModel {
    build(rootStore) {
        const locale = rootStore.locale.locale;

        return {
            locale,
            alertOnLowPaperEnabled: false
        }
    }

    buildTest() {
        const locale = 'da';

        return {
            locale,
            alertOnLowPaperEnabled: false
        }
    }
}

export default PaymentFailedModel;
