import React, { Component } from 'react';
import TextFormInput from 'app/components/form/inputs/TextFormInput';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import InputContainer from 'app/components/form/inputs/utils/InputContainer';
import FormValidator from 'store/validators/FormValidator';

@translate()
@inject('store')
@observer
class CamperContactTab extends Component {
    render() {
        const { t, store: { camper } } = this.props;
        const { hiddenFields } = camper;
        const { empty, phoneEmpty, isPhoneValidForDisplay, isEmailValidForDisplay } = new FormValidator();

        return (
            <div className="tab-container">
                <div className="email-or-phone">{t('COMMON.PHONE_OR_EMAIL')}</div>

                <InputContainer forceRow>
                    <TextFormInput
                        isRequired
                        hidden={hiddenFields.isFirstNameHidden}
                        moduleStore={camper}
                        formFieldName={'firstName'}
                        setTitle={t('COMMON.ENTER_FIRST_NAME')}
                        changeTitle={t('COMMON.CHANGE_FIRST_NAME')}
                        title={t('COMMON.FIRST_NAME') + ':'} />

                    <TextFormInput
                        isRequired
                        hidden={hiddenFields.isLastNameHidden}
                        moduleStore={camper}
                        formFieldName={'lastName'}
                        setTitle={t('COMMON.ENTER_LAST_NAME')}
                        changeTitle={t('COMMON.CHANGE_LAST_NAME')}
                        title={t('COMMON.LAST_NAME') + ':'} />

                    <TextFormInput
                        isRequired={empty(camper.form.email) || !isEmailValidForDisplay(camper.form.email)}
                        isPhone
                        moduleStore={camper}
                        formFieldName={'phone'}
                        warningIcon={true}
                        redInputBorder={true}
                        actCodeWarning={true}
                        setTitle={t('COMMON.ENTER_PHONE')}
                        changeTitle={t('COMMON.CHANGE_PHONE')}
                        title={t('COMMON.PHONE') + ':'} />

                    <TextFormInput
                        isRequired={phoneEmpty(camper.form.phone) || !isPhoneValidForDisplay(camper.form.phone)}
                        isEmail
                        hidden={hiddenFields.isEmailHidden}
                        moduleStore={camper}
                        formFieldName={'email'}
                        warningIcon={true}
                        redInputBorder={true}
                        actCodeWarning={true}
                        setTitle={t('COMMON.ENTER_EMAIL')}
                        changeTitle={t('COMMON.CHANGE_EMAIL')}
                        title={t('COMMON.EMAIL') + ':'} />
                </InputContainer>
            </div>
        )
    }
}

export default CamperContactTab;
