import React, { Component, Fragment } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import 'scss/modules/testarea.scss';
import TestAreaComponent from 'app/modules/testarea/components/TestAreaComponent';

@withRouter
class TestArea extends Component {
    render() {
        return (
            <Fragment>
                <div className="test-container">
                    <Switch>
                        <Route path="/:harborId/test" render={() => <TestAreaComponent />} />
                        <Route render={() => (<Redirect to="/" />)} />
                    </Switch>
                </div>
            </Fragment>
        );
    }
}

export default TestArea;
