import Api from 'api/api';

class PrinterApi {
    getPrinterStatus() {
        return Api.getLocal('/printer/status');
    }
    getIsKioskWithPrinter() {
        return Api.getLocal('/printer/enabled');
    }

    printBookingReceipt(bookingReceipt) {
        return Api.postLocal('/printer/booking_receipt', bookingReceipt);
    }

    printBookingSticker(bookingSticker) {
        return Api.postLocal('/printer/booking_sticker', bookingSticker);
    }

    printShopReceipt(shopReceipt) {
        return Api.postLocal('/printer/shop_receipt', shopReceipt);
    }

    printCreditReceipt(creditReceipt) {
        return Api.postLocal('/printer/credit_receipt', creditReceipt);
    }

    printCamperReceipt(camperReceipt) {
        return Api.postLocal('/printer/camper_receipt', camperReceipt);
    }

    printCamperSticker(camperSticker) {
        return Api.postLocal('/printer/camper_sticker', camperSticker);
    }

    printPaymentFailed(paymentFailed) {
        return Api.postLocal('/printer/payment_failed', paymentFailed);
    }

    // Samples

    printCamperStickerSample() {
        return Api.getLocal('/printer/camper_sticker_sample');
    }
}

export default new PrinterApi();
