import React, { Component } from 'react';
import CraneBookingTypeTab from './tabs/CraneBookingTypeTab';
import CraneBookingOverviewTab from './tabs/CraneBookingOverviewTab';
import FormTabRouter from '../../components/form/FormTabRouter';
import CraneBookingNav from './CraneBookingNav';
import { inject, observer } from 'mobx-react';

@inject('store')
@observer
class CraneBooking extends Component {

    buildFormTabs() {
        let tabs = [];
        let tabNames = [];

        tabs.push(<CraneBookingTypeTab />);
        tabNames.push('COMMON.CRANE_BOOKING_TYPE');

        tabs.push(<CraneBookingOverviewTab />);

        return { tabs, tabNames };
    }

    render() {
        const { tabs, tabNames } = this.buildFormTabs();

        return (
            <div className="module-container">
                <FormTabRouter tabs={tabs} module={'crane'} />
                <CraneBookingNav tabTitles={tabNames} />
            </div>
        )
    }
}

export default CraneBooking;
