import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import DurationFormInput from 'app/components/form/inputs/DurationFormInput';

@inject('store')
@observer
class CamperDurationTab extends Component {
    render() {
        const { camper } = this.props.store;

        return (
            <Fragment>
                <div className="tab-container">
                    <DurationFormInput moduleStore={camper} />
                </div>
            </Fragment>
        );
    }
}

export default CamperDurationTab;
