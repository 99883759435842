import React, { Component } from 'react';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import PaymentSuccessTitle from 'app/modules/payment/components/PaymentSuccessTitle';
import PaymentSuccessNavBar from 'app/modules/payment/components/PaymentSuccessNavBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltDown } from '@fortawesome/free-solid-svg-icons';

@translate()
@withRouter
@inject('store')
@observer
class CraneBookingPaymentSuccess extends Component {
    componentDidMount() {
        const { payment: { craneBooking } } = this.props.store;
        
        craneBooking.fetchPrinterStatus().then(() => craneBooking.finalizePayment());
    }

    render() {
        const { t, store: { terminal } } = this.props;

        return (
            <div className="payment-block">
                <PaymentSuccessTitle />

                {terminal.hasPrinter &&
                <div style={ { marginTop: '150px' } } className="payment-sticker-message">
                    <FontAwesomeIcon className="payment-arrow-down" icon={faLongArrowAltDown} />
                    <div>{t('COMMON.RECEIPT_COLLECT_REMINDER')}</div>
                    <FontAwesomeIcon className="payment-arrow-down" icon={faLongArrowAltDown} />
                </div>
                }

                <PaymentSuccessNavBar />
            </div>
        )
    }
}

export default CraneBookingPaymentSuccess;
