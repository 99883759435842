import { action, observable } from 'mobx';
import TerminalApi from 'api/TerminalApi';
import PrinterApi from 'api/PrinterApi';
import BookingReceiptModel from 'store/PaymentStore/PrintModels/BookingReceiptModel';
import BookingStickerModel from 'store/PaymentStore/PrintModels/BookingStickerModel';
import CreditReceiptModel from 'store/PaymentStore/PrintModels/CreditReceiptModel';
import CamperReceiptModel from 'store/PaymentStore/PrintModels/CamperReceiptModel';
import PaymentFailedModel from 'store/PaymentStore/PrintModels/PaymentFailedModel';
import CraneBookingReceiptModel from '../PaymentStore/PrintModels/CraneBookingReceiptModel';
import ShopHackReceiptModel from '../PaymentStore/PrintModels/ShopHackReceiptModel';

class TestAreaStore {
    @observable hasPrinter = false;
    @observable localAddress = null;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @action
    startTestReconciliation() {
        TerminalApi.startReconciliation()
            .then();
    }

    @action
    printTestBookingReceipt() {
        const print = new BookingReceiptModel().buildTest();
        PrinterApi.printBookingReceipt(print);
    }

    @action
    printTestBookingSticker() {
        const print = new BookingStickerModel().buildTest();
        PrinterApi.printBookingSticker(print);
    }

    @action
    printTestCreditReceipt() {
        const print = new CreditReceiptModel().buildTest();
        PrinterApi.printCreditReceipt(print);
    }

    @action
    printTestCamperReceipt() {
        const print = new CamperReceiptModel().buildTest();
        PrinterApi.printCamperReceipt(print);
    }

    @action
    printTestCamperStickerSample() {
        PrinterApi.printCamperStickerSample();
    }

    @action
    printTestCraneBookingReceipt() {
        const print = new CraneBookingReceiptModel().buildTest();
        PrinterApi.printShopReceipt(print);
    }

    @action
    printTestShopHackReceipt() {
        const print = new ShopHackReceiptModel().buildTest();
        PrinterApi.printShopReceipt(print);
    }

    @action
    printTestPaymentFailed() {
        const print = new PaymentFailedModel().buildTest();
        PrinterApi.printPaymentFailed(print);
    }

    @action
    getTestAreaStatus() {
        const q = TerminalApi.getLocalAddress();
        const q2 = PrinterApi.getPrinterStatus();

        Promise.all([q, q2])
            .then((values) => {
                this.rootStore.terminal.setLocalAddress(values[0].data);
                this.rootStore.terminal.setHasPrinter(values[1].data)
            })
            .catch();
    }
}

export default TestAreaStore;
