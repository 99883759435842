import React, { Component } from 'react';
import TextFormInput from 'app/components/form/inputs/TextFormInput';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import InputContainer from 'app/components/form/inputs/utils/InputContainer';

@translate()
@inject('store')
@observer
class SlipwayContactTab extends Component {
    render() {
        const { t, store: { slipway } } = this.props;
        const { hiddenFields } = slipway;

        return (
            <div className="tab-container">
                <InputContainer forceRow>
                    <TextFormInput
                        isRequired
                        hidden={hiddenFields.isFirstNameHidden}
                        moduleStore={slipway}
                        formFieldName={'firstName'}
                        setTitle={t('COMMON.ENTER_FIRST_NAME')}
                        changeTitle={t('COMMON.CHANGE_FIRST_NAME')}
                        title={t('COMMON.FIRST_NAME') + ':'} />

                    <TextFormInput
                        isRequired
                        hidden={hiddenFields.isLastNameHidden}
                        moduleStore={slipway}
                        formFieldName={'lastName'}
                        setTitle={t('COMMON.ENTER_LAST_NAME')}
                        changeTitle={t('COMMON.CHANGE_LAST_NAME')}
                        title={t('COMMON.LAST_NAME') + ':'} />

                    <TextFormInput
                        isRequired isPhone
                        moduleStore={slipway}
                        formFieldName={'phone'}
                        warningIcon={true}
                        redInputBorder={true}
                        actCodeWarning={true}
                        setTitle={t('COMMON.ENTER_PHONE')}
                        changeTitle={t('COMMON.CHANGE_PHONE')}
                        title={t('COMMON.PHONE') + ':'} />

                    <TextFormInput
                        isRequired isEmail
                        hidden={hiddenFields.isEmailHidden}
                        moduleStore={slipway}
                        formFieldName={'email'}
                        warningIcon={true}
                        redInputBorder={true}
                        actCodeWarning={true}
                        setTitle={t('COMMON.ENTER_EMAIL')}
                        changeTitle={t('COMMON.CHANGE_EMAIL')}
                        title={t('COMMON.EMAIL') + ':'} />
                </InputContainer>
            </div>
        )
    }
}

export default SlipwayContactTab;
