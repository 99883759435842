import React, { Component } from 'react';
import PropTypes from 'prop-types'
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { inject, observer } from 'mobx-react';
import { translate } from 'i18n/i18n';
import KeyboardedInput from 'react-touch-screen-keyboard';

Modal.setAppElement('#root');

@translate()
@inject('store')
@observer
class CountryModal extends Component {
    constructor() {
        super();
        this.state = { open: false, page: 1, search: '' }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.triggerOpen && (prevProps.value !== this.props.triggerOpen)) {
            this.setState({ open: true });
        }
    }

    setModalPage(newPage) {
        this.setState({ page: newPage });
    }

    setModalSearch(search) {
        this.setState({ page: 1, search });
    }

    closeModal() {
        this.setState({ open: false, page: 1, search: '' });
    }

    paginator() {
        const items = countries;
        const { page, search } = this.state;

        let perPage = 20,
            offset = (page - 1) * perPage,
            filteredItems = items.filter((item) => item.label.toUpperCase().indexOf(search.toUpperCase()) !== -1);

        let paginatedItems = filteredItems.slice(offset).slice(0, perPage),
            total_pages = Math.ceil(filteredItems.length / perPage);

        return {
            page: page,
            perPage: perPage,
            prePage: page - 1 ? page - 1 : null,
            nextPage: (total_pages > page) ? page + 1 : null,
            total: filteredItems.length,
            total_pages: total_pages,
            data: paginatedItems
        };
    }

    initialFocus(ref) {
        this.inputRef = ref;
    }

    render() {
        const { t, moduleStore, formFieldName, closeOnOutClick, preselectPhone } = this.props;

        const { open, page, search } = this.state;

        const value = moduleStore.form[formFieldName];

        const pageDown = () => () => this.setModalPage(page - 1);

        const pageUp = () => () => this.setModalPage(page + 1);

        const select = (value) => () => {
            moduleStore.setFormValue(formFieldName, value);

            if (preselectPhone) {
                moduleStore.preselectPhone(value);
            }

            this.closeModal();
        };

        const onSearch = () => (value) => {
            this.setModalSearch(value)
        };

        const close = () => () => {
            this.inputRef = null;
            setTimeout(() => {
                this.closeModal();
            });
        };

        const paginator = this.paginator();

        return (
            <Modal isOpen={open} shouldCloseOnOverlayClick={closeOnOutClick} onRequestClose={close()}
                   className="form-modal country">
                <label htmlFor="inp" className="inp modal-search-keyboard inpSmall">
                    <KeyboardedInput enabled ref={ref => this.initialFocus(ref)} value={search} isDraggable={false}
                                     defaultKeyboard={keyboardMapping} isFirstLetterUppercase={false}
                                     placeholder={t('COMMON.SEARCH_COUNTRY')} onChange={onSearch()}
                    />
                </label>

                <div className="country-modal">
                    <div className="modal-arrow-container">
                        {paginator.page !== 1 &&
                            <FontAwesomeIcon icon={faChevronLeft} className="modal-arrow" onClick={pageDown()} />
                        }
                    </div>

                    <div className="modal-option-container">
                        {paginator.data.map((option, i) => {
                            return (
                                <button onClick={select(option.value)} key={i}
                                        className={`${value === option.value && 'active-option'} modal-option
                                                    flag-option`}>
                                            <span className={`flag-icon modal-flag-icon
                                                flag-icon-${option.value.toLowerCase()}`}>
                                            </span>
                                    {option.value}
                                </button>
                            )
                        })}
                    </div>

                    <div className="modal-arrow-container">
                        {paginator.nextPage &&
                            <FontAwesomeIcon icon={faChevronRight} className="modal-arrow" onClick={pageUp()} />
                        }
                    </div>
                </div>
            </Modal>
        )
    }
}

export default CountryModal;

CountryModal.propTypes = {
    moduleStore: PropTypes.any.isRequired,
    formFieldName: PropTypes.string.isRequired,
    triggerOpen: PropTypes.any,
    closeOnOutClick: PropTypes.any,
    preselectPhone: PropTypes.bool,
};

const keyboardMapping = [
    ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'å'],
    ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'æ', 'ø'],
    ['z', 'x', 'c', 'v', 'b', 'n', 'm', '.', '@', '*sh']
];

const countries = [
    // Harba countries
    { value: 'DK', label: 'Denmark', additional: { timezone: 'Europe/Copenhagen', locale: 'da_DK', localeName: 'Danish', currency: 'DKK', currencyPrefix: null, currencySuffix: ' kr' } },
    { value: 'DE', label: 'Germany', additional: { timezone: 'Europe/Berlin', locale: 'de_DE', localeName: 'German', currency: 'EUR', currencyPrefix: null, currencySuffix: '€' } },
    { value: 'SE', label: 'Sweden', additional: { timezone: 'Europe/Stockholm', locale: 'en_US', localeName: 'English', currency: 'SEK', currencyPrefix: null, currencySuffix: ' kr' } },
    { value: 'NO', label: 'Norway', additional: { timezone: 'Europe/Oslo', locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: null, currencySuffix: ' kr' } },
    { value: 'GB', label: 'United Kingdom', additional: { timezone: 'Europe/London', locale: 'en_US', localeName: 'English', currency: 'GBP', currencyPrefix: '£', currencySuffix: null } },
    { value: 'US', label: 'United States', additional: { locale: 'en_US', localeName: 'English', currency: 'USD', currencyPrefix: '$', currencySuffix: null } },
    { value: 'AU', label: 'Australia', additional: { locale: 'en_AU', localeName: 'English', currency: 'AUD', currencyPrefix: 'A$', currencySuffix: null } },
    { value: 'FI', label: 'Finland', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'LT', label: 'Lithuania', additional: { timezone: 'Europe/Vilnius', locale: 'lt_LT', localeName: 'Lithuanian', currency: 'EUR', currencyPrefix: null, currencySuffix: ' €' } },
    { value: 'PL', label: 'Poland', additional: { timezone: 'Europe/Warsaw', locale: 'pl_PL', localeName: 'Polish', currency: 'PLN', currencyPrefix: null, currencySuffix: ' zł' } },
    { value: 'FR', label: 'France', additional: { timezone: 'Europe/Paris', locale: 'fr_FR', localeName: 'French', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'NL', label: 'Netherlands', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'ES', label: 'Spain', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'IT', label: 'Italy', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'IE', label: 'Ireland', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'BE', label: 'Belgium', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'AT', label: 'Austria', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'LU', label: 'Luxembourg', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'PT', label: 'Portugal', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'CH', label: 'Switzerland', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },

    // Other countries
    { value: 'EE', label: 'Estonia', additional: { timezone: 'Europe/Tallinn', locale: 'et_EE', localeName: 'Estonian', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'LV', label: 'Latvia', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'BD', label: 'Bangladesh', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'GR', label: 'Greece', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'HR', label: 'Croatia', additional: { timezone: 'Europe/Zagreb', locale: 'hr_HR', localeName: 'Croatian', currency: 'HRK', currencyPrefix: null, currencySuffix: ' kn' } },
    { value: 'BF', label: 'Burkina Faso', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'BG', label: 'Bulgaria', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'BA', label: 'Bosnia and Herzegovina', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'BB', label: 'Barbados', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'WF', label: 'Wallis and Futuna', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'BL', label: 'Saint Barthelemy', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'BM', label: 'Bermuda', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'BN', label: 'Brunei', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'BO', label: 'Bolivia', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'BH', label: 'Bahrain', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'BI', label: 'Burundi', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'BJ', label: 'Benin', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'BT', label: 'Bhutan', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'JM', label: 'Jamaica', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'BW', label: 'Botswana', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'WS', label: 'Samoa', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'BQ', label: 'Bonaire, Saint Eustatius and Saba ', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'BR', label: 'Brazil', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'BS', label: 'Bahamas', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'JE', label: 'Jersey', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'BY', label: 'Belarus', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'BZ', label: 'Belize', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'RU', label: 'Russia', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'RW', label: 'Rwanda', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'RS', label: 'Serbia', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'TL', label: 'East Timor', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'IS', label: 'Iceland', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'RE', label: 'Reunion', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'TM', label: 'Turkmenistan', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'TJ', label: 'Tajikistan', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'RO', label: 'Romania', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'TK', label: 'Tokelau', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'GW', label: 'Guinea-Bissau', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'GU', label: 'Guam', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'GT', label: 'Guatemala', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'GS', label: 'South Georgia and the South Sandwich Islands', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'GQ', label: 'Equatorial Guinea', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'GP', label: 'Guadeloupe', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'JP', label: 'Japan', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'GY', label: 'Guyana', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'GG', label: 'Guernsey', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'GF', label: 'French Guiana', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'GE', label: 'Georgia', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'GD', label: 'Grenada', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'GA', label: 'Gabon', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'SV', label: 'El Salvador', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'GN', label: 'Guinea', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'GM', label: 'Gambia', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'GL', label: 'Greenland', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'GI', label: 'Gibraltar', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'GH', label: 'Ghana', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'OM', label: 'Oman', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'TN', label: 'Tunisia', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'JO', label: 'Jordan', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'HT', label: 'Haiti', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'HU', label: 'Hungary', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'HK', label: 'Hong Kong', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'HN', label: 'Honduras', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'VE', label: 'Venezuela', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'PR', label: 'Puerto Rico', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'PS', label: 'Palestinian Territory', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'PW', label: 'Palau', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'SJ', label: 'Svalbard and Jan Mayen', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'PY', label: 'Paraguay', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'IQ', label: 'Iraq', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'PA', label: 'Panama', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'PF', label: 'French Polynesia', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'PG', label: 'Papua New Guinea', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'PE', label: 'Peru', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'PK', label: 'Pakistan', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'PH', label: 'Philippines', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'PN', label: 'Pitcairn', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'PM', label: 'Saint Pierre and Miquelon', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'ZM', label: 'Zambia', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'EH', label: 'Western Sahara', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'EG', label: 'Egypt', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'ZA', label: 'South Africa', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'EC', label: 'Ecuador', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'VN', label: 'Vietnam', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'SB', label: 'Solomon Islands', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'ET', label: 'Ethiopia', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'SO', label: 'Somalia', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'ZW', label: 'Zimbabwe', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'SA', label: 'Saudi Arabia', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'ER', label: 'Eritrea', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'ME', label: 'Montenegro', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'MD', label: 'Moldova', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'MG', label: 'Madagascar', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'MF', label: 'Saint Martin', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'MA', label: 'Morocco', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'MC', label: 'Monaco', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'UZ', label: 'Uzbekistan', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'MM', label: 'Myanmar', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'ML', label: 'Mali', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'MO', label: 'Macao', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'MN', label: 'Mongolia', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'MH', label: 'Marshall Islands', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'MK', label: 'Macedonia', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'MU', label: 'Mauritius', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'MT', label: 'Malta', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'MW', label: 'Malawi', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'MV', label: 'Maldives', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'MQ', label: 'Martinique', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'MP', label: 'Northern Mariana Islands', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'MS', label: 'Montserrat', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'MR', label: 'Mauritania', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'IM', label: 'Isle of Man', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'UG', label: 'Uganda', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'TZ', label: 'Tanzania', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'MY', label: 'Malaysia', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'MX', label: 'Mexico', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'IL', label: 'Israel', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'IO', label: 'British Indian Ocean Territory', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'SH', label: 'Saint Helena', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'FJ', label: 'Fiji', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'FK', label: 'Falkland Islands', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'FM', label: 'Micronesia', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'FO', label: 'Faroe Islands', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'NI', label: 'Nicaragua', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'NA', label: 'Namibia', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'VU', label: 'Vanuatu', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'NC', label: 'New Caledonia', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'NE', label: 'Niger', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'NF', label: 'Norfolk Island', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'NG', label: 'Nigeria', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'NZ', label: 'New Zealand', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'NP', label: 'Nepal', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'NR', label: 'Nauru', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'NU', label: 'Niue', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'CK', label: 'Cook Islands', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'XK', label: 'Kosovo', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'CI', label: 'Ivory Coast', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'CO', label: 'Colombia', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'CN', label: 'China', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'CM', label: 'Cameroon', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'CL', label: 'Chile', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'CC', label: 'Cocos Islands', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'CA', label: 'Canada', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'CG', label: 'Republic of the Congo', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'CF', label: 'Central African Republic', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'CD', label: 'Democratic Republic of the Congo', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'CZ', label: 'Czech Republic', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'CY', label: 'Cyprus', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'CX', label: 'Christmas Island', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'CR', label: 'Costa Rica', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'CW', label: 'Curacao', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'CU', label: 'Cuba', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'SZ', label: 'Swaziland', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'SY', label: 'Syria', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'KG', label: 'Kyrgyzstan', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'KE', label: 'Kenya', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'SS', label: 'South Sudan', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'SR', label: 'Suriname', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'KI', label: 'Kiribati', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'KH', label: 'Cambodia', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'KM', label: 'Comoros', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'ST', label: 'Sao Tome and Principe', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'SK', label: 'Slovakia', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'KR', label: 'South Korea', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'SI', label: 'Slovenia', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'KP', label: 'North Korea', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'KW', label: 'Kuwait', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'SN', label: 'Senegal', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'SM', label: 'San Marino', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'SL', label: 'Sierra Leone', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'SC', label: 'Seychelles', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'KZ', label: 'Kazakhstan', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'KY', label: 'Cayman Islands', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'SG', label: 'Singapore', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'SD', label: 'Sudan', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'DO', label: 'Dominican Republic', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'DM', label: 'Dominica', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'DJ', label: 'Djibouti', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'VG', label: 'British Virgin Islands', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'YE', label: 'Yemen', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'DZ', label: 'Algeria', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'UY', label: 'Uruguay', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'YT', label: 'Mayotte', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'UM', label: 'United States Minor Outlying Islands ', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'LB', label: 'Lebanon', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'LC', label: 'Saint Lucia', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'LA', label: 'Laos', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'TW', label: 'Taiwan', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'TR', label: 'Turkey', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'LK', label: 'Sri Lanka', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'LI', label: 'Liechtenstein', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'TO', label: 'Tonga', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'LR', label: 'Liberia', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'LS', label: 'Lesotho', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'TH', label: 'Thailand', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'TF', label: 'French Southern Territories', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'TG', label: 'Togo', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'TD', label: 'Chad', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'TC', label: 'Turks and Caicos Islands', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'LY', label: 'Libya', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'VA', label: 'Vatican', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'VC', label: 'Saint Vincent and the Grenadines', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'AE', label: 'United Arab Emirates', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'AD', label: 'Andorra', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'AG', label: 'Antigua and Barbuda', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'AF', label: 'Afghanistan', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'AI', label: 'Anguilla', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'VI', label: 'U.S. Virgin Islands', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'IR', label: 'Iran', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'AM', label: 'Armenia', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'AL', label: 'Albania', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'AO', label: 'Angola', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'AS', label: 'American Samoa', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'AR', label: 'Argentina', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'AW', label: 'Aruba', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'IN', label: 'India', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'AX', label: 'Aland Islands', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'AZ', label: 'Azerbaijan', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'ID', label: 'Indonesia', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'UA', label: 'Ukraine', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'QA', label: 'Qatar', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } },
    { value: 'MZ', label: 'Mozambique', additional: { locale: 'en_US', localeName: 'English', currency: 'EUR', currencyPrefix: '€', currencySuffix: null } }
];
