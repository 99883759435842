import React from 'react';
import PropTypes from 'prop-types';

const Loader = (props) => {
    return (
        <div className={props.fullScreen ? 'spinnerContainer' : ''}>
            <div className="spinner spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    );
};

Loader.propTypes = {
    fullScreen: PropTypes.any,
};

export default Loader;
