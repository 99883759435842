import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Loader from 'app/components/Loader';
import FormTabRouter from 'app/components/form/FormTabRouter';
import BookingDurationTab from 'app/modules/booking/tabs/BookingDurationTab';
import BookingNav from 'app/modules/booking/BookingNav';
import BookingLocationTab from 'app/modules/booking/tabs/BookingLocationTab';
import BookingBoatInfoTab from 'app/modules/booking/tabs/BookingBoatInfoTab';
import BookingUtilityTab from 'app/modules/booking/tabs/BookingUtilityTab';
import BookingContactTab from 'app/modules/booking/tabs/BookingContactTab';
import BookingOverviewTab from 'app/modules/booking/tabs/BookingOverviewTab';

@inject('store')
@observer
class Booking extends Component {
    componentDidMount() {
        this.props.store.booking.getInitialDateRange();
    }

    buildFormTabs() {
        const { booking } = this.props.store;
        const { isLocationStepNeedsSkip, isUtilityStepNeedsSkip } = booking;

        let tabs = [];
        let tabNames = [];

        tabs.push(<BookingDurationTab />);
        tabNames.push('COMMON.STEPS_DURATION');

        if (!isLocationStepNeedsSkip) {
            tabs.push(<BookingLocationTab />);
            tabNames.push('COMMON.STEPS_LOCATION');
        }

        tabs.push(<BookingBoatInfoTab />);
        tabNames.push('COMMON.STEPS_BOAT');

        if (!isUtilityStepNeedsSkip) {
            tabs.push(<BookingUtilityTab />);
            tabNames.push('COMMON.STEPS_SERVICE');
        }

        tabs.push(<BookingContactTab />);
        tabNames.push('COMMON.STEPS_CONTACT_INFORMATION');

        tabs.push(<BookingOverviewTab />);

        return { tabs, tabNames };
    }

    render() {
        const { store: { booking: { loading } } } = this.props;

        if (loading) {
            return <Loader fullScreen={true} />
        }

        const { tabs, tabNames } = this.buildFormTabs();

        return (
            <div className="module-container">
                <FormTabRouter tabs={tabs} module={'booking'} />
                <BookingNav tabTitles={tabNames} />
            </div>
        )
    }
}

export default Booking;
