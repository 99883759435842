class FormValidator {
    isPhoneValidForChange(value) {
        const lengthLimit = 15;
        const lastChar = value.substr(value.length - 1);

        if (value.length <= lengthLimit && lastChar !== ' ' && (value.indexOf('+')) !== -1) {
            if (value === '+' || value.substr(1).match(/^[0-9]+$/) != null) {
                return true;
            }
        }

        return false;
    }

    isEmailValidForChange(value) {
        const lastChar = value.substr(value.length - 1);

        if (lastChar !== ' ') {
            return true;
        }

        return false;
    }

    isMoneyAmountValidForChange(value) {
        return this.isValidNumberForChange(value);
    }

    isValidNumberForChange(value) {
        const stringLength = 8;

        const regex = /^[+-]?\d+(\.\d+)?$/;
        const lastChar = value.substr(value.length - 1);
        const dotCount = (value.match(/\./g) || []).length;
        const isLengthValid = regex.test(value) || (lastChar === '.' && (value.length > 1) && dotCount <= 1);
        const noLastDot = !(lastChar === '.' && value.length === stringLength);
        const dotPresent = value.indexOf('.') === -1;
        const onlyTwoDecimals = dotPresent || (value.indexOf('.') !== -1 && (value.length - value.indexOf('.')) <= 3);

        if ((value === '' || isLengthValid) && (value.length <= stringLength) && noLastDot && onlyTwoDecimals) {
            return true
        }

        return false
    }

    isPhoneValidForDisplay(value) {
        let re = /^[\s()+-]*([0-9][\s()+-]*){9,20}$/;
        return re.test(value);
    }

    isEmailValidForDisplay(value) {
        let re = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
        return re.test(value);
    }

    isNumberValidForDisplay(value) {
        let re = /^(-?\d+\.\d+)$|^(-?\d+)$/;
        return re.test(value);
    }

    empty(value) {
        return value === null || value === '';
    }

    phoneEmpty(value) {
        return value === null || value === '+';
    }
}

export default FormValidator;
