import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FormTextOverview = ({ t, label, value, showWarnSign }) => {
    if (!value || (value && value === '+')) {
        return null
    }
    return (
        <div className="overview-block">
            <div className="overview-label">
                {showWarnSign && (
                    <span className='fa-warning mr-1'>
                        <FontAwesomeIcon icon='exclamation-triangle'/>
                    </span>
                )}
                {label}:
            </div>
            <span className="overview-text">{value}</span>
        </div>
    );
};

FormTextOverview.propTypes = {
    label: PropTypes.any.isRequired,
    value: PropTypes.any,
};

export default FormTextOverview;
