import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import PaymentFailedForm from 'app/modules/payment/components/PaymentFailedForm';
import Loader from 'app/components/Loader';

@withRouter
@inject('store')
@observer
class CamperPaymentFailed extends Component {
    constructor() {
        super();
        this.state = {
            total: null,
        };
    }

    componentDidMount() {
        const { payment: { camper } } = this.props.store;
        const { params: { orderId } } = this.props.match;

        camper.extractTotalByOrderId(orderId)
            .then((res) => {
                this.setState({ total: res });
                camper.fetchPrinterStatus();
            })
    }

    render() {
        const { payment: { camper: { isTotalSet } } } = this.props.store;

        return (
            <Fragment>
                {!isTotalSet && !this.state.total ?
                    <Loader fullScreen={true} /> : <PaymentFailedForm total={this.state.total} />
                }
            </Fragment>
        )
    }
}

export default CamperPaymentFailed;
