import React, { Component, Fragment } from 'react';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';

@translate()
@inject('store')
@observer
class PaymentFailedNavBar extends Component {
    render() {
        const { t, store: { terminal, payment } } = this.props;
        const { isReceiptPrinted } = payment[payment.paymentType];

        const printReceipt = () => () => {
            payment[payment.paymentType].printPaymentFailed();
            payment[payment.paymentType].setIsReceiptPrinted(true);
        };

        const exit = () => () => {
            this.props.store.refreshStore();
        };

        return (
            <Fragment>
                {terminal.hasPrinter &&
                    <input className={`payment-btn form-btn-full ${isReceiptPrinted ? 'form-btn-filled' : ''}`}
                           type="button"
                           onClick={printReceipt()}
                           disabled={isReceiptPrinted}
                           value={isReceiptPrinted ? t('COMMON.COLLECT_YOUR_RECEIPT') : t('COMMON.PRINT_RECEIPT')}
                    />
                }
                <form>
                    <input type="button" value={t('COMMON.EXIT')} onClick={exit()}
                           className="payment-btn payment-exit-btn" />
                </form>
            </Fragment>
        );
    }
}

export default PaymentFailedNavBar;
