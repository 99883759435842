import React, { Component } from 'react';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import wifi from 'assets/img/wifi.png'
import power from 'assets/img/power.png'
import toilet from 'assets/img/toilet.png'
import shower from 'assets/img/shower.png'
import laundry from 'assets/img/laundry.png'
import wifiWhite from 'assets/img/wifi-white.png'
import powerWhite from 'assets/img/power-white.png'
import toiletWhite from 'assets/img/toilet-white.png'
import showerWhite from 'assets/img/shower-white.png'
import laundryWhite from 'assets/img/laundry-white.png'

@translate()
@inject('store')
@observer
class BookingUtilityTab extends Component {
    isSelected(id) {
        const { utilities } = this.props.store.booking.form;
        return utilities.indexOf(id) > -1;
    }

    toggleUtility(utility) {
        const { utilities } = this.props.store.booking.form;

        if (utility.type !== 'cash') {
            const id = utility.id;
            const isAlreadySelected = utilities.indexOf(id) > -1;

            isAlreadySelected ? this.removeUtility(id) : this.addUtility(id);
        }
    }

    addUtility(id) {
        const { booking } = this.props.store;
        const { utilities } = booking.form;
        let clone = JSON.parse(JSON.stringify(utilities));
        clone.push(id);

        booking.setFormValue('utilities', clone);
    }

    removeUtility(id) {
        const { booking } = this.props.store;
        const { utilities } = booking.form;

        const index = utilities.indexOf(id);

        if (index > -1) {
            let clone = JSON.parse(JSON.stringify(utilities));

            clone.splice(index, 1);
            booking.setFormValue('utilities', clone);
        }
    }

    render() {
        const { booking } = this.props.store;

        const paidUtilities = booking.paidUtilities;
        const freeUtilities = booking.freeUtilities;
        const { currency } = this.props.store.harbor;
        const { t } = this.props;

        return (
            <div className="tab-container">
                {paidUtilities && paidUtilities.length > 0 &&
                <div className="utility-container">
                    <div className="utility-service">
                            <span className="utility-message-text">
                                    {t('COMMON.SERVICE_TITLE')}
                            </span>
                    </div>
                    <div id={'utilities'} className="utilities-container">
                        {paidUtilities.map((utility, i) => {
                            return (
                                <button key={i}
                                        className={`utility btn btn-light
                                                    ${this.isSelected(utility.id) ? 'utility-selected' : ''}`}
                                        onClick={() => this.toggleUtility(utility)}>

                                    <img className="utility-image"
                                         alt={'test'}
                                         src={this.isSelected(utility.id) ?
                                             images[utility.utility.name + 'White'] :
                                             images[utility.utility.name]
                                         } />
                                    <div className="utility-title font-weight-bold">
                                        {t('COMMON.' + utility.utility.name.toUpperCase())}
                                    </div>
                                    {utility.type !== 'cash' ?
                                        utility.daily ?
                                            <div className="utility-price">
                                                {utility.price + ' ' + currency + t('COMMON.PER_DAY')}
                                            </div>
                                            :
                                            <div className="utility-price">
                                                {utility.price + ' ' + currency}
                                            </div>

                                        :
                                        <div className="utility-price">
                                            {t('COMMON.BY_CASH_INFORMATION')}
                                        </div>
                                    }
                                </button>
                            )
                        })}
                    </div>
                </div>
                }

                {freeUtilities && freeUtilities.length > 0 &&
                <div className="utility-container">
                    <div id={'freeUtilities'} className="utility-service">
                        <span className="utility-message-text">
                                {t('COMMON.SERVICE_FREE_TITLE')}:
                        </span>
                    </div>
                    <div id={'utilities'} className="utilities-container">
                        {freeUtilities.map((utility, i) => {
                            return (
                                <button key={i} className="btn btn-light utility utility-selected">
                                    <img className="utility-image" alt={'test'}
                                         src={images[utility.utility.name + 'White']}
                                    />
                                    <div className="utility-title">
                                        {t('COMMON.' + utility.utility.name.toUpperCase())}
                                    </div>
                                    <div className="utility-price">
                                        {t('COMMON.FREE')}
                                    </div>
                                </button>
                            )
                        })}
                    </div>
                </div>
                }

            </div>
        );
    }
}

const images = {
    wifi: wifi,
    power: power,
    toilet: toilet,
    shower: shower,
    laundry: laundry,
    wifiWhite: wifiWhite,
    powerWhite: powerWhite,
    toiletWhite: toiletWhite,
    showerWhite: showerWhite,
    laundryWhite: laundryWhite,
};

export default BookingUtilityTab;
