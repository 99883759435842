import Api from 'api/api';

class HarborApi {
    getHarbor(id) {
        return Api.get(`/harbors/${id}/map`);
    }

    getHarbakioskSettings(id) {
        return Api.get(`/kiosk/setting/${id}`);
    }

    subtractPaperCount(id) {
        return Api.get(`/kiosk/setting/${id}?subtract=true`);
    }

    getDocks(id) {
        return Api.get(`/public/harbors/${id}/bridges?limit=9999`);
    }

    getBerths(id, dockId) {
        return Api.get(`/public/harbors/${id}/bridges/${dockId}/positions?limit=9999`);
    }

    getGuestSetting(id) {
        return Api.get(`/harbors/${id}/guest_setting`);
    }

    getBookingSetting(id) {
        return Api.get(`/public/booking/settings/${id}`);
    }

    getShopHackProducts(id) {
        return Api.get(`/public/kiosk/${id}/settings`);
    }


    getAccessCode(orderId) {
        return Api.get(`/public/access-manager/access-code/${orderId}`);
    }
}

export default new HarborApi();
