import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

@withRouter
class FormTabRouter extends Component {
    render() {
        const { tabs, module, location: { pathname, search } } = this.props;
        return (
            <Switch>
                <Route exact path={`/:harborId/${module}`} render={() => (<Redirect to={pathname + '/1'+search} />)} />

                {tabs.map((tab, i) => {
                    return (<Route key={i} path={`/:harborId/${module}/${i + 1}`} render={() => tab} />)
                })}

                <Route render={() => (<Redirect to="/" />)} />
            </Switch>
        )
    }
}

FormTabRouter.propTypes = {
    tabs: PropTypes.array.isRequired,
    module: PropTypes.string.isRequired
};

export default FormTabRouter;
