import React, { Component, Fragment } from 'react';
import Loader from 'app/components/Loader';
import HarborNotFound from 'app/components/HarborNotFound';
import { inject, observer } from 'mobx-react';

@inject('store')
@observer
class LayoutHarborAuth extends Component {
    hasError = false;
    componentDidMount() {
        const { match: { params: { harborId } } } = this.props;
        this.props.store.harbor.requestHarbor(harborId);
    }

    reloadOnConnectionIssues(error) {
        if (this.hasError) {
            setTimeout(() => {
                if (this.hasError) {
                    window.location.reload();
                }
            }, 2 * 60 * 1000);
        }
    }
    render() {
        const { id, harbakioskSettings: { isEnabled }, loading } = this.props.store.harbor;
        this.hasError = !loading && !id;
        this.reloadOnConnectionIssues();
        return (
            loading ?
                <Loader fullScreen={true} /> :
                ((id && isEnabled) ? <Fragment>{this.props.children}</Fragment> : <HarborNotFound id={id} />)
        );
    }
}

export default LayoutHarborAuth;
