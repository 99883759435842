import React, { Component } from 'react';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';

@translate()
@inject('store')
@observer
class PaymentSuccessNavBar extends Component {
    render() {
        const { t, store: { terminal, payment } } = this.props;

        const { isReceiptPrinted } = payment[payment.paymentType];

        const printReceipt = () => () => {
            payment[payment.paymentType].printReceipt();
        };

        const exit = () => () => {
            this.props.store.refreshStore();
        };

        return (
            <div className="success-action-container">
                <div className="w-100">
                    { terminal.hasPrinter &&
                        <div className="text-center mb-4">
                            <input
                                className={`print-receipt-button form-btn form-btn-full  ${isReceiptPrinted ? 'form-btn-filled' : ''}`}
                                type="button"
                                onClick={printReceipt()}
                                disabled={isReceiptPrinted}
                                value={isReceiptPrinted ? t('COMMON.COLLECT_YOUR_RECEIPT') : t('COMMON.PRINT_RECEIPT')}
                            />
                        </div>
                    }
                    <div className="text-center">
                        <input type="button" value={t('COMMON.EXIT')} onClick={exit()}
                               className="form-btn payment-exit-btn"/>
                    </div>
                </div>
            </div>

        );
    }
}

export default PaymentSuccessNavBar;
