import { computed, observable } from 'mobx';
import ModuleStore from 'store/modules/ModuleStore';

class CraneBookingStore extends ModuleStore {
    @observable prices;

    @observable form = {
        type: '',
        price: null,
    }

    constructor(rootStore) {
        super(rootStore);
        this.rootStore = rootStore;
    }

    @computed
    get createCraneBookingPaymentPromise() {
        return () => {
            return new Promise((resolve, reject) => {
                resolve();
            })
        }
    }

}

export default CraneBookingStore;
