import React from 'react';
import wifiIssueImage from '../../assets/img/wifi_issue.png';
import mobileAppQrImage from '../../assets/img/mobile-app-qr.png';

const HarborNotFound = () => (
	<div className="container d-flex justify-content-center align-items-center vh-100">
		<div className="row">
			<div className="col-sm-12 text-center mb-5">
				<img className="img-fluid" src={wifiIssueImage} alt="Wifi Issue" />
			</div>
			<div className="col-sm-4">
				<h2 className="text-harba-blue font-weight-bold">Network issue</h2>
				<p className="text-harba-blue">
					We are sorry for the inconvenience. Scan the QR code with your smartphone camera to get app for mobile payments.
				</p>
			</div>
			<div className="col-sm-4">
				<h2 className="text-harba-blue font-weight-bold">Netværksfejl</h2>
				<p className="text-harba-blue">
					Vi beklager ulejligheden. Scan QR koden med dit smartphone kamera og få appen til mobil betalinger.
				</p>
				<div className="text-center">
					<img className="img-fluid mt-3" src={mobileAppQrImage} alt="Mobile App QR code" />
				</div>
			</div>
			<div className="col-sm-4">
				<h2 className="text-harba-blue font-weight-bold">Netzwerkproblem</h2>
				<p className="text-harba-blue">
					Wir entschuldigen uns für den Anlass. Scannen Sie den QR-Code mit Ihrer Smartphone-Kamera und erhalten Sie die App für mobiles Bezahlen.
				</p>
			</div>
		</div>
	</div>
)

export default HarborNotFound;
