class VerifonePaymentModel {
    orderId = null;
    amount = null;
    proceedWithoutPrinter = null;
    isvData = '';

    constructor(amount, orderId, isvData = null, proceedWithoutPrinter = false) {
        this.amount = amount;
        this.orderId = orderId;
        this.isvData = isvData;
        this.proceedWithoutPrinter = proceedWithoutPrinter;
    }
}

export default VerifonePaymentModel;
