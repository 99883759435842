import moment from 'moment';

class BookingStickerModel {
    build(response, rootStore) {
        const prices = JSON.parse(response['legacyPrices']);
        const { total } = prices;

        const currency = rootStore.harbor.currency;
        const arrivalDay = moment(response.dateFrom).format('YYYY-MM-DD');
        const departureDay = moment(response.dateTo).format('YYYY-MM-DD');
        const lengthFrom = Number(prices['priceTier']['lengthFrom']);
        const lengthTo = Number(prices['priceTier']['lengthTo']);
        const locale = rootStore.locale.locale;
        const harborName = rootStore.harbor.name;
        const stickerInitials = rootStore.harbor.harbakioskSettings.stickerInitials;
        const freeCrewLimitReached = response.freeCrewLimitReached;
        const isFreeharbor = response.isFreeharbor ? response.isFreeharbor : false;
        const crewMembersLimit = rootStore.harbor.crewMembersLimit;

        return {
            locale,
            alertOnLowPaperEnabled: false,
            arrivalDay,
            departureDay,
            boatLengthFrom: lengthFrom,
            boatLengthTo: lengthTo,
            harborName,
            stickerInitials,
            total: total.toFixed(2),
            currency,
            freeCrewLimitReached,
            crewMembersLimit,
            isFreeharbor
        }
    }

    buildTest() {
        const locale = 'da';
        let arrivalDay = moment().add(1,'days').format('YYYY-MM-DD');
        let departureDay = moment().add(2,'days').format('YYYY-MM-DD');
        const boatLengthFrom = 10;
        const boatLengthTo = 15;

        return {
            locale,
            alertOnLowPaperEnabled: false,
            arrivalDay,
            departureDay,
            boatLengthFrom,
            boatLengthTo,
            harborName: 'PORT',
            stickerInitials: 'PRT',
            total: '12.34',
            currency: 'DKK',
            freeCrewLimitReached: true,
            crewMembersLimit: 12,
            isFreeharbor: true
        }
    }
}

export default BookingStickerModel;
