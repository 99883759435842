import React, { Component } from 'react';
import PropTypes from 'prop-types'
import Modal from 'react-modal';
import KeyboardedInput from 'react-touch-screen-keyboard';
import 'scss/modules/keyboard.scss';
import { translate } from 'i18n/i18n';

Modal.setAppElement('#root');

@translate()
class InputModal extends Component {
    initialFocus(ref) {
        if (this.props.isOpen) {
            if (!this.inputRef && ref) {
                ref.focus();
            }
        }

        this.inputRef = ref;
    }

    getKeyMappings() {
        const { isPhoneInput, isMoneyInput, isLengthInput, isEmailInput } = this.props;

        if (isPhoneInput) {
            return [
                ['7', '8', '9'],
                ['4', '5', '6'],
                ['1', '2', '3'],
                ['*bs', '0'],
            ];
        }

        if (isMoneyInput || isLengthInput) {
            return [
                ['7', '8', '9'],
                ['4', '5', '6'],
                ['1', '2', '3'],
                ['*bs', '0', '.'],
            ];
        }

        if (isEmailInput) {
            return [
                ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'å'],
                ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'æ', 'ø'],
                ['z', 'x', 'c', 'v', 'b', 'n', 'm', '.', '@']
            ];
        }

        return [
            ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'å'],
            ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'æ', 'ø'],
            ['z', 'x', 'c', 'v', 'b', 'n', 'm', '.', '@', '*sh']
        ];
    }

    render() {
        const { isOpen, message, inputWidth, value, t, isFirstLetterUppercase, isPhoneInput, isMoneyInput, isLengthInput
        } = this.props;
        const CustomMapping = this.getKeyMappings();

        const onChange = () => (val) => {this.props.onChange(val)};

        const save = () => () => {
            this.inputRef = null;
            setTimeout(() => {
                this.props.onSave();
            });
        };

        const close = () => () => {
            this.inputRef = null;
            setTimeout(() => {
                this.props.onClose();
            });
        };

        return (
            <Modal
                className="component-modal"
                isOpen={isOpen}
                shouldCloseOnOverlayClick={this.props.closeOnOutClick}
                onRequestClose={close()}
            >
                <div className="input-modal-container">
                    <div className="modal-message-text">{message}</div>

                    <label htmlFor="inp"
                           className={`inp center ${(isPhoneInput || isMoneyInput || isLengthInput) && 'float-input'}`}
                           style={{ maxWidth: inputWidth }}>
                        <KeyboardedInput enabled
                                         ref={ref => this.initialFocus(ref)}
                                         onBlur={() => {if (this.inputRef) {this.inputRef.focus()} }}
                                         onChange={onChange()}
                                         value={value}
                                         isDraggable={false}
                                         defaultKeyboard={CustomMapping}
                                         isFirstLetterUppercase={isFirstLetterUppercase} />
                    </label>

                    <button type="button" className="input-modal-btn btn btn-light" onClick={save()}>
                        { t('COMMON.SAVE') }
                    </button>
                </div>
            </Modal>
        )
    }
}

export default InputModal;

InputModal.propTypes = {
    closeOnOutClick: PropTypes.any.isRequired,
    isOpen: PropTypes.any.isRequired,
    onClose: PropTypes.any.isRequired,
    onSave: PropTypes.any.isRequired,
    onChange: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
    message: PropTypes.any.isRequired,
    inputWidth: PropTypes.any.isRequired,
    isFirstLetterUppercase: PropTypes.bool,
    isPhoneInput: PropTypes.bool,
    isLengthInput: PropTypes.bool,
    isMoneyInput: PropTypes.bool,
    isEmailInput: PropTypes.bool,
};

InputModal.defaultProps = {
    isFirstLetterUppercase: false,
    isPhoneInput: false,
    isLengthInput: false,
    isMoneyInput: false,
    isEmailInput: false,
};
