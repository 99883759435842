import React, { Component } from 'react';
import PropTypes from 'prop-types';

class InputContainer extends Component {
    // COMPONENT ACCEPTS MAXIMUM 4 INPUTS
    extractInputs() {
        const { children } = this.props;

        return React.Children.toArray(children).filter((input) => !input.props.hidden && input);
    }

    render() {
        const inputs = this.extractInputs();
        const { secondRowFullWidth, biggerContainer } = this.props;

        return (
            <div className={`input-container ${biggerContainer && 'input-container-bigger'}`}>
                <div className={`${(inputs.length > 2) || this.props.forceRow ? 'input-row' : 'input-row-vertical'}`}>
                    {inputs.map((input, i) => (i <= 1) &&
                        <div className="col-md-6 form-element" key={i}>
                            {input}
                        </div>
                    )}
                </div>
                {(inputs.length > 2) &&
                    <div className="input-row">
                        {inputs.map((input, i) => (i > 1) &&
                            <div className={`${secondRowFullWidth ? 'col-md-12' : 'col-md-6'} form-element`} key={i}>
                                {input}
                            </div>
                        )}
                    </div>
                }
            </div>
        )
    }
}

InputContainer.propTypes = {
    secondRowFullWidth: PropTypes.bool,
    biggerContainer: PropTypes.bool,
    forceRow: PropTypes.bool,
};

export default InputContainer;
