import { action, computed, observable } from 'mobx';
import ModuleStore from 'store/modules/ModuleStore';
import moment from 'moment';
import SlipwayApi from '../../../api/SlipwayApi';

class SlipwayStore extends ModuleStore {
    @observable price;
    @observable slipwayFromApi;

    @observable hiddenFields = {
        isPlateNumberHidden: false,
        isNationalityHidden: false,
        isFirstNameHidden: false,
        isLastNameHidden: false,
        isEmailHidden: false,
    };

    @observable form = {
        dateFrom: moment().startOf('day').format(),
        duration: 1,
        plateNumber: null,
        country: null,
        firstName: null,
        lastName: null,
        phone: '+',
        email: null,
        price: null
    }

    constructor(rootStore) {
        super(rootStore);
        this.rootStore = rootStore;
    }

    @action
    setSlipwayFromApi(data) {
        this.slipwayFromApi = data;
    }

    @action
    getInitialCamperSettings() {
        this.setLoading(true);
        const harborId = this.rootStore.harbor.id;

        Promise.all([getSlipwaySettingsPromise(harborId)])
            .then((response) => {
                this.setSlipwaySettings(response[0])
                this.setLoading(false);
            })
            .catch(() => this.setLoading(false));
    }

    @action.bound
    setSlipwaySettings(settings) {
        this.price = settings.price;

        this.hiddenFields.isPlateNumberHidden = settings.isPlateNumberHidden;
        this.hiddenFields.isNationalityHidden = settings.isNationalityHidden;
        this.hiddenFields.isFirstNameHidden = settings.isFirstNameHidden;
        this.hiddenFields.isLastNameHidden = settings.isLastNameHidden;
        this.hiddenFields.isEmailHidden = settings.isEmailHidden;
    }

    @computed
    get formRequestBody() {
        const { dateFrom, duration, plateNumber, country, firstName, lastName, phone, email } = this.form;
        return {
            dateFrom: dateFrom,
            dateTo: moment(dateFrom).add(duration,'days').format(),
            plateNumber,
            country,
            firstName,
            lastName,
            phone: (phone !== '+' && !this.phonePreselected) ? phone : null,
            email,
            price: this.total,
            source: 'kiosk',
        }
    }

    @computed
    get total() {
        return this.form.duration * this.price;
    }

    @computed
    get createSlipwayReservationPromise() {
        return () => {
            return new Promise((resolve, reject) => {
                SlipwayApi.postReservation(this.formRequestBody, this.rootStore.harbor.id)
                    .then(({ data }) => {
                        this.savePaymentRequest(data.orderId, this.total);
                        resolve();
                    })
                    .catch(() => {
                        reject();
                    })
            })
        }
    }
}

const getSlipwaySettingsPromise = (harborId) => {
    return new Promise(resolve => {
        SlipwayApi.getSettings(harborId)
            .then((res) => resolve(res.data));
    })
}


export default SlipwayStore;
