import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { translate } from 'i18n/i18n';
import FormNav from 'app/components/form/FormNav';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import FormValidator from 'store/validators/FormValidator';

@translate()
@withRouter
@inject('store')
@observer
class CreditNav extends Component {
    isTabValid(tabNumber) {
        const activeTab = this.props.tabTitles[tabNumber - 1];
        const { form } = this.props.store.credit;
        const { phone, email, price } = form;

        const { empty, isEmailValidForDisplay, isPhoneValidForDisplay, phoneEmpty } = new FormValidator();

        switch (activeTab) {
            case 'COMMON.PRICES':
                return !empty(price);
            case 'COMMON.STEPS_CONTACT_INFORMATION':
            {
                if (!(!empty(email) && isEmailValidForDisplay(email))
                    && !(!phoneEmpty(phone) && isPhoneValidForDisplay(phone))) {return false}
                return (!phoneEmpty(phone) || !empty(email));
            }
            default: return true;
        }
    }

    render() {
        const { location: { pathname }, store: { credit }, tabTitles } = this.props;
        const tabNumber = Number(pathname.charAt(pathname.length - 1));

        return (
            <FormNav tabTitles={tabTitles}
                     tabNumber={tabNumber}
                     isTabValid={this.isTabValid(tabNumber)}
                     prePaymentPromise={credit.createCreditPaymentPromise}
                     moduleStore={credit}
            />
        )
    }
}

CreditNav.propTypes = {
    tabTitles: PropTypes.array.isRequired
};

export default CreditNav;
