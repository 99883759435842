class ShopHackReceiptModel {
    build(rootStore, i18n) {

        const locale = rootStore.locale.locale;
        const currency = rootStore.harbor.currency;
        const harborName = rootStore.harbor.name;
        const productText = this.getProductText(rootStore);
        const productPriceText = this.getProductPriceText(rootStore);
        const productCount = this.getProductCount(rootStore);
        const total = rootStore.shopHack.form.totalPrice.toFixed(2);
        const vat = this.getVat(rootStore);

        return {
            locale,
            alertOnLowPaperEnabled: false,
            currency,
            harborName,
            productText,
            productPriceText,
            productCount,
            total,
            vat
        }
    }

    buildTest() {
        const locale = 'da';
        const items = [{ name: 'name', price: 88, quantity: 11 },{ name: 'name', price: 888, quantity: 111 },{ name: 'name', price: 8888, quantity: 1111 }];
        const orderText = items.map((item)=> `${item.name} x ${item.quantity}\n`).toString().replace(/,/g, '');
        const priceText = items.map((item)=> `${Number(item.price * item.quantity).toFixed(2)} DKK\n`).toString().replace(/,/g, '');

        return {
            locale,
            alertOnLowPaperEnabled: false,
            total: (120).toFixed(2),
            currency: 'DKK',
            harborName: 'PORT',
            productText: orderText,
            productPriceText: priceText,
            productCount: items.length,
            vat: '24.00'
        }
    }

    getVat(rootStore) {
        return (rootStore.shopHack.form.totalPrice - (rootStore.shopHack.form.totalPrice / 1.25)).toFixed(2)
    }

    getProductText(rootStore) {
        return rootStore.shopHack.form.order
            .map((item) => item.quantity > 0 ? `${item.shortName ? item.shortName : item.name} x ${item.quantity}\n` : null)
            .toString()
            .replace(/,/g, '');
    }

    getProductPriceText(rootStore) {
        return rootStore.shopHack.form.order
            .map((item) => item.quantity > 0 ? `${Number(item.price * item.quantity).toFixed(2)} DKK\n` : null)
            .toString()
            .replace(/,/g, '');
    }

    getProductCount(rootStore) {
        return rootStore.shopHack.form.order.filter((item) => item.quantity > 0).length;
    }
}

export default ShopHackReceiptModel;
