import React, { Component } from 'react';
import FormOverview from 'app/components/form/overview/FormOverview';
import FormTextOverview from 'app/components/form/overview/FormTextOverview';
import { inject, observer } from 'mobx-react';
import { translate } from 'i18n/i18n';
import FormPriceOverview from 'app/components/form/overview/FormPriceOverview';

@translate()
@inject('store')
@observer
class CreditOverviewTab extends Component {
    render() {
        const { t, store: { credit, harbor } } = this.props;
        const { form } = credit;
        const { email, phone, price } = form;

        return (
            <div className="tab-container">
                <FormOverview redBorder>
                    <FormTextOverview showWarnSign label={t('COMMON.EMAIL')} value={email} />
                    {phone && phone.length > 4 && (
                        <FormTextOverview showWarnSign label={t('COMMON.PHONE')} value={phone} />
                    )}
                    {harbor.harbakioskSettings.isCreditEnabled && (
                        <>
                            <hr />
                            <p className='color-red'>{t('COMMON.ACCESS_CODE_PHONE_EMAIL_WARNING')}</p>
                        </>
                    )}
                </FormOverview>

                <FormPriceOverview total={Number(price)} noDivider />
            </div>
        );
    }
}

export default CreditOverviewTab;
