import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PaymentFailedForm from 'app/modules/payment/components/PaymentFailedForm';

@inject('store')
@observer
class ShopHackPaymentFailed extends Component {
    constructor() {
        super();
        this.state = {
            total: null,
        };
    }

    async componentDidMount() {
        const { payment: { shopHack } } = this.props.store;

        await shopHack.extractTotalPayment();

        if (shopHack.paymentTotalIndoor) {
            this.setState({ total: shopHack.paymentTotalIndoor });
        }
    }

    render() {
        const { payment: { shopHack: { paymentTotalIndoor } } } = this.props.store;

        return <PaymentFailedForm total={paymentTotalIndoor || this.state.total} />
    }
}

export default ShopHackPaymentFailed;
