import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Loader from 'app/components/Loader';
import FormTabRouter from 'app/components/form/FormTabRouter';
import CreditNav from 'app/modules/credit/CreditNav';
import 'scss/modules/credit.scss';
import CreditPriceTab from 'app/modules/credit/tabs/CreditPriceTab';
import CreditContactTab from 'app/modules/credit/tabs/CreditContactTab';
import CreditOverviewTab from 'app/modules/credit/tabs/CreditOverviewTab';

@inject('store')
@observer
class Credit extends Component {
    slipwayOnly = false;
    componentDidMount() {
        if(window.location.search){
            this.slipwayOnly = window.location.search.includes('slipway=true');
        }
        this.props.store.credit.getInitialCreditPrices(this.slipwayOnly);
        this.props.store.credit.getActSettings();
    }

    buildFormTabs() {
        let tabs = [];
        let tabNames = [];

        if (this.props.store.credit.prices) {
            tabs.push(<CreditPriceTab />);
            tabNames.push('COMMON.PRICES');
        }

        tabs.push(<CreditContactTab />);
        tabNames.push('COMMON.STEPS_CONTACT_INFORMATION');

        tabs.push(<CreditOverviewTab />);

        return { tabs, tabNames };
    }

    render() {
        const { store: { credit: { loading } } } = this.props;

        if (loading) {
            return <Loader fullScreen={true} />
        }

        const { tabs, tabNames } = this.buildFormTabs();

        return (
            <div className="module-container">
                <FormTabRouter tabs={tabs} module={'credit'} />
                <CreditNav tabTitles={tabNames} />
            </div>
        )
    }
}

export default Credit;
