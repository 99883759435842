import {action, computed, observable} from 'mobx';
import TerminalApi from 'api/TerminalApi';
import PrinterApi from 'api/PrinterApi';
import VerifonePaymentModel from 'store/TerminalStore/models/VerifonePaymentModel';
import ConnectionObserver from './observers/ConnectionObserver';

class TerminalStore {
    @observable isConnected = false;
    @observable isWebsocketRunning = false;
    @observable hasPrinter = false;
    @observable localAddress = null;
    @observable exitCode = 2222;
    @observable testAreaCode = 6666;
    @observable type = '';
    @observable isKioskWithPrinter = false;
    terminalConnectionObserver;
    printerConnectionObserver;

    constructor(rootStore) {
        this.rootStore = rootStore;
        if (typeof localStorage !== 'undefined') {
            this.terminalConnectionObserver = new ConnectionObserver('Terminal');
            this.printerConnectionObserver = new ConnectionObserver('Printer');
        }
    }

    @action
    startPayment(verifonePayment: VerifonePaymentModel) {
        const q = TerminalApi.startPayment(verifonePayment);
        q.then();
    }

    @action
    onConnectedTerminalStatusCheck() {
        if (this.type === 'indoor') {
            this.initialIndoorCheck();
        }
    }

    @action
    initialIndoorCheck() {
        const q = TerminalApi.getTerminalStatus();
        const q2 = TerminalApi.getExitCode();
        const q3 = PrinterApi.getIsKioskWithPrinter();
        this.setIsWebsocketRunning(true);
        if (typeof localStorage !== 'undefined') {
            this.terminalConnectionObserver.setHarborName(this.rootStore.harbor.name);
            this.printerConnectionObserver.setHarborName(this.rootStore.harbor.name);
        }
        Promise.all([q, q2, q3])
            .then((values) => {
                this.setIsConnected(values[0].data);
                this.setExitCode(values[1].data);
                if (typeof localStorage !== 'undefined') {
                    this.setIsKioskWithPrinter(values[2].data);
                    if (this.isKioskWithPrinter) {
                        PrinterApi.getPrinterStatus().then((value) => {
                            this.setHasPrinter(value.data);
                        });
                    }
                    this.checkPrinterStatus();
                }
            })
            .catch(() => {
                this.setIsConnected(false);
            });
    }

    checkPrinterStatus() {
        if (this.isKioskWithPrinter) {
            setTimeout(() => {
                PrinterApi.getPrinterStatus().then((value) => {
                    this.setHasPrinter(value.data);
                });
                this.checkPrinterStatus();
            }, 1 * 60 * 1000);
        }
    }

    @action
    onDisconnectedStatusReset() {
        this.setIsWebsocketRunning(false);
        this.setIsConnected(false);
    }

    @action
    setIsConnected(value) {
        this.isConnected = value;
        if (this.terminalConnectionObserver) {
            this.terminalConnectionObserver.setIsConnected(value);
        }
    }

    @action
    setIsWebsocketRunning(value) {
        this.isWebsocketRunning = value;
    }

    @action
    setType(value) {
        this.type = value;
    }

    @action
    setHasPrinter(value) {
        this.hasPrinter = value;
        if (this.isKioskWithPrinter && this.printerConnectionObserver) {
            this.printerConnectionObserver.setIsConnected(value);
        }
    }

    @action
    setIsKioskWithPrinter(value) {
        this.isKioskWithPrinter = value;
    }

    @action
    setLocalAddress(value) {
        this.localAddress = value;
    }

    @action
    setExitCode(value) {
        this.exitCode = value;
    }

    @action
    abortPayment() {
        const q = TerminalApi.abortPayment();
        q.then();
    }

    @action
    enableOutdoorKioskMode() {
        this.hasPrinter = false;
        this.isConnected = true;
        this.isWebsocketRunning = true;
    }

    @computed
    get isReady() {
        if (this.type === 'indoor') {
            return this.isConnected && this.isWebsocketRunning;
        }

        if (this.type === 'outdoor') {
            return this.isWebsocketRunning;
        }

        return false;
    }

    @computed
    get isIndoor() {
        return this.type === 'indoor';
    }

    @computed
    get isOutdoor() {
        return this.type === 'outdoor';
    }
}

export default TerminalStore;
