import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { translate } from 'i18n/i18n';
import FormNav from 'app/components/form/FormNav';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

@translate()
@withRouter
@inject('store')
@observer
class ShopHackNav extends Component {
    isTabValid(tabNumber) {
        const activeTab = this.props.tabTitles[tabNumber - 1];
        const { form } = this.props.store.shopHack;
        const { order } = form;

        let doHaveOrderedItem = false;
        order.map((item)=>{
            if (item && item.quantity > 0) {
                doHaveOrderedItem = true;
            }
            return true;
        })

        switch (activeTab) {
            case 'COMMON.SHOP_PRODUCTS':
                return doHaveOrderedItem;
            case 'COMMON.SHOP_OVERVIEW':
                return doHaveOrderedItem;
            default: return doHaveOrderedItem;
        }
    }

    render() {
        const { location: { pathname }, store: { shopHack }, tabTitles } = this.props;
        const tabNumber = Number(pathname.charAt(pathname.length - 1));

        return (
            <FormNav tabTitles={tabTitles}
                     tabNumber={tabNumber}
                     isTabValid={this.isTabValid(tabNumber)}
                     prePaymentPromise={shopHack.createShopHackPaymentPromise}
                     moduleStore={shopHack}
            />
        )
    }
}

ShopHackNav.propTypes = {
    tabTitles: PropTypes.array.isRequired
};

export default ShopHackNav;
