import { action } from 'mobx';
import i18n from 'i18n/i18n';
import ShopApi from 'api/ShopApi';
import AbstractModulePaymentStore from 'store/PaymentStore/modules/AbstractModulePaymentStore';
import PrinterApi from 'api/PrinterApi';
import CraneBookingReceiptModel from '../../PrintModels/CraneBookingReceiptModel';

class CraneBookingPaymentStore extends AbstractModulePaymentStore {

    constructor(paymentStore) {
        super(paymentStore);
        this.payment = paymentStore;
    }

    @action
    finalizePayment() {
        ShopApi.postPayment(this.generateDataForRequest()).then();
        this.generatePrint();
        this.printReceipt();
    }

    @action
    generateDataForRequest() {
        return {
            harbor: this.payment.rootStore.harbor.id,
            data: {
                total: this.payment.rootStore.craneBooking.form.price,
            }
        }
    }

    getCorrectType(type) {
        switch (type) {
            case 0:
                return 'Op til 5 tons uden mast'
            case 1:
                return 'Op til 5 tons med mast'
            case 2:
                return '5-10 tons uden mast'
            case 3:
                return '5-10 tons med mast'
            default:
                return 'Error'
        }
    }

    @action
    generatePrint() {
        this.receiptPrint = new CraneBookingReceiptModel().build(this.payment.rootStore, i18n);
    }

    @action
    printReceipt() {
        if (this.payment.rootStore.terminal.hasPrinter) {
            PrinterApi.printShopReceipt(this.receiptPrint).then();
            this.setIsReceiptPrinted(true);
        }
    }

    @action
    async extractTotalPayment() {
        this.setPaymentTotalIndoor(this.payment.rootStore.craneBooking.form.price);
        this.setPaymentTotalOutdoor(this.payment.rootStore.craneBooking.form.price * 100);
    }
}

export default CraneBookingPaymentStore;
