import { action } from 'mobx';
import i18n from 'i18n/i18n';
import ShopApi from 'api/ShopApi';
import AbstractModulePaymentStore from 'store/PaymentStore/modules/AbstractModulePaymentStore';
import PrinterApi from 'api/PrinterApi';
import ShopHackReceiptModel from '../../PrintModels/ShopHackReceiptModel';

class ShopHackPaymentStore extends AbstractModulePaymentStore {

    constructor(paymentStore) {
        super(paymentStore);
        this.payment = paymentStore;
    }

    @action
    finalizePayment() {
        ShopApi.postPayment(this.generateDataForRequest()).then();
        this.generatePrint();
        this.printReceipt();
    }

    @action
    generateDataForRequest() {
        const products = this.payment.rootStore.shopHack.form.order
            .map((item)=> item.quantity > 0 ? `${item.shortName ? item.shortName : item.name} x ${item.quantity} | ${item.price}\n` : null)
            .toString()
            .replace(/,/g, '');
        return {
            harbor: this.payment.rootStore.harbor.id,
            data: {
                products: products,
                total: this.payment.rootStore.shopHack.form.totalPrice,
            }
        }
    }

    @action
    generatePrint() {
        this.receiptPrint = new ShopHackReceiptModel().build(this.payment.rootStore, i18n);
    }

    @action
    printReceipt() {
        if (this.payment.rootStore.terminal.hasPrinter) {
            PrinterApi.printShopReceipt(this.receiptPrint).then();
            this.setIsReceiptPrinted(true);
        }
    }

    @action
    async extractTotalPayment() {
        this.setPaymentTotalIndoor(this.payment.rootStore.shopHack.form.totalPrice);
        this.setPaymentTotalOutdoor(this.payment.rootStore.shopHack.form.totalPrice * 100);
    }
}

export default ShopHackPaymentStore;
