import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { translate } from 'i18n/i18n';
import FormNav from 'app/components/form/FormNav';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import FormValidator from 'store/validators/FormValidator';

@translate()
@withRouter
@inject('store')
@observer
class SlipwayNav extends Component {
    isTabValid(tabNumber) {
        const activeTab = this.props.tabTitles[tabNumber - 1];
        const { form, hiddenFields } = this.props.store.slipway;
        const { isNationalityHidden, isFirstNameHidden, isLastNameHidden, isEmailHidden  } = hiddenFields;
        const { camperLot, country, firstName, lastName, phone, email  } = form;

        const { empty, isEmailValidForDisplay, isPhoneValidForDisplay } = new FormValidator();

        switch (activeTab) {
            case 'COMMON.STEPS_LOCATION':
                return !empty(camperLot);
            case 'COMMON.INFO_TITLE':
                return !isNationalityHidden && !empty(country);
            case 'COMMON.STEPS_CONTACT_INFORMATION':
                {
                    let isValid = true;
                    if (!isFirstNameHidden && empty(firstName)) {isValid = false}
                    if (!isLastNameHidden && empty(lastName)) {isValid = false}
                    if (!isEmailHidden && !isEmailValidForDisplay(email)) {isValid = false}
                    if (!isPhoneValidForDisplay(phone)) {isValid = false}

                    return isValid;
                }
            default: return true;
        }
    }

    render() {
        const { location: { pathname }, store: { slipway } } = this.props;
        const tabNumber = Number(pathname.charAt(pathname.length - 1));

        return (
            <FormNav tabTitles={this.props.tabTitles}
                     tabNumber={tabNumber}
                     isTabValid={this.isTabValid(tabNumber)}
                     prePaymentPromise={slipway.createSlipwayReservationPromise}
                     moduleStore={slipway}
            />
        )
    }
}

SlipwayNav.propTypes = {
    tabTitles: PropTypes.array.isRequired
};

export default SlipwayNav;
