import React, { Component, Fragment } from 'react';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { faLongArrowAltDown, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from 'app/components/Loader';
import PaymentSuccessTitle from 'app/modules/payment/components/PaymentSuccessTitle';
import PaymentSuccessNavBar from 'app/modules/payment/components/PaymentSuccessNavBar';
import PaymentReceiptCodeReminder from 'app/modules/payment/components/PaymentReceiptCodeReminder';
import AccessCodeReminder from '../../components/AccessCodeReminder';

@translate()
@withRouter
@inject('store')
@observer
class BookingPaymentSuccess extends Component {
    async componentDidMount() {
        const { payment: { booking } } = this.props.store;
        const { params: { orderId } } = this.props.match;

        await booking.fetchPrinterStatus();
        booking.finalizePayment(orderId)
    }

    render() {
        const { t, store: { harbor, terminal , payment: { booking } } } = this.props;
        const bookingRoot = booking.getBookingRoot();
        const bookingToUse = bookingRoot.rootStore.booking.bookingFromApi;

        if (!bookingToUse) {
            return <Loader fullScreen={true} />;
        }

        return (
            <div className="payment-block">
                <PaymentSuccessTitle />
                {harbor.successBookingTranslationBySelectedLocale && (
                    <div style={{ width: '30%' }} className="review-box">
                        <p style={{ marginBottom: 0, marginTop: 10 }}
                           dangerouslySetInnerHTML={{ __html: harbor.successBookingTranslationBySelectedLocale }} />
                    </div>
                )}
                <div className="review-box">
                    <Fragment>
                        <div className="payment-overview">
                            <div className="payment-dates-title-container">
                                <span className="payment-dates-titles">{t('COMMON.ARRIVAL')}</span>
                                <span className="payment-dates-titles">{t('COMMON.DEPARTURE')}</span>
                            </div>
                            <div className="payment-date-container">
                                <span className="payment-date">
                                    {bookingToUse.dateFrom}
                                </span>
                                <span>
                                    <FontAwesomeIcon className="payment-arrow-right"
                                                     icon={faLongArrowAltRight} />
                                </span>
                                <span className="payment-date">
                                {bookingToUse.dateTo}
                            </span>
                            </div>
                        </div>

                        <div>
                            {bookingToUse.dock &&
                                <div id={'location'} className="payment-data-container">
                                    <div className="payment-review-label">{t('COMMON.LOCATION')}:</div>
                                    <div className="payment-review-text-round">
                                        {bookingToUse.dock.name}
                                    </div>
                                    {bookingToUse.berth &&
                                    <Fragment>
                                        <span className="payment-score">–</span>
                                        <div className="payment-review-text-round">
                                            {bookingToUse.berth.name}
                                        </div>
                                    </Fragment>
                                    }
                                </div>
                            }
                        </div>
                    </Fragment>
                </div>

                <PaymentReceiptCodeReminder model={bookingToUse} />

                {terminal.hasPrinter &&
                    <div className="payment-sticker-message">
                        <FontAwesomeIcon className="payment-arrow-down" icon={faLongArrowAltDown} />
                        <div>{t('COMMON.STICKER_COLLECT_REMINDER')}</div>
                        <FontAwesomeIcon className="payment-arrow-down" icon={faLongArrowAltDown} />
                    </div>
                }

                {booking.hasAccessCode && (
                    <AccessCodeReminder accessCode={booking.accessCode} />
                )}

                <PaymentSuccessNavBar />
            </div>
        )
    }
}

export default BookingPaymentSuccess;
