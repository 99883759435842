import { observable } from 'mobx';

class CreditPaymentViewModel {
    @observable email;
    @observable phone;
    @observable amount;

    constructor(credit, amount) {
        this.email = credit.email;
        this.phone = credit.phone ? credit.phone : '+';
        this.amount = amount;
    }
}

export default CreditPaymentViewModel;
