import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import FormValueDisplay from 'app/components/form/FormValueDisplay';
import CountryModal from 'app/components/form/modal/CountryModal';

@inject('store')
@observer
class CountryFormInput extends Component {
    constructor(props) {
        super(props);
        this.state = { open: false };
    }

    triggerOpen() {
        this.setState({ open: true }, () => this.setState({ open: false }));
    }

    render() {
        const { moduleStore, formFieldName, setTitle, changeTitle } = this.props;
        const value = this.props.value ? this.props.value : moduleStore.form[formFieldName];

        return (
            <div>
                <div className="form-input" onClick={() => this.triggerOpen()}>
                    <FormValueDisplay value={moduleStore.form[formFieldName]} {...this.props} isCountry />

                    <button type="button" className={`modal-form-btn ${!value ? 'red-border' : ''}`}>
                        {!moduleStore.form[formFieldName] ? setTitle : changeTitle}
                    </button>
                </div>

                <CountryModal {...this.props} closeOnOutClick={false} triggerOpen={this.state.open} />
            </div>
        )
    }
}

CountryFormInput.propTypes = {
    isRequired: PropTypes.bool.isRequired,
    isValid: PropTypes.any,
    moduleStore: PropTypes.any.isRequired,
    formFieldName: PropTypes.string.isRequired,
    displayValue: PropTypes.any,
    setTitle: PropTypes.any.isRequired,
    changeTitle: PropTypes.any.isRequired,
    title: PropTypes.any.isRequired,
    hideOptionality: PropTypes.bool,
    preselectPhone: PropTypes.bool,
};

export default CountryFormInput;
