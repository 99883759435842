import { action } from 'mobx';
import HarborApi from 'api/HarborApi';
import HarborStoreModel from 'store/models/HarborStoreModel';

class HarborStore extends HarborStoreModel {
    @action
    requestHarbor(id) {
        this.setLoading(true);

        const q = HarborApi.getHarbor(id);
        const q2 = HarborApi.getHarbakioskSettings(id);
        const q3 = HarborApi.getGuestSetting(id);
        const q4 = HarborApi.getBookingSetting(id);

        Promise.all([q, q2, q3, q4])
            .then((values) => {
                this.setHarbor(values[0].data);
                this.setHarbakioskSettings(values[1].data);
                this.findCrewMemberFeeTypeFromSetting(values[2].data);
                this.setBookingPricingType(values[3].data);
                this.setLoading(false);
            })
            .catch(() => {
                this.setId(null);
                this.setLoading(false);
            });
    }

    @action.bound
    findCrewMemberFeeTypeFromSetting(settings) {
        settings.forEach((setting) => {
            if (setting.settingKey === 'crewMembersFeeType' &&
                setting.settingValue === '1'
            ) {
                settings.forEach((settingTwo) => {
                    if (settingTwo.settingKey === 'crewMembersFree') {
                        this.setCrewMembersLimit(settingTwo.settingValue);
                        this.setIsCrewMemberCorrectType(true);
                    }
                });
            }
        });
    }

    @action
    subtractPaperCount() {
        const q = HarborApi.subtractPaperCount(this.id);

        q.then();
    }
}

export default HarborStore;
