import { action, observable } from 'mobx';
import PaymentApi from 'api/PaymentApi';
import BookingPaymentStore from 'store/PaymentStore/modules/Booking/BookingPaymentStore';
import CamperPaymentStore from 'store/PaymentStore/modules/Camper/CamperPaymentStore';
import SlipwayPaymentStore from 'store/PaymentStore/modules/Slipway/SlipwayPaymentStore';
import CreditPaymentStore from 'store/PaymentStore/modules/Credit/CreditPaymentStore';
import ShopPaymentStore from 'store/PaymentStore/modules/Shop/ShopPaymentStore';
import CraneBookingPaymentStore from './modules/CraneBooking/CraneBookingPaymentStore';
import ShopHackPaymentStore from './modules/ShopHack/ShopHackPaymentStore';

class PaymentStore {
    @observable paymentType;
    @observable loading = true;

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @action
    fetchPaymentType(orderId) {
        if (orderId === 'shop') {
            this.setPaymentType('shop');
            this.assignModuleStoreByPaymentType();
            this.setLoading(false);
        } else if (orderId === 'craneBooking') {
            this.setPaymentType('craneBooking');
            this.assignModuleStoreByPaymentType();
            this.setLoading(false);
        } else if (orderId === 'shopHack') {
            this.setPaymentType('shopHack');
            this.assignModuleStoreByPaymentType();
            this.setLoading(false);
        } else {
            PaymentApi.getPaymentType(orderId)
                .then((type) => {
                    this.setPaymentType(type.data);
                    this.assignModuleStoreByPaymentType();
                    this.setLoading(false);
                })
        }
    }

    @action
    assignModuleStoreByPaymentType() {
        if (this.paymentType === 'booking') {
            this[this.paymentType] = new BookingPaymentStore(this);
        }

        if (this.paymentType === 'credit') {
            this[this.paymentType] = new CreditPaymentStore(this);
        }

        if (this.paymentType === 'camper') {
            this[this.paymentType] = new CamperPaymentStore(this);
        }

        if (this.paymentType === 'slipway') {
            this[this.paymentType] = new SlipwayPaymentStore(this);
        }

        if (this.paymentType === 'shop') {
            this[this.paymentType] = new ShopPaymentStore(this);
        }

        if (this.paymentType === 'craneBooking') {
            this[this.paymentType] = new CraneBookingPaymentStore(this);
        }

        if (this.paymentType === 'shopHack') {
            this[this.paymentType] = new ShopHackPaymentStore(this);
        }
    }

    @action
    setPaymentType(value: string) {
        this.paymentType = value;
    }

    @action
    setLoading(value) {
        this.loading = value;
    }
}

export default PaymentStore;
