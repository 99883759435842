import React, {Component} from 'react';
import Modal from 'react-modal';
import KeyboardedInput from 'react-touch-screen-keyboard';
import PropTypes from 'prop-types'
import {translate} from 'i18n/i18n';
import {inject, observer} from 'mobx-react';
import FormValidator from 'store/validators/FormValidator';
import {isValidPhoneNumber, AsYouType} from 'libphonenumber-js';

@translate()
@inject('store')
@observer
class TextInputModal extends Component {
    constructor() {
        super();
        this.state = {
            open: false,
            validPhoneNumber: false,
            preselectedPhone: '',
        }
    }

    componentDidMount() {
        if (this.props.isPhone &&
            (!this.props.moduleStore.form.phone || this.props.moduleStore.form.phone.length < 5) &&
            !this.props.moduleStore.phonePreselected) {
            this.preselectPhoneByLocale();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {store: {terminal: {isIndoor}}} = this.props;
        if (this.props.triggerOpen && (prevProps.value !== this.props.triggerOpen)) {
            if (isIndoor) {
                if (this.state.preselectedPhone && !this.props.moduleStore.form.phone) {
                    this.props.moduleStore.setFormValue(this.props.formFieldName, this.state.preselectedPhone);
                } else if (!this.props.moduleStore.form.phone) {
                    this.preselectPhoneByLocale();
                }
            }
            this.setState({open: true});
        }
    }

    preselectPhoneByLocale() {
        let locale = this.props.store.locale.locale;
        if (locale === 'en-US') {
            locale = 'GB'
        }
        this.props.moduleStore.preselectPhone(locale.toUpperCase());
    }

    closeModal() {
        const {store: {terminal: {isIndoor}}} = this.props;

        if (isIndoor) {
            if (!this.state.validPhoneNumber && this.props.isPhone) {
                const asYouType = new AsYouType();
                asYouType.input(this.props.moduleStore.form[this.props.formFieldName]);
                const countryCode = asYouType.getCallingCode();
                if (countryCode) {
                    this.setState({preselectedPhone: `+${countryCode}`})
                } else {
                    let locale = this.props.store.locale.locale;
                    if (locale === 'en-US') {
                        locale = 'GB'
                    }
                    const number = this.props.moduleStore.getCountryCodeByLocale(locale.toUpperCase());
                    this.setState({preselectedPhone: number})
                }

                this.props.moduleStore.setFormValue(this.props.formFieldName, null);
            }
        }

        this.setState({open: false});
    }

    initialFocus(ref) {
        const {open} = this.state;

        if (open) {
            if (!this.inputRef && ref) {
                ref.focus();
            }
        }

        this.inputRef = ref;
    }

    isNewValueValid(newValue) {
        const {isNumber, isEmail, isPhone} = this.props;
        const validator = new FormValidator();

        if (isNumber) {
            return validator.isValidNumberForChange(newValue);
        }

        if (isEmail) {
            return validator.isEmailValidForChange(newValue);
        }

        if (isPhone) {
            return validator.isPhoneValidForChange(newValue);
        }

        return true;
    }

    getKeyboardLayoutByType() {
        const {isNumber, isEmail, isPhone} = this.props;

        if (isNumber) {
            return numberKeyboardLayout;
        }

        if (isEmail) {
            return emailKeyboardLayout;
        }

        if (isPhone) {
            return phoneKeyboardLayout;
        }

        return keyboardLayout;
    }

    render() {
        const {
            t, setTitle, formFieldName, moduleStore, onSave,
            closeOnOutClick, isPhone, isNumber, isAlwaysUppercase, isEmail, redInputBorder, actCodeWarning
        } = this.props;
        const {store: {harbor, terminal: {isIndoor}}} = this.props;
        const {open, validPhoneNumber} = this.state;
        const value = moduleStore.form[formFieldName];

        const onChange = () => (newValue) => {
            if (this.isNewValueValid(newValue)) {
                if (newValue === '') {
                    newValue = null;
                }

                if (isAlwaysUppercase && newValue) {
                    newValue = newValue.toUpperCase();
                }

                moduleStore.setFormValue(formFieldName, newValue);

                if (formFieldName === 'phone') {
                    moduleStore.setPhonePreselected(false);
                    if (isIndoor) {
                        const asYouType = new AsYouType();
                        asYouType.input(newValue);
                        let locale = asYouType.getCountry();

                        if (newValue.includes('+44')) {
                            locale = 'GB';
                        }

                        this.setState({validPhoneNumber: isValidPhoneNumber(newValue, locale)})
                    }
                }
            }
        };

        const save = () => () => {
            if (this.props.onClose) {
                this.props.onClose();
            }

            this.inputRef = null;
            setTimeout(() => {
                if (onSave) {
                    onSave();
                }
                this.closeModal();
            });
        };

        const close = () => () => {
            if (this.props.onClose) {
                this.props.onClose();
            }

            this.inputRef = null;
            setTimeout(() => {
                this.closeModal()
            });
        };

        const keyboard = this.getKeyboardLayoutByType();

        return (
            <Modal isOpen={open} onRequestClose={close()} shouldCloseOnOverlayClick={closeOnOutClick}
                   className="form-modal">
                <div className="input-modal-container">
                    <div className="modal-message-text">{setTitle}</div>

                    <label htmlFor="inp"
                           className={`inp center modal-search-keyboard 
                           ${(isPhone || isNumber) && 'float-input'} ${redInputBorder ? 'red-input-border' : ''}`}>
                        <KeyboardedInput enabled
                                         ref={ref => this.initialFocus(ref)}
                                         onBlur={() => {
                                             if (this.inputRef) {
                                                 this.inputRef.focus()
                                             }
                                         }}
                                         onChange={onChange()}
                                         value={value ? value : ''}
                                         isDraggable={false}
                                         defaultKeyboard={keyboard}
                                         isFirstLetterUppercase={!isEmail}/>
                    </label>
                    {actCodeWarning && harbor.harbakioskSettings.isCreditEnabled && (
                        <p className='color-red'>{t('COMMON.ACCESS_CODE_PHONE_EMAIL_WARNING')}</p>
                    )}
                    <div style={{display: 'flex', justifyContent: 'space-evenly', width: '100%'}}>
                        {isPhone && (
                            <button
                                type="button"
                                className="input-modal-save btn btn-light"
                                onClick={() => this.closeModal()}
                            >
                                {t('COMMON.CLOSE')}
                            </button>
                        )}
                        <button
                            type="button"
                            className="input-modal-save btn btn-light"
                            onClick={save()}
                            // disabled={isPhone && !validPhoneNumber}
                            disabled={isIndoor && isPhone && !validPhoneNumber}
                        >
                            {t('COMMON.SAVE')}
                        </button>
                    </div>

                </div>
            </Modal>
        )
    }
}

TextInputModal.propTypes = {
    setTitle: PropTypes.string.isRequired,
    moduleStore: PropTypes.any.isRequired,
    formFieldName: PropTypes.string.isRequired,
    closeOnOutClick: PropTypes.any,
    triggerOpen: PropTypes.any,
    isPhone: PropTypes.any,
    isEmail: PropTypes.any,
    isNumber: PropTypes.any,
    onSave: PropTypes.any,
    onClose: PropTypes.any,
    isAlwaysUppercase: PropTypes.bool,
    redInputBorder: PropTypes.bool,
    actCodeWarning: PropTypes.bool,
};

const phoneKeyboardLayout = [
    ['7', '8', '9'],
    ['4', '5', '6'],
    ['1', '2', '3'],
    ['*bs', '0'],
];

const emailKeyboardLayout = [
    ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'å'],
    ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'æ', 'ø'],
    ['z', 'x', 'c', 'v', 'b', 'n', 'm', '.', '@']
];

const numberKeyboardLayout = [
    ['7', '8', '9'],
    ['4', '5', '6'],
    ['1', '2', '3'],
    ['*bs', '0', '.'],
];

const keyboardLayout = [
    ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'å'],
    ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'æ', 'ø'],
    ['z', 'x', 'c', 'v', 'b', 'n', 'm', '.', '@', '*sh']
];

export default TextInputModal;
