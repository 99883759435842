import React, { Component, Fragment } from 'react';
import { translate } from 'i18n/i18n';
import { Helmet } from 'react-helmet';

@translate()
class PageNotFound extends Component {
    render() {
        const { t } = this.props;
        const url = window.location.href.replace(window.location.pathname, '');

        return (
            <Fragment>
                <Helmet>
                    <title>{t('COMMON.PAGE_NOT_FOUND')}</title>
                </Helmet>
                <div className="notFound">
                    <div className="notFoundContainer">
                        <span className="notFoundText">{t('COMMON.PAGE_NOT_FOUND_ERROR')}</span>
                        <span className="codeSnippet">{url}
                            <span className="codeSnippetBold">/:HARBOR_ID</span>
                        </span>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default PageNotFound;
