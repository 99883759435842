import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import Stomp from 'stompjs';
import SockJS from 'sockjs-client';
import HarbakioskMessage from 'app/dashboard/websocket/HarbakioskMessage';
import { withRouter } from 'react-router-dom';

@withRouter
@inject('store')
@observer
class WebsocketClient extends Component {
    componentDidMount() {
        if (process.env.REACT_APP_KIOSK_TYPE === 'indoor') {
            this.props.store.terminal.setType('indoor');
            this.connect();
        }

        if (process.env.REACT_APP_KIOSK_TYPE === 'outdoor') {
            this.props.store.terminal.setType('outdoor');
            this.props.store.terminal.enableOutdoorKioskMode();
            if (process.env.REACT_APP_ENV !== 'demo') {
                this.connect();
            }
        }
    }

    connect() {
        let Sock = new SockJS('http://localhost:8080/harbakiosk-websocket');
        let stompClient = Stomp.over(Sock);
        stompClient.debug = null;
        stompClient.connect({}, () => this.onConnected(stompClient), () => this.onDisconnected());
    }

    onConnected = (stompClient) => {
        const { terminal } = this.props.store;
        stompClient.subscribe('/topic/harbakiosk/terminals', (message) => this.onMessageReceived(message, stompClient));
        terminal.onConnectedTerminalStatusCheck();
    };

    onDisconnected = () => {
        const { terminal } = this.props.store;
        terminal.onDisconnectedStatusReset();
        setTimeout(() => {
            this.connect();
        }, 5000)
    };

    onMessageReceived = (payload, client) => {
        const message = new HarbakioskMessage(JSON.parse(payload['body']));
        const { type, data } = message;
        const { value, value2 } = data;
        const { store: { terminal, harbor, locale: { locale } } } = this.props;

        if (type === 'HEARTBEAT_CHECK') {
            client.send('/topic/harbakiosk/terminals', {},
                JSON.stringify({ type: 'HEARTBEAT_RESPONSE', data: 'response' }));
        }

        if (process.env.REACT_APP_KIOSK_TYPE === 'indoor') {
            if (type === 'STATUS_TERMINAL') {
                const isConnected = value === 'true';
                terminal.setIsConnected(isConnected);
            }

            if (type === 'REDIRECT_TO_PAY') {
                this.props.history.push(`/${harbor.id}/payment/pay/${value}`)
            }

            if (type === 'REDIRECT_TO_PRINTER_OFFLINE') {
                this.props.history.push(`/${harbor.id}/payment/printer_offline/${value}/${value2}`)
            }

            if (type === 'PAYMENT_SUCCESS') {
                this.props.history.push(`/${harbor.id}/payment/success/${value}/${locale}`)
            }

            if (type === 'PAYMENT_FAILED') {
                this.props.history.push(`/${harbor.id}/payment/failed/${value}/${locale}`)
            }

            if (type === 'PAYMENT_FAILED_DUE_TO_SIGNATURE') {
                this.props.history.push(`/${harbor.id}/payment/failed/${value}/${locale}?signature=true`)
            }

            if (type === 'REDIRECT_TO_ENTER_PIN') {
                this.props.history.push(`/${harbor.id}/payment/enter_pin`)
            }
        }
    };

    render() {
        return (
            <Fragment />
        );
    }
}

export default WebsocketClient;
