import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import touchHereGif from "../../../assets/img/touch_here.gif";

const localeHeaders = {
    'en-US': 'Welcome',
    'da': 'Velkommen',
    'de': 'Wilkommen',
}

const localeTouchHereText = {
    'en-US': 'Click on the flag to resume',
    'da': 'Tryk på flaget for at forsætte',
    'de': 'Drücken Sie bitte auf die Flagge',
}

@inject('store')
@observer
class LanguageSelector extends Component{
    render() {
        const { locale: { locales } } = this.props.store;

        const changeLocale = (locale) => () => {
            this.props.store.locale.setInitialLocale(locale);
        };

        return (
            <div className="language-container">
                <div className="container h-100">
                    <div className="row h-100">
                        {locales.map((locale, index) => {
                            return (
                                <div className='col-sm-4 text-center align-self-center' key={index}>
                                    <button onClick={changeLocale(locale.locale)}
                                            type="button"
                                            className="language-btn btn btn-light">
                                        <img className="language-flag" alt={locale.locale} src={locale.flag} />
                                    </button>
                                    <h1 className="text-harba-blue font-weight-bold mt-3">
                                        {localeHeaders[locale.locale]}
                                    </h1>
                                    <p className="text-harba-blue">{localeTouchHereText[locale.locale]}</p>
                                </div>
                            )
                        })}
                        <img className="touch-here-image" src={touchHereGif} alt="Touch here gif" />
                    </div>
                </div>
            </div>
        );
    }
}

export default LanguageSelector;
