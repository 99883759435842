import React from 'react';
import PropTypes from 'prop-types';

const FormCountryOverview = ({ label, value }) => {
    if (!value) {return null}
    return (
        <div className="overview-block">
            <div className="overview-label">{label}:</div>
            <div className="overview-flag-box">
                <span className={`overview-flag flag-icon flag-icon-${value.toLowerCase()}`}> </span>
            </div>
        </div>
    );
};

FormCountryOverview.propTypes = {
    label: PropTypes.any.isRequired,
    value: PropTypes.any
};

export default FormCountryOverview;
