import React, { Component, Fragment } from 'react';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import PaymentFailedNavBar from 'app/modules/payment/components/PaymentFailedNavBar';
import PaymentPayAgainForm from 'app/modules/payment/components/PaymentPayAgainForm';

@translate()
@withRouter
@inject('store')
@observer
class PaymentFailedForm extends Component {
    render() {
        const extractSearchParamFromUrl = ( name, url ) => {
            name = name.replace(/[\\[]/,'\\[').replace(/[\]]/,'\\]');
            var regexS = '[\\?&]'+name+'=([^&#]*)';
            var regex = new RegExp( regexS );
            var results = regex.exec( url );
            return results == null ? null : results[1];
        }

        const { t, total } = this.props;
        const isSignaturePayment = extractSearchParamFromUrl('signature', this.props.location.search) === 'true';

        return (
            <Fragment>
                <div className="payment-block">
                    <div className="message-failed-container">
                        <div className="failed-message-text">
                            {t('COMMON.PAYMENT_FAILED_MESSAGE')}
                        </div>
                    </div>

                    {isSignaturePayment &&
                        <div className="signature-explain">
                            {t('COMMON.SIGNATURE_PAYMENT_SUPPORT')}
                        </div>
                    }

                    <div className="failed-action-container">
                        <PaymentPayAgainForm total={total} />
                        <PaymentFailedNavBar />
                    </div>
                </div>

            </Fragment>
        )
    }
}

export default PaymentFailedForm;
