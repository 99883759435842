import { action } from 'mobx';
import i18n from 'i18n/i18n';
import ShopApi from 'api/ShopApi';
import ShopReceiptModel from 'store/PaymentStore/PrintModels/ShopReceiptModel';
import AbstractModulePaymentStore from 'store/PaymentStore/modules/AbstractModulePaymentStore';
import PrinterApi from 'api/PrinterApi';

class ShopPaymentStore extends AbstractModulePaymentStore {

    constructor(paymentStore) {
        super(paymentStore);
        this.payment = paymentStore;
    }

    @action
    finalizePayment() {
        try {
            ShopApi.postPayment(this.generateDataForRequest());
            this.generatePrint();
            this.printReceipt();
        } catch (err) {
            throw new Error(err);
        }
    }

    @action
    generateDataForRequest() {
        return {
            harbor: this.payment.rootStore.harbor.id,
            data: {
                duration: this.payment.rootStore.shop.durationNumber,
                people: this.payment.rootStore.shop.peopleNumber,
                tent: this.payment.rootStore.shop.servicesEnabled.tent,
                shelter: this.payment.rootStore.shop.servicesEnabled.shelter,
                shower: this.payment.rootStore.shop.facilitiesAmount.shower,
                washer: this.payment.rootStore.shop.facilitiesAmount.washer,
                dryer: this.payment.rootStore.shop.facilitiesAmount.dryer,
                total: this.payment.rootStore.shop.total
            }
        }
    }

    @action
    generatePrint() {
        this.receiptPrint = new ShopReceiptModel().build(this.payment.rootStore, i18n);
    }

    @action
    printReceipt() {
        if (this.payment.rootStore.terminal.hasPrinter) {
            PrinterApi.printShopReceipt(this.receiptPrint)
                .then(() => {
                    this.setIsReceiptPrinted(true);
                })
                .catch((err) => {
                    throw new Error(err);
                })
        }
    }
}

export default ShopPaymentStore;
