import React, { Component } from 'react';
import { translate } from 'i18n/i18n';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Loader from 'app/components/Loader';
import PaymentSuccessTitle from 'app/modules/payment/components/PaymentSuccessTitle';
import PaymentSuccessNavBar from 'app/modules/payment/components/PaymentSuccessNavBar';
import AccessCodeReminder from '../../components/AccessCodeReminder';

@translate()
@withRouter
@inject('store')
@observer
class SlipwayPaymentSuccess extends Component {
    async componentDidMount() {
        const { payment: { slipway } } = this.props.store;
        const { params: { orderId } } = this.props.match;

        await slipway.fetchPrinterStatus();
        slipway.finalizePayment(orderId);
    }

    render() {
        const { store: { payment: { slipway } } } = this.props;

        const slipwayRoot = slipway.getSlipwayRoot();
        const slipwayToUse = slipwayRoot.rootStore.slipway.slipwayFromApi;

        if (!slipwayToUse) {
            return <Loader fullScreen={true} />;
        }

        return (
            <div className="payment-block">
                <PaymentSuccessTitle />

                <div className="payment-overview">
                    <div className="payment-dates-title-container">
                    </div>
                </div>

                {slipway.hasAccessCode && (
                    <AccessCodeReminder accessCode={slipway.accessCode} />
                )}

                <PaymentSuccessNavBar />
            </div>
        )
    }
}

export default SlipwayPaymentSuccess;
