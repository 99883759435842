import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Images:
import actImage from '../../../assets/img/modules/act.png';
import autocamperImage from '../../../assets/img/modules/autocamper.png';
import craneImage from '../../../assets/img/modules/crane.png';
import servicesEjerslevImage from '../../../assets/img/modules/services-ejerslev.png';
import shopHackImage from '../../../assets/img/modules/shop-hack.png';
import slipwayImage from '../../../assets/img/modules/slipway.png';
import bookingImage from '../../../assets/img/modules/booking.png';

const SelectionPage = ({ store: { harbor, camper, slipway, booking, terminal } }) => {
    const harborName = harbor.name
    const isDisabled = !terminal.isReady;
    const history = useHistory();
    const { pathname } = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        camper.resetDates();
        slipway.resetDates();
        booking.resetDates();
        booking.updateDates();
    }, [booking, camper, slipway])
    return (
        <div className="selection-container">
            <div className="selection-title-container">
                <span className="harbor-name">{t('COMMON.WELCOME_TO', { harborName })}</span>
            </div>
            <div className="vertical-centered">
                <div className="selection-btn-container">
                    <button type="button"
                            disabled={isDisabled}
                            className="btn selection-btn"
                            onClick={() => history.push(pathname + '/booking')}>
                        <div className="selection-img-container">
                            <img src={bookingImage} alt="booking" />
                        </div>
                        <div className="selection-text-container">
                            {t('COMMON.PAY_FOR_A_SPOT')}
                        </div>
                    </button>
                </div>
                {harbor.harbakioskSettings.isCreditEnabled &&
                    <div className="selection-btn-container">
                        <button type="button"
                                disabled={isDisabled}
                                className="btn selection-btn"
                                onClick={() => history.push(pathname + '/credit')}>
                            <div className="selection-img-container">
                                <img src={actImage} alt="act" />
                            </div>
                            <div className="selection-text-container">
                                {t('COMMON.BUY_SERVICES')}
                            </div>
                        </button>
                    </div>
                }
                {harbor.harbakioskSettings.isCamperEnabled &&
                    <div className="selection-btn-container">
                        <button type="button"
                                disabled={isDisabled}
                                className="btn selection-btn"
                                onClick={() => history.push(pathname + '/camper')}>
                            <div className="selection-img-container">
                                <img src={autocamperImage} alt="autocamper" />
                            </div>
                            <div className="selection-text-container">
                                {t('COMMON.PAY_FOR_AUTOCAMPER')}
                            </div>
                        </button>
                    </div>
                }
                {harbor.harbakioskSettings.isSlipwayEnabled &&
                    <div className="selection-btn-container">
                        <button type="button"
                                disabled={isDisabled}
                                className="btn selection-btn"
                                onClick={() => history.push(pathname + '/slipway')}>
                            <div className="selection-img-container">
                                <img src={slipwayImage} alt="slipway" />
                            </div>
                            <div className="selection-text-container">
                                {t('COMMON.PAY_FOR_SLIPWAY')}
                            </div>
                        </button>
                    </div>
                }
                {harbor.harbakioskSettings.shopEnabled &&
                    <div className="selection-btn-container">
                        <button type="button"
                                disabled={isDisabled}
                                className="btn selection-btn"
                                onClick={() => history.push(pathname + '/shop')}>
                            <div className="selection-img-container">
                                <img src={servicesEjerslevImage} alt="services" />
                            </div>
                            <div className="selection-text-container">
                                {t('COMMON_PAY_FOR_SERVICE')}
                            </div>
                        </button>
                    </div>
                }
                {harbor.harbakioskSettings.craneBookingEnabled &&
                    <div className="selection-btn-container">
                        <button type="button"
                                disabled={isDisabled}
                                className="btn selection-btn"
                                onClick={() => history.push(pathname + '/crane')}>
                            <div className="selection-img-container">
                                <img src={craneImage} alt="craneImage" />
                            </div>
                            <div className="selection-text-container">
                                {t('COMMON_CRANE_BOOKING')}
                            </div>
                        </button>
                    </div>
                }
                {harbor.harbakioskSettings.actDoorOnMenuEnabled && harbor.harbakioskSettings.actDoor &&
                    <div className="selection-btn-container">
                        <button type="button"
                                disabled={isDisabled}
                                className="btn selection-btn"
                                onClick={() => history.push(pathname + '/credit?slipway=true')}>
                            <div className="selection-img-container">
                                <img src={slipwayImage} alt="slipway" />
                            </div>
                            <div className="selection-text-container">
                                {t('COMMON.PAY_FOR_SLIPWAY')}
                            </div>
                        </button>
                    </div>
                }
                {harbor.harbakioskSettings.shopHackEnabled && (
                    <div className="selection-btn-container">
                        <button type="button"
                                disabled={isDisabled}
                                className="btn selection-btn"
                                onClick={() => history.push(pathname + '/shopHack')}>
                            <div className="selection-img-container">
                                <img src={shopHackImage} alt="shopHack" />
                            </div>
                            <div className="selection-text-container">
                                {t('COMMON.OTHER_SERVICES')}
                            </div>
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default inject('store')(observer(SelectionPage));
