import React, { Component, Fragment } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import PaymentFailed from 'app/modules/payment/PaymentFailed';
import PaymentSuccess from 'app/modules/payment/PaymentSuccess';
import 'scss/modules/payment.scss';
import PaymentPay from 'app/modules/payment/components/PaymentPay';
import PaymentPrinterOffline from 'app/modules/payment/components/PaymentPrinterOffline';
import PaymentEnterPin from 'app/modules/payment/components/PaymentEnterPin';
import { inject, observer } from 'mobx-react';

@withRouter
@inject('store')
@observer
class Payment extends Component {
    render() {
        return (
            <Fragment>
                <div className="payment-container">
                    <Switch>
                        {/* Pre payment screens*/}
                        <Route path="/:harborId/payment/printer_offline/:orderId/:amount"
                               render={() => <PaymentPrinterOffline />} />
                        {/* Payment dummy page: */}
                        {/* <Route path="/:harborId/payment" render={() => <PaymentPay />} /> */}
                        <Route path="/:harborId/payment/pay/:amount" render={() => <PaymentPay />} />
                        <Route path="/:harborId/payment/enter_pin/" render={() => <PaymentEnterPin />} />

                        {/* Post payment screens*/}
                        <Route path="/:harborId/payment/failed/:orderId/:locale" render={() => <PaymentFailed />} />
                        <Route path="/:harborId/payment/aborted/:orderId/:locale" render={() => <PaymentFailed />} />
                        <Route path="/:harborId/payment/success/:orderId/:locale" render={() => <PaymentSuccess />} />

                        <Route render={() => (<Redirect to="/" />)} />
                    </Switch>
                </div>
            </Fragment>
        );
    }
}

export default Payment;
