import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';

@inject('store')
@observer
class TestAreaComponent extends Component {
    componentDidMount() {
        this.props.store.testArea.getTestAreaStatus();
    }

    render() {
        const { testArea, terminal } = this.props.store;
        const version = process.env.REACT_APP_VERSION;

        return (
            <Fragment>
                {terminal.hasPrinter &&
                    <div className="row test-row">
                        <div className="col-md-4 test-col">
                            <input className="test-btn form-btn-full" type="button"
                                   onClick={() => testArea.printTestBookingReceipt()}
                                   value={'Print booking receipt'} />
                        </div>
                        <div className="col-md-4 test-col">
                            <input className="test-btn form-btn-full" type="button"
                                   onClick={() => testArea.printTestBookingSticker()}
                                   value={'Print booking sticker'} />
                        </div>
                        <div className="col-md-4 test-col">
                            <input className="test-btn form-btn-full" type="button"
                                   onClick={() => testArea.printTestCreditReceipt()}
                                   value={'Print ACT receipt'} />
                        </div>
                    </div>
                }

                {terminal.hasPrinter &&
                    <div className="row test-row">
                        <div className="col-md-4 test-col">
                            <input className="test-btn form-btn-full" type="button"
                                   onClick={() => testArea.printTestCamperReceipt()}
                                   value={'Print Camper receipt'} />
                        </div>

                        <div className="col-md-4 test-col">
                            <input className="test-btn form-btn-full" type="button"
                                   onClick={() => testArea.printTestCamperStickerSample()}
                                   value={'Print Camper sticker'} />
                        </div>

                        <div className="col-md-4 test-col">
                            <input className="test-btn form-btn-full" type="button"
                                   onClick={() => testArea.printTestPaymentFailed()}
                                   value={'Print Payment failed'} />
                        </div>

                        <div className="col-md-4 test-col">
                            <input className="test-btn form-btn-full" type="button"
                                   onClick={() => testArea.printTestCraneBookingReceipt()}
                                   value={'Print Crane Booking receipt'} />
                        </div>

                        <div className="col-md-4 test-col">
                            <input className="test-btn form-btn-full" type="button"
                                   onClick={() => testArea.printTestShopHackReceipt()}
                                   value={'Print Shop Hack receipt'} />
                        </div>
                    </div>
                }

                {terminal.type === 'indoor' &&
                    <div className="row test-row">
                        <div className="col-md-4 test-col">
                            <input className="test-btn form-btn-full" type="button"
                                   onClick={() => testArea.startTestReconciliation()}
                                   value={'Do reconciliation'} />
                        </div>
                    </div>
                }

                {terminal.localAddress &&
                    <div className="row test-row">
                        <div className="col-md-12 test-col">
                            <div className="test-address">{terminal.localAddress}</div>
                        </div>
                    </div>
                }

                {version &&
                    <div className="row test-row">
                        <div className="col-md-12 test-col">
                            <div className="version">Version: {version}</div>
                        </div>
                    </div>
                }

                <input type="button" value={'Exit'} onClick={() => this.props.store.refreshStore()}
                       className="test-exit" />
            </Fragment>
        );
    }
}

export default TestAreaComponent;
