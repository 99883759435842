import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { translate } from 'i18n/i18n';
import FormNav from 'app/components/form/FormNav';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import FormValidator from 'store/validators/FormValidator';

@translate()
@withRouter
@inject('store')
@observer
class BookingNav extends Component {
    isTabValid(tabNumber) {
        const activeTab = this.props.tabTitles[tabNumber - 1];
        const { booking, booking: { form } } = this.props.store;
        const { isNoBerths, phonePreselected } = booking;
        const {
            phone, email, dock, berth, priceLoading, price, boatLength, boatCountry,
            berthsLoading, boatName, boatTooBig
        } = form;

        const { empty, isEmailValidForDisplay, isPhoneValidForDisplay, phoneEmpty } = new FormValidator();

        switch (activeTab) {
            case 'COMMON.STEPS_DURATION':
                return !!(!booking.isLoading && booking.isAnyAvailableSpots);
            case 'COMMON.STEPS_LOCATION': {
                if (!dock) {return false}
                if (berthsLoading) {return false}
                return dock && (berth !== null || isNoBerths);
            }
            case 'COMMON.STEPS_BOAT': {
                if (priceLoading || boatTooBig || !price) {return false}
                return !empty(boatLength) && !empty(boatCountry) && !empty(boatName);
            }
            case 'COMMON.STEPS_SERVICE':
                return true;
            case 'COMMON.STEPS_CONTACT_INFORMATION': {
                if (!phonePreselected && !phoneEmpty(phone) &&!isPhoneValidForDisplay(phone)) {return false}
                if (!empty(email) && !isEmailValidForDisplay(email)) {return false}
                return ((!phonePreselected && !phoneEmpty(phone)) || (!empty(email)));
            }
            default: return !!(price && !priceLoading);
        }
    }

    render() {
        const { location: { pathname }, store: { booking } } = this.props;
        const tabNumber = Number(pathname.charAt(pathname.length - 1));

        return (
            <FormNav tabTitles={this.props.tabTitles}
                     tabNumber={tabNumber}
                     isTabValid={this.isTabValid(tabNumber)}
                     prePaymentPromise={booking.createBookingPromise}
                     moduleStore={booking}
            />
        )
    }
}

BookingNav.propTypes = {
    tabTitles: PropTypes.array.isRequired
};

export default BookingNav;
