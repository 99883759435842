import React, { Component, Fragment } from 'react';
import { translate } from 'i18n/i18n';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Loader from 'app/components/Loader';

@translate()
@inject('store')
@observer
class FormPriceOverview extends Component {
    render() {
        const {
            t, stayPrice, freeharborDiscount, environmentalFee, localTax, facilityPrice, total, noDivider, loading
        } = this.props;
        const { currency } = this.props.store.harbor;
        return (
            <div className="overview-price-container">
                {loading ? <Loader /> :
                    <Fragment>
                        <PriceDisplay title={t('COMMON.STAY_PRICE')} amount={stayPrice} currency={currency} />

                        <PriceDisplay title={t('COMMON.ENVIRONMENTAL_FEE')} amount={environmentalFee}
                        currency={currency} />

                        <PriceDisplay title={t('COMMON.LOCAL_TAX')} amount={localTax}
                        currency={currency} />

                        <PriceDisplay title={t('COMMON.SERVICE_PRICE')} amount={facilityPrice}
                        currency={currency} />

                        <PriceDisplay title={t('COMMON.DISCOUNT')} amount={freeharborDiscount}
                        currency={currency} isNegative={true} />

                        <TotalDisplay title={t('COMMON.TOTAL')} amount={total} currency={currency}
                                      noDivider={noDivider} />
                    </Fragment>
                }
            </div>
        );
    }
}

FormPriceOverview.propTypes = {
    stayPrice: PropTypes.any,
    freeharborDiscount: PropTypes.any,
    environmentalFee: PropTypes.any,
    localTax: PropTypes.any,
    facilityPrice: PropTypes.any,
    noDivider: PropTypes.any,
    loading: PropTypes.any,
    total: PropTypes.any,
};

const PriceDisplay = ({ title, amount, currency, isNegative }) => {
    return ((amount && amount !== 0) ?
            <div className="overview-price-box">
                <div className="overview-subprice-title">{title}</div>
                <div className="overview-subprice">
                    {isNegative && <span style={{ marginRight: '3px' }}>–</span>}
                    {amount.toFixed(2)} {currency}
                </div>
            </div>
            :
            null
    )
};

const TotalDisplay = ({ title, amount, currency, noDivider }) => {
    return ((amount && amount !== 0) ?
            <div className={`overview-total-box ${noDivider && 'borderless'}`}>
                <div className="overview-price-title">{title}:</div>
                <div className="overview-total-price">{amount.toFixed(2)} {currency}</div>
            </div>
            :
            null
    )
};

export default FormPriceOverview;
