import React, { Component } from 'react';
import InputContainer from 'app/components/form/inputs/utils/InputContainer';
import TextFormInput from 'app/components/form/inputs/TextFormInput';
import FormValidator from 'store/validators/FormValidator';
import { inject, observer } from 'mobx-react';
import { translate } from 'i18n/i18n';

@translate()
@inject('store')
@observer
class BookingContactTab extends Component {
    render() {
        const { t, store: { harbor, booking, harbor: { hasFreeharbor, crewMembersLimit, harbakioskSettings } } } = this.props;
        const { phone, email, hasFreeharborSelected, freeCrewLimitReached } = booking.form;
        const { isPhoneValidForDisplay, isEmailValidForDisplay } = new FormValidator();

        const selectHasFreeharbor = () => () => booking.setFormValue('hasFreeharborSelected', true);
        const unselectHasFreeharbor = () => () => booking.setFormValue('hasFreeharborSelected', false);

        const selectRequiresCrewMembers = () => () => booking.setFormValue('freeCrewLimitReached', true);
        const unselectRequiresCrewMembers = () => () => booking.setFormValue('freeCrewLimitReached', false);

        const isEmailHidden = !harbakioskSettings.guestEmailHidden;

        return (
            <div className="tab-container">

                <div className="row w-75 mt-5">
                    <div className={isEmailHidden ? 'col-sm-4' : 'col-sm-12'}>
                        <TextFormInput
                            hideOptionality={true}
                            isRequired={!isEmailValidForDisplay(email) && !isEmailHidden}
                            isPhone
                            warningIcon={true}
                            redInputBorder={true}
                            actCodeWarning={true}
                            moduleStore={booking}
                            formFieldName={'phone'}
                            setTitle={t('COMMON.ENTER_PHONE')}
                            changeTitle={t('COMMON.CHANGE_PHONE')}
                            title={t('COMMON.PHONE') + ':'} />
                    </div>
                    {isEmailHidden && (
                        <>
                            <div className="col-sm-4 d-flex align-items-center justify-content-center">
                                <div className="email-or-phone">
                                    {t('COMMON.PHONE_OR_EMAIL')}
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <TextFormInput
                                    isRequired={(!isPhoneValidForDisplay(phone) || booking.phonePreselected) && !isEmailHidden}
                                    isEmail
                                    hideOptionality={true}
                                    warningIcon={true}
                                    redInputBorder={true}
                                    actCodeWarning={true}
                                    moduleStore={booking}
                                    formFieldName={'email'}
                                    setTitle={t('COMMON.ENTER_EMAIL')}
                                    changeTitle={t('COMMON.CHANGE_EMAIL')}
                                    title={t('COMMON.EMAIL') + ':'} />
                            </div>
                        </>
                    )}
                </div>

                <InputContainer secondRowFullWidth biggerContainer>



                    <div>
                        {hasFreeharbor &&
                            <div className="question-container">
                                <span className="form-label">{t('COMMON.IS_FREEHARBOR_LABEL')}:</span>

                                <button type="button" onClick={unselectHasFreeharbor()}
                                        className={`form-btn question-checkbox-btn click-effect
                                        ${!hasFreeharborSelected ? 'question-selected' : ''}`}>
                                    {t('COMMON.ANSWER_NO')}
                                </button>
                                <button type="button" onClick={selectHasFreeharbor()}
                                        className={`form-btn question-checkbox-btn click-effect
                                        ${hasFreeharborSelected ? 'question-selected' : ''}`}>
                                    {t('COMMON.ANSWER_YES')}
                                </button>
                            </div>
                        }

                        {harbor.requiresCrewMembersEnabled && harbor.isCrewMemberCorrectType &&
                            <div className="question-container">
                                <span className="form-label">
                                    {t('COMMON.CREW_LIMIT_LABEL', { crewMembersLimit })}:
                                </span>

                                <button type="button" onClick={unselectRequiresCrewMembers()}
                                        className={`form-btn question-checkbox-btn click-effect
                                        ${!freeCrewLimitReached ? 'question-selected' : ''}`}>
                                    {t('COMMON.ANSWER_NO')}
                                </button>
                                <button type="button" onClick={selectRequiresCrewMembers()}
                                        className={`form-btn question-checkbox-btn click-effect
                                        ${freeCrewLimitReached ? 'question-selected' : ''}`}>
                                    {t('COMMON.ANSWER_YES')}
                                </button>
                            </div>
                        }
                    </div>
                </InputContainer>

                <div className="contact-explain">{t('COMMON.PHONE_EMAIL_EXPLAIN')}</div>
            </div>
        )
    }
}

export default BookingContactTab;
