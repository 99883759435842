import React, { Component } from 'react';
import FormOverview from 'app/components/form/overview/FormOverview';
import FormTextOverview from 'app/components/form/overview/FormTextOverview';
import FormLocationOverview from 'app/components/form/overview/FormLocationOverview';
import FormCountryOverview from 'app/components/form/overview/FormCountryOverview';
import { inject, observer } from 'mobx-react';
import { translate } from 'i18n/i18n';
import FormPriceOverview from 'app/components/form/overview/FormPriceOverview';
import FormDurationOverview from 'app/components/form/overview/FormDurationOverview';

@translate()
@inject('store')
@observer
class BookingOverviewTab extends Component {
    componentDidMount() {
        this.props.store.booking.getBookingPrice();
    }

    render() {
        const { t, store: { booking, harbor: { bookingPricingType } } } = this.props;
        const { form, phonePreselected } = booking;
        const {
            dock, berth, boatCountry, boatLength, email, phone, dateFrom, duration, boatName, price, priceLoading
        } = form;
        const { store: { harbor } } = this.props;
        return (
            <div className="tab-container">
                <FormOverview redBorder>
                    <FormDurationOverview dateFrom={dateFrom} duration={duration} />
                    <FormLocationOverview label={t('COMMON.LOCATION')} value={dock && dock.title}
                                          secondaryValue={berth && berth.title} />
                    <FormTextOverview
                        label={t(bookingPricingType === 'by_boat_width' ? 'COMMON.BOAT_WIDTH' : 'COMMON.BOAT_LENGTH')}
                        value={boatLength && boatLength + ' m.'} />
                    <FormCountryOverview label={t('COMMON.BOAT_COUNTRY')} value={boatCountry} />
                    <FormTextOverview label={t('COMMON.BOAT_NAME')} value={boatName} />
                    {!phonePreselected && <FormTextOverview showWarnSign label={t('COMMON.PHONE')} value={phone} />}
                    <FormTextOverview showWarnSign label={t('COMMON.EMAIL')} value={email} />
                    {harbor.harbakioskSettings.isCreditEnabled && (
                        <>
                            <hr />
                            <p className='color-red'>{t('COMMON.ACCESS_CODE_PHONE_EMAIL_WARNING')}</p>
                        </>
                    )}
                </FormOverview>

                {(price || priceLoading) && <FormPriceOverview {...price} loading={priceLoading} /> }
            </div>
        );
    }
}

export default BookingOverviewTab;
