import Api from 'api/api';

class CamperApi {
    getLots(id) {
        return Api.get(`/public/camper/lots/${id}`);
    }

    getSettings(id) {
        return Api.get(`/public/camper/settings/${id}`);
    }

    postReservation(body, id) {
        return Api.post(`/public/camper/reservation/${id}`, body);
    }

    getCamperPaymentByOrderId(orderId: string) {
        return Api.get(`/public/camper/reservation_payment/${orderId}`);
    }

    markReservationAsPaid(orderId: string, gateway, fee = 0) {
        return Api.patch(`/public/camper/verify_order/${orderId}?gateway=${gateway}&fee=${fee}`);
    }
}

export default new CamperApi();
