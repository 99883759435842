import { action, observable } from 'mobx';
import TerminalApi from 'api/TerminalApi';

class ExitStore {
    @observable count = 0;
    @observable interval;
    @observable isExitModalOpen = false;
    @observable code = '';

    constructor(rootStore) {
        this.rootStore = rootStore;
    }

    @action
    incrementCount() {
        this.count = this.count + 1;

        if (this.interval) {
            clearTimeout(this.interval);
        }

        this.interval = setTimeout(this.clearCount, 1000)
    }

    @action.bound
    clearCount() {
        this.count = 0;
    }

    @action
    closeClient() {
        TerminalApi.shutdown()
            .then();
    }

    @action
    setIsExitModalOpen(value) {
        this.isExitModalOpen = value;
    }

    @action
    setCode(value) {
        this.code = value;
    }

    @action
    closeExitModal() {
        this.setIsExitModalOpen(false);
        this.setCode('');
    }
}

export default ExitStore;
