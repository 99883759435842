import 'react-app-polyfill/ie9';
import 'css-vars-ponyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18n/i18n';
import { Provider } from 'mobx-react';
import rootStore from './store/RootStore';
import { configure } from 'mobx';
import { BrowserRouter } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css';

// Enforces MobX to use actions to manipulate state
configure({ enforceActions: 'always' });

ReactDOM.render((
    <Provider store={rootStore}>
        <I18nextProvider i18n={i18n}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </I18nextProvider>
    </Provider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
