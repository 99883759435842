class CreditReceiptModel {
    build(response, rootStore) {
        const { amount, vatAmount, accessCode } = response;
        const locale = rootStore.locale.locale;
        const currency = rootStore.harbor.currency;
        const harborName = rootStore.harbor.name;

        return {
            locale,
            alertOnLowPaperEnabled: false,
            total: amount.toFixed(2),
            vat: vatAmount.toFixed(2),
            currency,
            harborName,
            accessCode
        }
    }

    buildTest() {
        const locale = 'da';

        return {
            locale,
            accessCode: '5555',
            alertOnLowPaperEnabled: false,
            total: '50.00',
            vat: '2.00',
            currency: 'DKK',
            harborName: 'PORT'
        }
    }
}

export default CreditReceiptModel;
