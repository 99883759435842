import { action, observable } from 'mobx';
import BookingPaymentViewModel from 'store/PaymentStore/modules/Booking/BookingPaymentViewModel';
import BookingApi from 'api/BookingApi';
import BookingReceiptModel from 'store/PaymentStore/PrintModels/BookingReceiptModel';
import i18n from 'i18n/i18n';
import BookingStickerModel from 'store/PaymentStore/PrintModels/BookingStickerModel';
import PrinterApi from 'api/PrinterApi';
import AbstractModulePaymentStore from 'store/PaymentStore/modules/AbstractModulePaymentStore';
import HarborApi from '../../../../api/HarborApi';

class BookingPaymentStore extends AbstractModulePaymentStore {
    @observable booking: BookingPaymentViewModel;
    @observable hasAccessCode = false;

    constructor(paymentStore) {
        super(paymentStore);
        this.payment = paymentStore;
    }

    @action
    getBookingRoot() {
        return this.payment.rootStore.booking;
    }

    @action
    finalizePayment(orderId) {
        getPaymentByOrderIdPromise(orderId)
            .then((response) => {
                if (response) {
                    let responseObject = response;
                    HarborApi.getAccessCode(orderId)
                        .then((res)=>{
                            responseObject.accessCode = res.data;
                            if (res.data) {
                                this.setHasAccessCode();
                            }

                            this.setBooking(responseObject);
                            this.markPaymentAsPaid(orderId);

                            this.generatePrint(responseObject);
                            this.printSticker();
                        })
                }
            })
    }

    @action
    extractTotalByOrderId(orderId) {
        return new Promise((resolve, reject) => {
            getPaymentByOrderIdPromise(orderId)
                .then((response) => {
                    this.setPaymentTotalIndoor(response.price);
                    this.setPaymentTotalOutdoor(response.price * 100);
                    resolve(response.price);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    @action
    setHasAccessCode() {
        this.hasAccessCode = true;
    }

    @action
    setBooking(response) {
        if (response.dateFrom) {
            this.accessCode = response.accessCode;
            this.booking = new BookingPaymentViewModel(response, this.payment.rootStore.locale.locale);
            this.payment.rootStore
                .booking.setBookingFromApi(new BookingPaymentViewModel(response, this.payment.rootStore.locale.locale));
        }
    }

    @action
    markPaymentAsPaid(orderId) {
        BookingApi.markAsPaid(orderId, this.getGateway(), this.payment.rootStore.booking.fee)
            .then(() => {})
    }

    @action
    generatePrint(response) {
        this.receiptPrint = new BookingReceiptModel().build(response, this.payment.rootStore, i18n);
        this.stickerPrint = new BookingStickerModel().build(response, this.payment.rootStore);
    }

    printSticker() {
        if (this.payment.rootStore.terminal.hasPrinter) {
            PrinterApi.printBookingSticker(this.stickerPrint).then();
        }
    }

    printReceipt() {
        if (this.payment.rootStore.terminal.hasPrinter) {
            PrinterApi.printBookingReceipt(this.receiptPrint)
                .then(() => this.setIsReceiptPrinted(true));
        }
    }
}

const getPaymentByOrderIdPromise = (orderId) => {
    return new Promise(resolve => {
        BookingApi.getBookingByPaymentOrderId(orderId)
            .then((res) => resolve(res.data));
    })
}

export default BookingPaymentStore;
